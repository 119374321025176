/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any };
  /**
   * Represents a time zone - a mapping between UTC and local time.
   * A time zone maps UTC instants to local times - or, equivalently, to the offset from UTC at any particular instant.
   */
  DateTimeZone: { input: any; output: any };
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any };
  /** Represents a fixed (and calendar-independent) length of time. */
  Duration: { input: any; output: any };
  /** Represents an instant on the global timeline, with nanosecond resolution. */
  Instant: { input: any; output: any };
  /**
   * Equates the days of the week with their numerical value according to ISO-8601.
   *  Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6, Sunday = 7.
   */
  IsoDayOfWeek: { input: any; output: any };
  JSON: { input: any; output: any };
  /** LocalDate is an immutable struct representing a date within the calendar, with no reference to a particular time zone or time of day. */
  LocalDate: { input: any; output: any };
  /** A date and time in a particular calendar system. */
  LocalDateTime: { input: any; output: any };
  /** LocalTime is an immutable struct representing a time of day, with no reference to a particular calendar, time zone or date. */
  LocalTime: { input: any; output: any };
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any };
  /**
   * An offset from UTC in seconds.
   *  A positive value means that the local time is ahead of UTC (e.g. for Europe); a negative value means that the local time is behind UTC (e.g. for America).
   */
  Offset: { input: any; output: any };
  /** A combination of a LocalDate and an Offset, to represent a date at a specific offset from UTC but without any time-of-day information. */
  OffsetDate: { input: any; output: any };
  /** A local date and time in a particular calendar system, combined with an offset from UTC. */
  OffsetDateTime: { input: any; output: any };
  /** A combination of a LocalTime and an Offset, to represent a time-of-day at a specific offset from UTC but without any date information. */
  OffsetTime: { input: any; output: any };
  /** Represents a period of time expressed in human chronological terms: hours, days, weeks, months and so on. */
  Period: { input: any; output: any };
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: any; output: any };
  URL: { input: any; output: any };
  UUID: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
  /** A year and month in a particular calendar. This is effectively LocalDate without the day-of-month component. */
  YearMonth: { input: any; output: any };
  /**
   * A LocalDateTime in a specific time zone and with a particular offset to distinguish between otherwise-ambiguous instants.
   * A ZonedDateTime is global, in that it maps to a single Instant.
   */
  ZonedDateTime: { input: any; output: any };
};

export enum AcademicResultsType {
  HighSchool = 'HIGH_SCHOOL',
  MiddleSchool = 'MIDDLE_SCHOOL',
}

export type AcademicResultsTypeOperationFilterInput = {
  eq?: InputMaybe<AcademicResultsType>;
  in?: InputMaybe<Array<AcademicResultsType>>;
  neq?: InputMaybe<AcademicResultsType>;
  nin?: InputMaybe<Array<AcademicResultsType>>;
};

export type AddApplicationLabelsResult = {
  __typename?: 'AddApplicationLabelsResult';
  count: Scalars['Int']['output'];
};

export type AddApplicationLabelsResultFilterInput = {
  and?: InputMaybe<Array<AddApplicationLabelsResultFilterInput>>;
  count?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<AddApplicationLabelsResultFilterInput>>;
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  data?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
};

export type AdditionalInfoFilterInput = {
  and?: InputMaybe<Array<AdditionalInfoFilterInput>>;
  data?: InputMaybe<StringOperationFilterInput>;
  emails?: InputMaybe<ListStringOperationFilterInput>;
  or?: InputMaybe<Array<AdditionalInfoFilterInput>>;
  phoneNumbers?: InputMaybe<ListStringOperationFilterInput>;
};

export type AdditionalInfoInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdditionalInfoSortInput = {
  data?: InputMaybe<SortEnumType>;
};

export type Address = {
  __typename?: 'Address';
  /** State / Province / Region */
  administrativeArea?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  /** City / Town */
  locality?: Maybe<Scalars['String']['output']>;
  /** Postal code / ZIP Code */
  postalCode?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type AddressFilterInput = {
  /** State / Province / Region */
  administrativeArea?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  country?: InputMaybe<StringOperationFilterInput>;
  /** City / Town */
  locality?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  /** Postal code / ZIP Code */
  postalCode?: InputMaybe<StringOperationFilterInput>;
  streetAddress?: InputMaybe<StringOperationFilterInput>;
  streetNumber?: InputMaybe<StringOperationFilterInput>;
};

export type AddressInput = {
  /** State / Province / Region */
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  /** City / Town */
  locality?: InputMaybe<Scalars['String']['input']>;
  /** Postal code / ZIP Code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AddressSortInput = {
  /** State / Province / Region */
  administrativeArea?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  /** City / Town */
  locality?: InputMaybe<SortEnumType>;
  /** Postal code / ZIP Code */
  postalCode?: InputMaybe<SortEnumType>;
  streetAddress?: InputMaybe<SortEnumType>;
  streetNumber?: InputMaybe<SortEnumType>;
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  items: Array<Scalars['JSON']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  request?: Maybe<Request>;
  requestId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type AnswerEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type AnswerFilterInput = {
  and?: InputMaybe<Array<AnswerFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfJsonElementFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<AnswerFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  request?: InputMaybe<RequestFilterInput>;
  requestId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type AnswerSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  request?: InputMaybe<RequestSortInput>;
  requestId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AnswersCollectionSegment = {
  __typename?: 'AnswersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Answer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  key: Scalars['String']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  owner: User;
  ownerId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ApiKeyEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ApiKeyFilterInput = {
  and?: InputMaybe<Array<ApiKeyFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ApiKeyFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  owner?: InputMaybe<UserFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ApiKeySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<UserSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ApiKeysCollectionSegment = {
  __typename?: 'ApiKeysCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ApiKey>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Application = {
  __typename?: 'Application';
  accountCompletionDate?: Maybe<Scalars['Instant']['output']>;
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  code: Scalars['String']['output'];
  consentState: ConsentState;
  consents: Array<Consent>;
  contest?: Maybe<Contest>;
  contestId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  currentFlowIndex?: Maybe<Scalars['Int']['output']>;
  currentStateIndex?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  eligibilityTest?: Maybe<EligibilityTest>;
  estimatedInterculturaScholarshipAmount?: Maybe<Scalars['Float']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  flows?: Maybe<Array<Flow>>;
  hasConsent?: Maybe<Scalars['Boolean']['output']>;
  hasPaidAdvancePaymentQuota?: Maybe<Scalars['Boolean']['output']>;
  hasPaidRegistrationQuota?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  internationalApplication?: Maybe<InternationalApplication>;
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  iseEs: Array<Isee>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  programApplications: Array<ProgramApplication>;
  programTypes: Array<ProgramType>;
  ranking?: Maybe<Ranking>;
  relationshipsCount: Scalars['Int']['output'];
  reports: Array<Report>;
  scholarshipRequests: Array<ScholarshipRequest>;
  score?: Maybe<Score>;
  student: StudentBase;
  studentId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ApplicationEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ApplicationFilterInput = {
  accountCompletionDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  and?: InputMaybe<Array<ApplicationFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  consentState?: InputMaybe<ConsentStateOperationFilterInput>;
  consents?: InputMaybe<ListFilterInputTypeOfConsentFilterInput>;
  contest?: InputMaybe<ContestFilterInput>;
  contestId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  currentFlowIndex?: InputMaybe<IntOperationFilterInput>;
  currentStateIndex?: InputMaybe<IntOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  eligibilityTest?: InputMaybe<EligibilityTestFilterInput>;
  estimatedInterculturaScholarshipAmount?: InputMaybe<FloatOperationFilterInput>;
  hasConsent?: InputMaybe<BooleanOperationFilterInput>;
  hasPaidAdvancePaymentQuota?: InputMaybe<BooleanOperationFilterInput>;
  hasPaidRegistrationQuota?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isClosed?: InputMaybe<BooleanOperationFilterInput>;
  iseEs?: InputMaybe<ListFilterInputTypeOfIseeFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  programApplications?: InputMaybe<ListFilterInputTypeOfProgramApplicationFilterInput>;
  programTypes?: InputMaybe<ListProgramTypeOperationFilterInput>;
  ranking?: InputMaybe<RankingFilterInput>;
  relationshipsCount?: InputMaybe<IntOperationFilterInput>;
  reports?: InputMaybe<ListFilterInputTypeOfReportFilterInput>;
  scholarshipRequests?: InputMaybe<ListFilterInputTypeOfScholarshipRequestFilterInput>;
  score?: InputMaybe<ScoreFilterInput>;
  student?: InputMaybe<StudentBaseFilterInput>;
  studentId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ApplicationSortInput = {
  accountCompletionDate?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  consentState?: InputMaybe<SortEnumType>;
  contest?: InputMaybe<ContestSortInput>;
  contestId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  currentFlowIndex?: InputMaybe<SortEnumType>;
  currentStateIndex?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  eligibilityTest?: InputMaybe<EligibilityTestSortInput>;
  estimatedInterculturaScholarshipAmount?: InputMaybe<SortEnumType>;
  hasConsent?: InputMaybe<SortEnumType>;
  hasPaidAdvancePaymentQuota?: InputMaybe<SortEnumType>;
  hasPaidRegistrationQuota?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isClosed?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  ranking?: InputMaybe<RankingSortInput>;
  relationshipsCount?: InputMaybe<SortEnumType>;
  score?: InputMaybe<ScoreSortInput>;
  student?: InputMaybe<StudentBaseSortInput>;
  studentId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ApplicationsCollectionSegment = {
  __typename?: 'ApplicationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Application>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type Area = {
  __typename?: 'Area';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  search: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type AreaEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type AreaFilterInput = {
  and?: InputMaybe<Array<AreaFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AreaFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type AreaSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AreasCollectionSegment = {
  __typename?: 'AreasCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Area>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type BadRequestError = Error & {
  __typename?: 'BadRequestError';
  errors: Array<KeyValuePairOfStringAndString__>;
  message: Scalars['String']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkSetAssigneesError = BadRequestError | ConflictError | ForbiddenError | NotFoundError;

export type BulkSetAssigneesInput = {
  assigneeIds: Array<Scalars['UUID']['input']>;
  targetIds: Array<Scalars['UUID']['input']>;
  targetType: Scalars['String']['input'];
};

export type BulkSetAssigneesPayload = {
  __typename?: 'BulkSetAssigneesPayload';
  errors?: Maybe<Array<BulkSetAssigneesError>>;
  itemIds?: Maybe<ItemIds>;
};

export type BulkSetLabelsError = BadRequestError | ConflictError | ForbiddenError | NotFoundError;

export type BulkSetLabelsInput = {
  labelIds: Array<Scalars['UUID']['input']>;
  targetIds: Array<Scalars['UUID']['input']>;
  targetType: Scalars['String']['input'];
};

export type BulkSetLabelsPayload = {
  __typename?: 'BulkSetLabelsPayload';
  errors?: Maybe<Array<BulkSetLabelsError>>;
  itemIds?: Maybe<ItemIds>;
};

export type CalendarSystemFilterInput = {
  and?: InputMaybe<Array<CalendarSystemFilterInput>>;
  eras?: InputMaybe<ListFilterInputTypeOfEraFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  maxYear?: InputMaybe<IntOperationFilterInput>;
  minYear?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CalendarSystemFilterInput>>;
};

export type CannotLiveWithAnimal = {
  __typename?: 'CannotLiveWithAnimal';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  indoor: Scalars['Boolean']['output'];
  internationalApplicationId: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  outdoor: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type CannotLiveWithAnimalEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CannotLiveWithAnimalFilterInput = {
  and?: InputMaybe<Array<CannotLiveWithAnimalFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  indoor?: InputMaybe<BooleanOperationFilterInput>;
  internationalApplicationId?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CannotLiveWithAnimalFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  outdoor?: InputMaybe<BooleanOperationFilterInput>;
  reason?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type CannotLiveWithAnimalSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  indoor?: InputMaybe<SortEnumType>;
  internationalApplicationId?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  outdoor?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CannotLiveWithAnimalsCollectionSegment = {
  __typename?: 'CannotLiveWithAnimalsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CannotLiveWithAnimal>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export enum ColumnOrderType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ColumnOrderTypeOperationFilterInput = {
  eq?: InputMaybe<ColumnOrderType>;
  in?: InputMaybe<Array<ColumnOrderType>>;
  neq?: InputMaybe<ColumnOrderType>;
  nin?: InputMaybe<Array<ColumnOrderType>>;
};

export type ColumnOrdersView = {
  __typename?: 'ColumnOrdersView';
  name: Scalars['String']['output'];
  order: ColumnOrderType;
};

export type ColumnOrdersViewFilterInput = {
  and?: InputMaybe<Array<ColumnOrdersViewFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ColumnOrdersViewFilterInput>>;
  order?: InputMaybe<ColumnOrderTypeOperationFilterInput>;
};

export type ColumnOrdersViewInput = {
  name: Scalars['String']['input'];
  order: ColumnOrderType;
};

export type ColumnsView = {
  __typename?: 'ColumnsView';
  color: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ColumnsViewFilterInput = {
  and?: InputMaybe<Array<ColumnsViewFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  isHidden?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ColumnsViewFilterInput>>;
};

export type ColumnsViewInput = {
  color: Scalars['String']['input'];
  isHidden: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type ComparableInstantOperationFilterInput = {
  eq?: InputMaybe<Scalars['Instant']['input']>;
  gt?: InputMaybe<Scalars['Instant']['input']>;
  gte?: InputMaybe<Scalars['Instant']['input']>;
  in?: InputMaybe<Array<Scalars['Instant']['input']>>;
  lt?: InputMaybe<Scalars['Instant']['input']>;
  lte?: InputMaybe<Scalars['Instant']['input']>;
  neq?: InputMaybe<Scalars['Instant']['input']>;
  ngt?: InputMaybe<Scalars['Instant']['input']>;
  ngte?: InputMaybe<Scalars['Instant']['input']>;
  nin?: InputMaybe<Array<Scalars['Instant']['input']>>;
  nlt?: InputMaybe<Scalars['Instant']['input']>;
  nlte?: InputMaybe<Scalars['Instant']['input']>;
};

export type ComparableNullableOfInstantOperationFilterInput = {
  eq?: InputMaybe<Scalars['Instant']['input']>;
  gt?: InputMaybe<Scalars['Instant']['input']>;
  gte?: InputMaybe<Scalars['Instant']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Instant']['input']>>>;
  lt?: InputMaybe<Scalars['Instant']['input']>;
  lte?: InputMaybe<Scalars['Instant']['input']>;
  neq?: InputMaybe<Scalars['Instant']['input']>;
  ngt?: InputMaybe<Scalars['Instant']['input']>;
  ngte?: InputMaybe<Scalars['Instant']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Instant']['input']>>>;
  nlt?: InputMaybe<Scalars['Instant']['input']>;
  nlte?: InputMaybe<Scalars['Instant']['input']>;
};

export type ConflictError = Error & {
  __typename?: 'ConflictError';
  message: Scalars['String']['output'];
};

export type Consent = {
  __typename?: 'Consent';
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isAccepted: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  relationship: Relationship;
  relationshipId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ConsentEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ConsentFilterInput = {
  and?: InputMaybe<Array<ConsentFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isAccepted?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ConsentFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  relationship?: InputMaybe<RelationshipFilterInput>;
  relationshipId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ConsentSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAccepted?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  relationship?: InputMaybe<RelationshipSortInput>;
  relationshipId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum ConsentState {
  Completed = 'COMPLETED',
  None = 'NONE',
  Partial = 'PARTIAL',
}

export type ConsentStateOperationFilterInput = {
  eq?: InputMaybe<ConsentState>;
  in?: InputMaybe<Array<ConsentState>>;
  neq?: InputMaybe<ConsentState>;
  nin?: InputMaybe<Array<ConsentState>>;
};

/** A segment of a collection. */
export type ConsentsCollectionSegment = {
  __typename?: 'ConsentsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Consent>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  additionalInfo?: Maybe<AdditionalInfo>;
  address?: Maybe<Address>;
  aliases: Array<Contact>;
  aliasesIds: Array<Scalars['UUID']['output']>;
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  birthDate?: Maybe<Scalars['LocalDate']['output']>;
  birthPlace?: Maybe<Address>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  email?: Maybe<Scalars['String']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  firstName?: Maybe<Scalars['String']['output']>;
  fiscalCode?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  houses: Array<House>;
  id: Scalars['UUID']['output'];
  isDeceased?: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mainContactId?: Maybe<Scalars['UUID']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  originId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Document>;
  pictureId?: Maybe<Scalars['UUID']['output']>;
  relationships: Array<Relationship>;
  search: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  weight?: Maybe<Scalars['String']['output']>;
};

export type ContactEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactFilterInput = {
  additionalInfo?: InputMaybe<AdditionalInfoFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  aliasesIds?: InputMaybe<ListUuidOperationFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  birthDate?: InputMaybe<LocalDateFilterInput>;
  birthPlace?: InputMaybe<AddressFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fiscalCode?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<StringOperationFilterInput>;
  height?: InputMaybe<StringOperationFilterInput>;
  houses?: InputMaybe<ListFilterInputTypeOfHouseFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeceased?: InputMaybe<BooleanOperationFilterInput>;
  isEmailVerified?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mainContactId?: InputMaybe<UuidOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originId?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  picture?: InputMaybe<DocumentFilterInput>;
  pictureId?: InputMaybe<UuidOperationFilterInput>;
  relationships?: InputMaybe<ListFilterInputTypeOfRelationshipFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  weight?: InputMaybe<StringOperationFilterInput>;
};

export type ContactReference = LeadReference | RelationshipReference | StudentReference;

export type ContactSortInput = {
  additionalInfo?: InputMaybe<AdditionalInfoSortInput>;
  address?: InputMaybe<AddressSortInput>;
  birthDate?: InputMaybe<SortEnumType>;
  birthPlace?: InputMaybe<AddressSortInput>;
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fiscalCode?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<SortEnumType>;
  height?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeceased?: InputMaybe<SortEnumType>;
  isEmailVerified?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mainContactId?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originId?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  picture?: InputMaybe<DocumentSortInput>;
  pictureId?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ContactsCollectionSegment = {
  __typename?: 'ContactsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Contest = {
  __typename?: 'Contest';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  eligibilityTestDates: Array<EligibilityTestDate>;
  events?: Maybe<EventsCollectionSegment>;
  flowSchema?: Maybe<FlowSchema>;
  flowSchemaId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  isCurrent: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  previousContestId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  year?: Maybe<Scalars['String']['output']>;
};

export type ContestEligibilityTestDatesArgs = {
  order?: InputMaybe<Array<EligibilityTestDateSortInput>>;
  where?: InputMaybe<EligibilityTestDateFilterInput>;
};

export type ContestEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ContestFilterInput = {
  and?: InputMaybe<Array<ContestFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  flowSchema?: InputMaybe<FlowSchemaFilterInput>;
  flowSchemaId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isCurrent?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContestFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  previousContestId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  year?: InputMaybe<StringOperationFilterInput>;
};

export type ContestSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  flowSchema?: InputMaybe<FlowSchemaSortInput>;
  flowSchemaId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isCurrent?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  previousContestId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ContestsCollectionSegment = {
  __typename?: 'ContestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Contest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CountItem = {
  __typename?: 'CountItem';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CreateAnswerInput = {
  applicationId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  items: Array<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateAnswerPayload = {
  __typename?: 'CreateAnswerPayload';
  answer?: Maybe<Answer>;
};

export type CreateApiKeyError = NotFoundError;

export type CreateApiKeyPayload = {
  __typename?: 'CreateApiKeyPayload';
  apiKey?: Maybe<ApiKey>;
  errors?: Maybe<Array<CreateApiKeyError>>;
};

export type CreateApplicationError = ConflictError | UnauthorizedError;

export type CreateApplicationInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  code: Scalars['String']['input'];
  contestId?: InputMaybe<Scalars['UUID']['input']>;
  currentFlowIndex?: InputMaybe<Scalars['Int']['input']>;
  currentStateIndex?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  estimatedInterculturaScholarshipAmount?: InputMaybe<Scalars['Float']['input']>;
  hasConsent?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaidAdvancePaymentQuota?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaidRegistrationQuota?: InputMaybe<Scalars['Boolean']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programTypes: Array<ProgramType>;
  studentId: Scalars['UUID']['input'];
};

export type CreateApplicationPayload = {
  __typename?: 'CreateApplicationPayload';
  application?: Maybe<Application>;
  errors?: Maybe<Array<CreateApplicationError>>;
};

export type CreateAreaError = ConflictError | UnauthorizedError;

export type CreateAreaInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type CreateAreaPayload = {
  __typename?: 'CreateAreaPayload';
  area?: Maybe<Area>;
  errors?: Maybe<Array<CreateAreaError>>;
};

export type CreateCannotLiveWithAnimalError = ConflictError | UnauthorizedError;

export type CreateCannotLiveWithAnimalInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  indoor: Scalars['Boolean']['input'];
  internationalApplicationId: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  outdoor: Scalars['Boolean']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCannotLiveWithAnimalPayload = {
  __typename?: 'CreateCannotLiveWithAnimalPayload';
  cannotLiveWithAnimal?: Maybe<CannotLiveWithAnimal>;
  errors?: Maybe<Array<CreateCannotLiveWithAnimalError>>;
};

export type CreateConsentError = ConflictError | UnauthorizedError;

export type CreateConsentInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  isAccepted: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  relationshipId: Scalars['UUID']['input'];
};

export type CreateConsentPayload = {
  __typename?: 'CreateConsentPayload';
  consent?: Maybe<Consent>;
  errors?: Maybe<Array<CreateConsentError>>;
};

export type CreateContactError = ConflictError | UnauthorizedError;

export type CreateContactInput = {
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
  address?: InputMaybe<AddressInput>;
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  birthPlace?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  isDeceased?: InputMaybe<Scalars['Boolean']['input']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mainContactId?: InputMaybe<Scalars['UUID']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<Contact>;
  errors?: Maybe<Array<CreateContactError>>;
};

export type CreateContestError = ConflictError | UnauthorizedError;

export type CreateContestInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  flowSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  isCurrent: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  previousContestId?: InputMaybe<Scalars['UUID']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContestPayload = {
  __typename?: 'CreateContestPayload';
  contest?: Maybe<Contest>;
  errors?: Maybe<Array<CreateContestError>>;
};

export type CreateEligibilityTestDateError = ConflictError | UnauthorizedError;

export type CreateEligibilityTestDateInput = {
  contestId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  date: Scalars['LocalDate']['input'];
  deadLineDate: Scalars['LocalDate']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  seats: Scalars['Int']['input'];
};

export type CreateEligibilityTestDatePayload = {
  __typename?: 'CreateEligibilityTestDatePayload';
  eligibilityTestDate?: Maybe<EligibilityTestDate>;
  errors?: Maybe<Array<CreateEligibilityTestDateError>>;
};

export type CreateEligibilityTestError = ConflictError | UnauthorizedError;

export type CreateEligibilityTestInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  eligibilityTestDate?: InputMaybe<Scalars['Date']['input']>;
  eligibilityTestOutcome?: InputMaybe<Scalars['String']['input']>;
  giuntiCode?: InputMaybe<Scalars['String']['input']>;
  giuntiPassword?: InputMaybe<Scalars['String']['input']>;
  hasPassedEligibilityTest?: InputMaybe<Scalars['Boolean']['input']>;
  hasTakenEligibilityTest?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  lastCredentialsSend?: InputMaybe<Scalars['DateTime']['input']>;
  lastDateSend?: InputMaybe<Scalars['DateTime']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  testDateId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateEligibilityTestPayload = {
  __typename?: 'CreateEligibilityTestPayload';
  eligibilityTest?: Maybe<EligibilityTest>;
  errors?: Maybe<Array<CreateEligibilityTestError>>;
};

export type CreateEmergencyContactError = ConflictError | UnauthorizedError;

export type CreateEmergencyContactInput = {
  contact: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
  studentId: Scalars['UUID']['input'];
};

export type CreateEmergencyContactPayload = {
  __typename?: 'CreateEmergencyContactPayload';
  emergencyContact?: Maybe<EmergencyContact>;
  errors?: Maybe<Array<CreateEmergencyContactError>>;
};

export type CreateFlowSchemaInput = {
  flows: Array<FlowInput>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
};

export type CreateFlowSchemaPayload = {
  __typename?: 'CreateFlowSchemaPayload';
  flowSchema?: Maybe<FlowSchema>;
};

export type CreateHouseError = ConflictError | UnauthorizedError;

export type CreateHouseInput = {
  address: AddressInput;
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: HouseType;
};

export type CreateHousePayload = {
  __typename?: 'CreateHousePayload';
  errors?: Maybe<Array<CreateHouseError>>;
  house?: Maybe<House>;
};

export type CreateInternationalApplicationError = ConflictError | UnauthorizedError;

export type CreateInternationalApplicationInput = {
  anticipatedGraduationDate?: InputMaybe<Scalars['YearMonth']['input']>;
  applicationId: Scalars['UUID']['input'];
  canEatDairy?: InputMaybe<Scalars['Boolean']['input']>;
  canEatFish?: InputMaybe<Scalars['Boolean']['input']>;
  canEatPoultry?: InputMaybe<Scalars['Boolean']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  dietaryPreference?: InputMaybe<DietaryPreference>;
  dietaryRestrictionsDescription?: InputMaybe<Scalars['String']['input']>;
  doesSmoke?: InputMaybe<Scalars['Boolean']['input']>;
  familyMemberHostDescription?: InputMaybe<Scalars['String']['input']>;
  familyMemberParticipantDescription?: InputMaybe<Scalars['String']['input']>;
  frequentAbsencesDescription?: InputMaybe<Scalars['String']['input']>;
  hasDietaryRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  hasFamilyMemberHosted?: InputMaybe<Scalars['Boolean']['input']>;
  hasFamilyMemberParticipated?: InputMaybe<Scalars['Boolean']['input']>;
  hasMedicalRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissedYearOrSemester?: InputMaybe<Scalars['Boolean']['input']>;
  hasRelationLivingAbroad?: InputMaybe<Scalars['Boolean']['input']>;
  isFrequentlyAbsent?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  medicalRequirementsDescription?: InputMaybe<Scalars['String']['input']>;
  missedYearOrSemesterDescription?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  previousAbroadExperienceDescription?: InputMaybe<Scalars['String']['input']>;
  relationLivingAbroadDescription?: InputMaybe<Scalars['String']['input']>;
  willAbstainFromSmoking?: InputMaybe<Scalars['Boolean']['input']>;
  yearsOfPrimarySchool?: InputMaybe<Scalars['String']['input']>;
  yearsOfSecondarySchool?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInternationalApplicationPayload = {
  __typename?: 'CreateInternationalApplicationPayload';
  errors?: Maybe<Array<CreateInternationalApplicationError>>;
  internationalApplication?: Maybe<InternationalApplication>;
};

export type CreateIseeError = ConflictError | UnauthorizedError;

export type CreateIseeInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  maxScholarshipAmount?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIseePayload = {
  __typename?: 'CreateIseePayload';
  errors?: Maybe<Array<CreateIseeError>>;
  isee?: Maybe<Isee>;
};

export type CreateLabelError = ConflictError | UnauthorizedError;

export type CreateLabelInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload';
  errors?: Maybe<Array<CreateLabelError>>;
  label?: Maybe<Label>;
};

export type CreateLanguageProficiencyError = ConflictError | UnauthorizedError;

export type CreateLanguageProficiencyInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  internationalApplicationId: Scalars['UUID']['input'];
  isPrimaryLanguage: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  language: Scalars['String']['input'];
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  listening: Scalars['Int']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  reading: Scalars['Int']['input'];
  speaking: Scalars['Int']['input'];
  writing: Scalars['Int']['input'];
  yearsOfStudy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLanguageProficiencyPayload = {
  __typename?: 'CreateLanguageProficiencyPayload';
  errors?: Maybe<Array<CreateLanguageProficiencyError>>;
  languageProficiency?: Maybe<LanguageProficiency>;
};

export type CreateLeadError = ConflictError | UnauthorizedError;

export type CreateLeadInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  birthYear?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['UUID']['input'];
};

export type CreateLeadPayload = {
  __typename?: 'CreateLeadPayload';
  errors?: Maybe<Array<CreateLeadError>>;
  lead?: Maybe<Lead>;
};

export type CreateLeadTypeError = ConflictError | UnauthorizedError;

export type CreateLeadTypeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type CreateLeadTypePayload = {
  __typename?: 'CreateLeadTypePayload';
  errors?: Maybe<Array<CreateLeadTypeError>>;
  leadType?: Maybe<LeadType>;
};

export type CreateLocalCenterError = ConflictError | UnauthorizedError;

export type CreateLocalCenterInput = {
  address?: InputMaybe<AddressInput>;
  areaId?: InputMaybe<Scalars['UUID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originLocalCenterId?: InputMaybe<Scalars['UUID']['input']>;
  presidentId?: InputMaybe<Scalars['UUID']['input']>;
  sendingResponsibleId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateLocalCenterPayload = {
  __typename?: 'CreateLocalCenterPayload';
  errors?: Maybe<Array<CreateLocalCenterError>>;
  localCenter?: Maybe<LocalCenter>;
};

export type CreateMessageError = ConflictError | UnauthorizedError;

export type CreateMessageInput = {
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  html?: InputMaybe<Scalars['String']['input']>;
  imapUid?: InputMaybe<Scalars['Long']['input']>;
  isSent?: Scalars['Boolean']['input'];
  isStarred: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  messageDate?: InputMaybe<Scalars['Instant']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  sentBy?: InputMaybe<Scalars['UUID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  threadId: Scalars['UUID']['input'];
  type: MessageType;
};

export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  errors?: Maybe<Array<CreateMessageError>>;
  message?: Maybe<Message>;
};

export type CreateProgramApplicationAnswerError = ConflictError | UnauthorizedError;

export type CreateProgramApplicationAnswerInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programApplicationId: Scalars['UUID']['input'];
  questionId: Scalars['UUID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProgramApplicationAnswerPayload = {
  __typename?: 'CreateProgramApplicationAnswerPayload';
  errors?: Maybe<Array<CreateProgramApplicationAnswerError>>;
  programApplicationAnswer?: Maybe<ProgramApplicationAnswer>;
};

export type CreateProgramApplicationError = ConflictError | UnauthorizedError;

export type CreateProgramApplicationInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  doesNotRequireScholarship?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originServiceId?: InputMaybe<Scalars['String']['input']>;
  programId: Scalars['UUID']['input'];
  state?: InputMaybe<ProgramApplicationState>;
};

export type CreateProgramApplicationPayload = {
  __typename?: 'CreateProgramApplicationPayload';
  errors?: Maybe<Array<CreateProgramApplicationError>>;
  programApplication?: Maybe<ProgramApplication>;
};

export type CreateProgramError = ConflictError | UnauthorizedError;

export type CreateProgramInput = {
  code: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  departureInfo?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationIOC?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['Date']['input'];
  isReciprocal?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  returnInfo?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['Date']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  type: ProgramType;
};

export type CreateProgramPayload = {
  __typename?: 'CreateProgramPayload';
  errors?: Maybe<Array<CreateProgramError>>;
  program?: Maybe<Program>;
};

export type CreateProgramQuestionError = ConflictError | UnauthorizedError;

export type CreateProgramQuestionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programId: Scalars['UUID']['input'];
  text: Scalars['String']['input'];
};

export type CreateProgramQuestionPayload = {
  __typename?: 'CreateProgramQuestionPayload';
  errors?: Maybe<Array<CreateProgramQuestionError>>;
  programQuestion?: Maybe<ProgramQuestion>;
};

export type CreateRankingError = ConflictError | UnauthorizedError;

export type CreateRankingInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  position: Scalars['Int']['input'];
  sponsorId: Scalars['UUID']['input'];
  status?: InputMaybe<RankingStatus>;
};

export type CreateRankingPayload = {
  __typename?: 'CreateRankingPayload';
  errors?: Maybe<Array<CreateRankingError>>;
  ranking?: Maybe<Ranking>;
};

export type CreateRelationshipError = NotFoundError | UnauthorizedError;

export type CreateRelationshipInput = {
  objectContactId?: InputMaybe<Scalars['UUID']['input']>;
  relationshipTypeId?: InputMaybe<Scalars['UUID']['input']>;
  subjectContactId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateRelationshipPayload = {
  __typename?: 'CreateRelationshipPayload';
  errors?: Maybe<Array<CreateRelationshipError>>;
  relationship?: Maybe<Relationship>;
};

export type CreateRelationshipTypeInput = {
  objectSubjectName?: InputMaybe<Scalars['String']['input']>;
  subjectObjectName: Scalars['String']['input'];
};

export type CreateRelationshipTypePayload = {
  __typename?: 'CreateRelationshipTypePayload';
  relationshipType?: Maybe<Array<RelationshipType>>;
};

export type CreateReportError = ConflictError | UnauthorizedError;

export type CreateReportInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  reportText?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportPayload = {
  __typename?: 'CreateReportPayload';
  errors?: Maybe<Array<CreateReportError>>;
  report?: Maybe<Report>;
};

export type CreateRequestInput = {
  applicationId?: InputMaybe<Scalars['UUID']['input']>;
  contactId: Scalars['UUID']['input'];
  deadline?: InputMaybe<Scalars['Instant']['input']>;
  items: Array<RequestItemInput>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CreateRequestPayload = {
  __typename?: 'CreateRequestPayload';
  request?: Maybe<Request>;
};

export type CreateScholarshipError = ConflictError | UnauthorizedError;

export type CreateScholarshipFeeError = ConflictError | UnauthorizedError;

export type CreateScholarshipFeeInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  scholarshipId: Scalars['UUID']['input'];
};

export type CreateScholarshipFeePayload = {
  __typename?: 'CreateScholarshipFeePayload';
  errors?: Maybe<Array<CreateScholarshipFeeError>>;
  scholarshipFee?: Maybe<ScholarshipFee>;
};

export type CreateScholarshipInput = {
  closesAtDate: Scalars['Date']['input'];
  closesAtTime: Scalars['TimeSpan']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  doesRequireIsee: Scalars['Boolean']['input'];
  doesRequireOAPayment: Scalars['Boolean']['input'];
  isActive: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  sponsorId: Scalars['UUID']['input'];
  typeId: Scalars['UUID']['input'];
};

export type CreateScholarshipPayload = {
  __typename?: 'CreateScholarshipPayload';
  errors?: Maybe<Array<CreateScholarshipError>>;
  scholarship?: Maybe<Scholarship>;
};

export type CreateScholarshipProgramError = ConflictError | UnauthorizedError;

export type CreateScholarshipProgramInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programId: Scalars['UUID']['input'];
  scholarshipId: Scalars['UUID']['input'];
};

export type CreateScholarshipProgramPayload = {
  __typename?: 'CreateScholarshipProgramPayload';
  errors?: Maybe<Array<CreateScholarshipProgramError>>;
  scholarshipProgram?: Maybe<ScholarshipProgram>;
};

export type CreateScholarshipProgramRequestError = ConflictError | UnauthorizedError;

export type CreateScholarshipProgramRequestInput = {
  coverage: Scalars['Decimal']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programApplicationId: Scalars['UUID']['input'];
  scholarshipRequestId: Scalars['UUID']['input'];
};

export type CreateScholarshipProgramRequestPayload = {
  __typename?: 'CreateScholarshipProgramRequestPayload';
  errors?: Maybe<Array<CreateScholarshipProgramRequestError>>;
  scholarshipProgramRequest?: Maybe<ScholarshipProgramRequest>;
};

export type CreateScholarshipQuestionError = ConflictError | UnauthorizedError;

export type CreateScholarshipQuestionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  scholarshipId: Scalars['UUID']['input'];
  sorting: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type CreateScholarshipQuestionPayload = {
  __typename?: 'CreateScholarshipQuestionPayload';
  errors?: Maybe<Array<CreateScholarshipQuestionError>>;
  scholarshipQuestion?: Maybe<ScholarshipQuestion>;
};

export type CreateScholarshipRequestAnswerError = ConflictError | UnauthorizedError;

export type CreateScholarshipRequestAnswerInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  questionId: Scalars['UUID']['input'];
  scholarshipRequestId: Scalars['UUID']['input'];
  text: Scalars['String']['input'];
};

export type CreateScholarshipRequestAnswerPayload = {
  __typename?: 'CreateScholarshipRequestAnswerPayload';
  errors?: Maybe<Array<CreateScholarshipRequestAnswerError>>;
  scholarshipRequestAnswer?: Maybe<ScholarshipRequestAnswer>;
};

export type CreateScholarshipRequestError = ConflictError | UnauthorizedError;

export type CreateScholarshipRequestInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  scholarshipId: Scalars['UUID']['input'];
  state: ScholarshipRequestState;
};

export type CreateScholarshipRequestPayload = {
  __typename?: 'CreateScholarshipRequestPayload';
  errors?: Maybe<Array<CreateScholarshipRequestError>>;
  scholarshipRequest?: Maybe<ScholarshipRequest>;
};

export type CreateScholarshipTypeError = ConflictError | UnauthorizedError;

export type CreateScholarshipTypeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type CreateScholarshipTypePayload = {
  __typename?: 'CreateScholarshipTypePayload';
  errors?: Maybe<Array<CreateScholarshipTypeError>>;
  scholarshipType?: Maybe<ScholarshipType>;
};

export type CreateSchoolError = ConflictError | UnauthorizedError;

export type CreateSchoolInput = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isVerified: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originSchoolId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateSchoolPayload = {
  __typename?: 'CreateSchoolPayload';
  errors?: Maybe<Array<CreateSchoolError>>;
  school?: Maybe<School>;
};

export type CreateSchoolReportError = ConflictError | UnauthorizedError;

export type CreateSchoolReportInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  schoolId?: InputMaybe<Scalars['UUID']['input']>;
  studentId: Scalars['UUID']['input'];
  type: AcademicResultsType;
  year: Scalars['String']['input'];
};

export type CreateSchoolReportItemError = ConflictError | UnauthorizedError;

export type CreateSchoolReportItemInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  grade?: InputMaybe<Scalars['String']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  schoolReportId: Scalars['UUID']['input'];
  subject: Scalars['String']['input'];
};

export type CreateSchoolReportItemPayload = {
  __typename?: 'CreateSchoolReportItemPayload';
  errors?: Maybe<Array<CreateSchoolReportItemError>>;
  schoolReportItem?: Maybe<SchoolReportItem>;
};

export type CreateSchoolReportPayload = {
  __typename?: 'CreateSchoolReportPayload';
  errors?: Maybe<Array<CreateSchoolReportError>>;
  schoolReport?: Maybe<SchoolReport>;
};

export type CreateSchoolReportSummaryError = ConflictError | UnauthorizedError;

export type CreateSchoolReportSummaryInput = {
  averageGrade?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  schoolId?: InputMaybe<Scalars['UUID']['input']>;
  studentId: Scalars['UUID']['input'];
  type: SchoolReportType;
  year: Scalars['String']['input'];
};

export type CreateSchoolReportSummaryPayload = {
  __typename?: 'CreateSchoolReportSummaryPayload';
  errors?: Maybe<Array<CreateSchoolReportSummaryError>>;
  schoolReportSummary?: Maybe<SchoolReportSummary>;
};

export type CreateScoreError = ConflictError | UnauthorizedError;

export type CreateScoreInput = {
  applicationId: Scalars['UUID']['input'];
  committeeScore?: InputMaybe<Scalars['Float']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  giuntiTestScore?: InputMaybe<Scalars['Float']['input']>;
  hospitalityScore?: InputMaybe<Scalars['Float']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterScore?: InputMaybe<Scalars['Float']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  scholasticScore?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateScorePayload = {
  __typename?: 'CreateScorePayload';
  errors?: Maybe<Array<CreateScoreError>>;
  score?: Maybe<Score>;
};

export type CreateSponsorError = ConflictError | UnauthorizedError;

export type CreateSponsorInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type CreateSponsorPayload = {
  __typename?: 'CreateSponsorPayload';
  errors?: Maybe<Array<CreateSponsorError>>;
  sponsor?: Maybe<Sponsor>;
};

export type CreateStudentError = ConflictError | UnauthorizedError;

export type CreateStudentInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  contactId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  doesRequireReligionServicesAccess?: InputMaybe<Scalars['Boolean']['input']>;
  expectationsDescription?: InputMaybe<Scalars['String']['input']>;
  externalLink?: InputMaybe<Scalars['URL']['input']>;
  familyDescription?: InputMaybe<Scalars['String']['input']>;
  growthDescription?: InputMaybe<Scalars['String']['input']>;
  healthDescription?: InputMaybe<Scalars['String']['input']>;
  isFamilyWillingToHost?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originalSchoolId?: InputMaybe<Scalars['UUID']['input']>;
  personalityDescription?: InputMaybe<Scalars['String']['input']>;
  religionAttendanceFrequency?: InputMaybe<Scalars['String']['input']>;
  religionDescription?: InputMaybe<Scalars['String']['input']>;
  schoolDescription?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['UUID']['input']>;
  willNotPartecipateToNarritornoReason?: InputMaybe<Scalars['String']['input']>;
  willNotPartecipateToOrientationReason?: InputMaybe<Scalars['String']['input']>;
  willPartecipateToNarritorno?: InputMaybe<Scalars['Boolean']['input']>;
  willPartecipateToOrientation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateStudentPayload = {
  __typename?: 'CreateStudentPayload';
  errors?: Maybe<Array<CreateStudentError>>;
  student?: Maybe<Student>;
};

export type CreateTaskError = BadRequestError | ForbiddenError | NotFoundError | UnauthorizedError;

export type CreateTaskInput = {
  assigneeIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deadline?: InputMaybe<Scalars['Instant']['input']>;
  labelIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  state: TaskState;
  targetId?: InputMaybe<Scalars['UUID']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type: TaskType;
};

export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  errors?: Maybe<Array<CreateTaskError>>;
  task?: Maybe<Task>;
};

export type CreateThreadError = ConflictError | UnauthorizedError;

export type CreateThreadInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  isRead: Scalars['Boolean']['input'];
  isResolved: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['UUID']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
  type: ThreadType;
};

export type CreateThreadPayload = {
  __typename?: 'CreateThreadPayload';
  errors?: Maybe<Array<CreateThreadError>>;
  thread?: Maybe<Thread>;
};

export type CreateUserError = ConflictError;

export type CreateUserInput = {
  avatarId?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  roleNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  errors?: Maybe<Array<CreateUserError>>;
  user?: Maybe<User>;
};

export type CreateViewError = ConflictError | UnauthorizedError;

export type CreateViewInput = {
  columnOrdersView?: InputMaybe<Array<ColumnOrdersViewInput>>;
  columnsView?: InputMaybe<Array<ColumnsViewInput>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  isDefault: Scalars['Boolean']['input'];
  isPublic: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: Scalars['String']['input'];
};

export type CreateViewPayload = {
  __typename?: 'CreateViewPayload';
  errors?: Maybe<Array<CreateViewError>>;
  view?: Maybe<View>;
};

export type CreateWorkflowInfoError = ConflictError | UnauthorizedError;

export type CreateWorkflowInfoInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  effects?: InputMaybe<Array<Scalars['String']['input']>>;
  inputs?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: WorkflowType;
  workflowDescription?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkflowInfoPayload = {
  __typename?: 'CreateWorkflowInfoPayload';
  errors?: Maybe<Array<CreateWorkflowInfoError>>;
  workflowInfo?: Maybe<WorkflowInfo>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  allScholarshipsCurrentTimeSeries: Array<TimeSeriesItemOfInt32>;
  allScholarshipsPreviousTimeSeries: Array<TimeSeriesItemOfInt32>;
  applicationClosedProgramSankey: Array<SankeyItem>;
  applicationsWithProgramsCount: Scalars['Int']['output'];
  assignedProgramCount: Scalars['Int']['output'];
  bothProgramCount: Scalars['Int']['output'];
  closedAccountsCurrentCount: Scalars['Int']['output'];
  closedAccountsPreviousCount: Scalars['Int']['output'];
  completedAccountsCurrentCount: Scalars['Int']['output'];
  completedAccountsCurrentTimeSeries: Array<TimeSeriesItemOfInt32>;
  completedAccountsPreviousCount: Scalars['Int']['output'];
  completedAccountsPreviousTimeSeries: Array<TimeSeriesItemOfInt32>;
  convertedLeadCurrentCount: Scalars['Int']['output'];
  convertedLeadCurrentRatio: Scalars['Float']['output'];
  convertedLeadPreviousRatio: Scalars['Float']['output'];
  convertedToRelationshipCurrentCounts: Array<CountItem>;
  convertedToRelationshipPreviousCounts: Array<CountItem>;
  convertedToStudentCurrentCount: Scalars['Int']['output'];
  convertedToStudentPreviousCount: Scalars['Int']['output'];
  departedByDestinationCounts: Array<CountItem>;
  departedCount: Scalars['Int']['output'];
  inpsScholarshipsCurrentTimeSeries: Array<TimeSeriesItemOfInt32>;
  inpsScholarshipsPreviousTimeSeries: Array<TimeSeriesItemOfInt32>;
  interculturaScholarshipsByCoverageCurrent: Array<KeyValuePairOfDecimalAndInt32>;
  interculturaScholarshipsByCoveragePrevious: Array<KeyValuePairOfDecimalAndInt32>;
  interculturaScholarshipsCurrentTimeSeries: Array<TimeSeriesItemOfInt32>;
  interculturaScholarshipsPreviousTimeSeries: Array<TimeSeriesItemOfInt32>;
  linguisticProgramCount: Scalars['Int']['output'];
  newAccountsCurrentCount: Scalars['Int']['output'];
  newAccountsPreviousCount: Scalars['Int']['output'];
  newLeadCurrentCount: Scalars['Int']['output'];
  newLeadPreviousCount: Scalars['Int']['output'];
  nhProgramCount: Scalars['Int']['output'];
  partnersScholarshipsCurrentTimeSeries: Array<TimeSeriesItemOfInt32>;
  partnersScholarshipsPreviousTimeSeries: Array<TimeSeriesItemOfInt32>;
  pendingProgramCount: Scalars['Int']['output'];
  reserveProgramCount: Scalars['Int']['output'];
  scholasticProgramCount: Scalars['Int']['output'];
  shProgramCount: Scalars['Int']['output'];
};

export type DashboardFiltersInput = {
  areaId?: InputMaybe<Scalars['UUID']['input']>;
  contestId?: InputMaybe<Scalars['UUID']['input']>;
  endDate: Scalars['Instant']['input'];
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  startDate: Scalars['Instant']['input'];
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeleteAnswerError = NotFoundError | UnauthorizedError;

export type DeleteAnswerInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteAnswerPayload = {
  __typename?: 'DeleteAnswerPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteAnswerError>>;
};

export type DeleteApiKeyError = NotFoundError | UnauthorizedError;

export type DeleteApiKeyInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteApiKeyPayload = {
  __typename?: 'DeleteApiKeyPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteApiKeyError>>;
};

export type DeleteApplicationError = NotFoundError | UnauthorizedError;

export type DeleteApplicationInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteApplicationPayload = {
  __typename?: 'DeleteApplicationPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteApplicationError>>;
};

export type DeleteAreaError = NotFoundError | UnauthorizedError;

export type DeleteAreaInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteAreaPayload = {
  __typename?: 'DeleteAreaPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteAreaError>>;
};

export type DeleteCannotLiveWithAnimalError = NotFoundError | UnauthorizedError;

export type DeleteCannotLiveWithAnimalInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteCannotLiveWithAnimalPayload = {
  __typename?: 'DeleteCannotLiveWithAnimalPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteCannotLiveWithAnimalError>>;
};

export type DeleteConsentError = NotFoundError | UnauthorizedError;

export type DeleteConsentInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteConsentPayload = {
  __typename?: 'DeleteConsentPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteConsentError>>;
};

export type DeleteContactError = NotFoundError | UnauthorizedError;

export type DeleteContactInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteContactPayload = {
  __typename?: 'DeleteContactPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteContactError>>;
};

export type DeleteContestError = NotFoundError | UnauthorizedError;

export type DeleteContestInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteContestPayload = {
  __typename?: 'DeleteContestPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteContestError>>;
};

export type DeleteDocumentError = NotFoundError | UnauthorizedError;

export type DeleteDocumentInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteDocumentError>>;
};

export type DeleteEligibilityTestDateError = NotFoundError | UnauthorizedError;

export type DeleteEligibilityTestDateInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteEligibilityTestDatePayload = {
  __typename?: 'DeleteEligibilityTestDatePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteEligibilityTestDateError>>;
};

export type DeleteEligibilityTestError = NotFoundError | UnauthorizedError;

export type DeleteEligibilityTestInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteEligibilityTestPayload = {
  __typename?: 'DeleteEligibilityTestPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteEligibilityTestError>>;
};

export type DeleteEmergencyContactError = NotFoundError | UnauthorizedError;

export type DeleteEmergencyContactInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteEmergencyContactPayload = {
  __typename?: 'DeleteEmergencyContactPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteEmergencyContactError>>;
};

export type DeleteFlowSchemaError = NotFoundError | UnauthorizedError;

export type DeleteFlowSchemaInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteFlowSchemaPayload = {
  __typename?: 'DeleteFlowSchemaPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteFlowSchemaError>>;
};

export type DeleteHouseError = NotFoundError | UnauthorizedError;

export type DeleteHouseInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteHousePayload = {
  __typename?: 'DeleteHousePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteHouseError>>;
};

export type DeleteInternationalApplicationError = NotFoundError | UnauthorizedError;

export type DeleteInternationalApplicationInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteInternationalApplicationPayload = {
  __typename?: 'DeleteInternationalApplicationPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteInternationalApplicationError>>;
};

export type DeleteIseeError = NotFoundError | UnauthorizedError;

export type DeleteIseeInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteIseePayload = {
  __typename?: 'DeleteIseePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteIseeError>>;
};

export type DeleteLabelError = NotFoundError | UnauthorizedError;

export type DeleteLabelInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteLabelError>>;
};

export type DeleteLanguageProficiencyError = NotFoundError | UnauthorizedError;

export type DeleteLanguageProficiencyInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLanguageProficiencyPayload = {
  __typename?: 'DeleteLanguageProficiencyPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteLanguageProficiencyError>>;
};

export type DeleteLeadError = NotFoundError | UnauthorizedError;

export type DeleteLeadInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLeadPayload = {
  __typename?: 'DeleteLeadPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteLeadError>>;
};

export type DeleteLeadTypeError = NotFoundError | UnauthorizedError;

export type DeleteLeadTypeInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLeadTypePayload = {
  __typename?: 'DeleteLeadTypePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteLeadTypeError>>;
};

export type DeleteLocalCenterError = NotFoundError | UnauthorizedError;

export type DeleteLocalCenterInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLocalCenterPayload = {
  __typename?: 'DeleteLocalCenterPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteLocalCenterError>>;
};

export type DeleteProgramApplicationAnswerError = NotFoundError | UnauthorizedError;

export type DeleteProgramApplicationAnswerInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteProgramApplicationAnswerPayload = {
  __typename?: 'DeleteProgramApplicationAnswerPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteProgramApplicationAnswerError>>;
};

export type DeleteProgramApplicationError = NotFoundError | UnauthorizedError;

export type DeleteProgramApplicationInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteProgramApplicationPayload = {
  __typename?: 'DeleteProgramApplicationPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteProgramApplicationError>>;
};

export type DeleteProgramError = NotFoundError | UnauthorizedError;

export type DeleteProgramInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteProgramPayload = {
  __typename?: 'DeleteProgramPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteProgramError>>;
};

export type DeleteProgramQuestionError = NotFoundError | UnauthorizedError;

export type DeleteProgramQuestionInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteProgramQuestionPayload = {
  __typename?: 'DeleteProgramQuestionPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteProgramQuestionError>>;
};

export type DeleteRelationshipError = NotFoundError | UnauthorizedError;

export type DeleteRelationshipInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type DeleteRelationshipPayload = {
  __typename?: 'DeleteRelationshipPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteRelationshipError>>;
};

export type DeleteRelationshipTypeError = NotFoundError | UnauthorizedError;

export type DeleteRelationshipTypeInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type DeleteRelationshipTypePayload = {
  __typename?: 'DeleteRelationshipTypePayload';
  deleted?: Maybe<Array<Deleted>>;
  errors?: Maybe<Array<DeleteRelationshipTypeError>>;
};

export type DeleteReportError = NotFoundError | UnauthorizedError;

export type DeleteReportInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteReportPayload = {
  __typename?: 'DeleteReportPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteReportError>>;
};

export type DeleteRequestError = NotFoundError | UnauthorizedError;

export type DeleteRequestInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteRequestPayload = {
  __typename?: 'DeleteRequestPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteRequestError>>;
};

export type DeleteScholarshipError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipFeeError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipFeeInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipFeePayload = {
  __typename?: 'DeleteScholarshipFeePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipFeeError>>;
};

export type DeleteScholarshipInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipPayload = {
  __typename?: 'DeleteScholarshipPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipError>>;
};

export type DeleteScholarshipProgramError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipProgramInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipProgramPayload = {
  __typename?: 'DeleteScholarshipProgramPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipProgramError>>;
};

export type DeleteScholarshipProgramRequestError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipProgramRequestInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipProgramRequestPayload = {
  __typename?: 'DeleteScholarshipProgramRequestPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipProgramRequestError>>;
};

export type DeleteScholarshipQuestionError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipQuestionInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipQuestionPayload = {
  __typename?: 'DeleteScholarshipQuestionPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipQuestionError>>;
};

export type DeleteScholarshipRequestAnswerError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipRequestAnswerInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipRequestAnswerPayload = {
  __typename?: 'DeleteScholarshipRequestAnswerPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipRequestAnswerError>>;
};

export type DeleteScholarshipRequestError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipRequestInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipRequestPayload = {
  __typename?: 'DeleteScholarshipRequestPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipRequestError>>;
};

export type DeleteScholarshipTypeError = NotFoundError | UnauthorizedError;

export type DeleteScholarshipTypeInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScholarshipTypePayload = {
  __typename?: 'DeleteScholarshipTypePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScholarshipTypeError>>;
};

export type DeleteSchoolError = NotFoundError | UnauthorizedError;

export type DeleteSchoolInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteSchoolPayload = {
  __typename?: 'DeleteSchoolPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteSchoolError>>;
};

export type DeleteSchoolReportError = NotFoundError | UnauthorizedError;

export type DeleteSchoolReportInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteSchoolReportItemError = NotFoundError | UnauthorizedError;

export type DeleteSchoolReportItemInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteSchoolReportItemPayload = {
  __typename?: 'DeleteSchoolReportItemPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteSchoolReportItemError>>;
};

export type DeleteSchoolReportPayload = {
  __typename?: 'DeleteSchoolReportPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteSchoolReportError>>;
};

export type DeleteSchoolReportSummaryError = NotFoundError | UnauthorizedError;

export type DeleteSchoolReportSummaryInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteSchoolReportSummaryPayload = {
  __typename?: 'DeleteSchoolReportSummaryPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteSchoolReportSummaryError>>;
};

export type DeleteScoreError = NotFoundError | UnauthorizedError;

export type DeleteScoreInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteScorePayload = {
  __typename?: 'DeleteScorePayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteScoreError>>;
};

export type DeleteSponsorError = NotFoundError | UnauthorizedError;

export type DeleteSponsorInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteSponsorPayload = {
  __typename?: 'DeleteSponsorPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteSponsorError>>;
};

export type DeleteStudentError = NotFoundError | UnauthorizedError;

export type DeleteStudentInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteStudentPayload = {
  __typename?: 'DeleteStudentPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteStudentError>>;
};

export type DeleteTaskError = NotFoundError | UnauthorizedError;

export type DeleteTaskInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteTaskPayload = {
  __typename?: 'DeleteTaskPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteTaskError>>;
};

export type DeleteThreadError = NotFoundError | UnauthorizedError;

export type DeleteThreadInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteThreadPayload = {
  __typename?: 'DeleteThreadPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteThreadError>>;
};

export type DeleteViewError = NotFoundError | UnauthorizedError;

export type DeleteViewInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteViewPayload = {
  __typename?: 'DeleteViewPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteViewError>>;
};

export type DeleteWorkflowInfoError = NotFoundError | UnauthorizedError;

export type DeleteWorkflowInfoInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteWorkflowInfoPayload = {
  __typename?: 'DeleteWorkflowInfoPayload';
  deleted?: Maybe<Deleted>;
  errors?: Maybe<Array<DeleteWorkflowInfoError>>;
};

export type Deleted = {
  __typename?: 'Deleted';
  deletedAt?: Maybe<Scalars['Instant']['output']>;
  deletedBy?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
};

export enum DietaryPreference {
  Omnivore = 'OMNIVORE',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
}

export type Document = {
  __typename?: 'Document';
  contentLength: Scalars['Long']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  fileName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  uri: Scalars['String']['output'];
};

export type DocumentEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentFilterInput = {
  and?: InputMaybe<Array<DocumentFilterInput>>;
  contentLength?: InputMaybe<LongOperationFilterInput>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<DocumentFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  uri?: InputMaybe<StringOperationFilterInput>;
};

export type DocumentSortInput = {
  contentLength?: InputMaybe<SortEnumType>;
  contentType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  uri?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type DocumentsCollectionSegment = {
  __typename?: 'DocumentsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Document>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EligibilityTest = {
  __typename?: 'EligibilityTest';
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  eligibilityTestDate?: Maybe<Scalars['Date']['output']>;
  eligibilityTestOutcome?: Maybe<Scalars['String']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  giuntiCode?: Maybe<Scalars['String']['output']>;
  giuntiPassword?: Maybe<Scalars['String']['output']>;
  hasPassedEligibilityTest?: Maybe<Scalars['Boolean']['output']>;
  hasTakenEligibilityTest?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  lastCredentialsSend?: Maybe<Scalars['DateTime']['output']>;
  lastDateSend?: Maybe<Scalars['DateTime']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  testDateId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type EligibilityTestEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type EligibilityTestDate = {
  __typename?: 'EligibilityTestDate';
  availableSeats: Scalars['Int']['output'];
  contestId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  date: Scalars['LocalDate']['output'];
  deadLineDate: Scalars['LocalDate']['output'];
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  seats: Scalars['Int']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type EligibilityTestDateEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type EligibilityTestDateFilterInput = {
  and?: InputMaybe<Array<EligibilityTestDateFilterInput>>;
  availableSeats?: InputMaybe<IntOperationFilterInput>;
  contestId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  date?: InputMaybe<LocalDateFilterInput>;
  deadLineDate?: InputMaybe<LocalDateFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<EligibilityTestDateFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  seats?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type EligibilityTestDateSortInput = {
  availableSeats?: InputMaybe<SortEnumType>;
  contestId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  deadLineDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  seats?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EligibilityTestDatesCollectionSegment = {
  __typename?: 'EligibilityTestDatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EligibilityTestDate>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EligibilityTestFilterInput = {
  and?: InputMaybe<Array<EligibilityTestFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  eligibilityTestDate?: InputMaybe<DateOperationFilterInput>;
  eligibilityTestOutcome?: InputMaybe<StringOperationFilterInput>;
  giuntiCode?: InputMaybe<StringOperationFilterInput>;
  giuntiPassword?: InputMaybe<StringOperationFilterInput>;
  hasPassedEligibilityTest?: InputMaybe<BooleanOperationFilterInput>;
  hasTakenEligibilityTest?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  lastCredentialsSend?: InputMaybe<DateTimeOperationFilterInput>;
  lastDateSend?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EligibilityTestFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  testDateId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type EligibilityTestSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  eligibilityTestDate?: InputMaybe<SortEnumType>;
  eligibilityTestOutcome?: InputMaybe<SortEnumType>;
  giuntiCode?: InputMaybe<SortEnumType>;
  giuntiPassword?: InputMaybe<SortEnumType>;
  hasPassedEligibilityTest?: InputMaybe<SortEnumType>;
  hasTakenEligibilityTest?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  lastCredentialsSend?: InputMaybe<SortEnumType>;
  lastDateSend?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  testDateId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EligibilityTestsCollectionSegment = {
  __typename?: 'EligibilityTestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EligibilityTest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  contact: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  relation?: Maybe<Scalars['String']['output']>;
  studentId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type EmergencyContactEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type EmergencyContactFilterInput = {
  and?: InputMaybe<Array<EmergencyContactFilterInput>>;
  contact?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<EmergencyContactFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  relation?: InputMaybe<StringOperationFilterInput>;
  studentId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type EraFilterInput = {
  and?: InputMaybe<Array<EraFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EraFilterInput>>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  data?: Maybe<Scalars['JSON']['output']>;
  firedAt: Scalars['Instant']['output'];
  id: Scalars['UUID']['output'];
  targetId?: Maybe<Scalars['UUID']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type EventFilterInput = {
  and?: InputMaybe<Array<EventFilterInput>>;
  data?: InputMaybe<JsonElementFilterInput>;
  firedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
  targetId?: InputMaybe<UuidOperationFilterInput>;
  targetType?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type EventSortInput = {
  data?: InputMaybe<SortEnumType>;
  firedAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  targetId?: InputMaybe<SortEnumType>;
  targetType?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EventsCollectionSegment = {
  __typename?: 'EventsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Event>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type Flow = {
  __typename?: 'Flow';
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  states: Array<FlowState>;
  status: FlowStatus;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
};

export type FlowInput = {
  name: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  states: Array<FlowStateInput>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FlowSchema = {
  __typename?: 'FlowSchema';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  flows?: Maybe<Array<Flow>>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type FlowSchemaEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowSchemaFilterInput = {
  and?: InputMaybe<Array<FlowSchemaFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FlowSchemaFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type FlowSchemaSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type FlowSchemasCollectionSegment = {
  __typename?: 'FlowSchemasCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<FlowSchema>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type FlowState = {
  __typename?: 'FlowState';
  isCompleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type FlowStateInput = {
  isCompleted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export enum FlowStatus {
  Completed = 'COMPLETED',
  Started = 'STARTED',
  Unstarted = 'UNSTARTED',
}

export type FlowUpdateInput = {
  setCompleted: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  stateUpdates?: InputMaybe<Array<KeyValuePairOfStringAndBooleanInput>>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ForbiddenError = Error & {
  __typename?: 'ForbiddenError';
  message: Scalars['String']['output'];
};

export type House = {
  __typename?: 'House';
  address: Address;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  residentsIds: Array<Scalars['UUID']['output']>;
  type: HouseType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type HouseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type HouseFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<HouseFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<HouseFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  residentsIds?: InputMaybe<ListUuidOperationFilterInput>;
  type?: InputMaybe<HouseTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type HouseSortInput = {
  address?: InputMaybe<AddressSortInput>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum HouseType {
  Main = 'MAIN',
  Second = 'SECOND',
}

export type HouseTypeOperationFilterInput = {
  eq?: InputMaybe<HouseType>;
  in?: InputMaybe<Array<HouseType>>;
  neq?: InputMaybe<HouseType>;
  nin?: InputMaybe<Array<HouseType>>;
};

/** A segment of a collection. */
export type HousesCollectionSegment = {
  __typename?: 'HousesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<House>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type InternationalApplication = {
  __typename?: 'InternationalApplication';
  anticipatedGraduationDate?: Maybe<Scalars['YearMonth']['output']>;
  applicationId: Scalars['UUID']['output'];
  canEatDairy?: Maybe<Scalars['Boolean']['output']>;
  canEatFish?: Maybe<Scalars['Boolean']['output']>;
  canEatPoultry?: Maybe<Scalars['Boolean']['output']>;
  cannotLiveWithAnimals: Array<CannotLiveWithAnimal>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  dietaryPreference?: Maybe<DietaryPreference>;
  dietaryRestrictionsDescription?: Maybe<Scalars['String']['output']>;
  doesSmoke?: Maybe<Scalars['Boolean']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  familyMemberHostDescription?: Maybe<Scalars['String']['output']>;
  familyMemberParticipantDescription?: Maybe<Scalars['String']['output']>;
  frequentAbsencesDescription?: Maybe<Scalars['String']['output']>;
  hasDietaryRestrictions?: Maybe<Scalars['Boolean']['output']>;
  hasFamilyMemberHosted?: Maybe<Scalars['Boolean']['output']>;
  hasFamilyMemberParticipated?: Maybe<Scalars['Boolean']['output']>;
  hasMedicalRequirements?: Maybe<Scalars['Boolean']['output']>;
  hasMissedYearOrSemester?: Maybe<Scalars['Boolean']['output']>;
  hasRelationLivingAbroad?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isFrequentlyAbsent?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  languageProficiencies: Array<LanguageProficiency>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  medicalRequirementsDescription?: Maybe<Scalars['String']['output']>;
  missedYearOrSemesterDescription?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  previousAbroadExperienceDescription?: Maybe<Scalars['String']['output']>;
  relationLivingAbroadDescription?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  willAbstainFromSmoking?: Maybe<Scalars['Boolean']['output']>;
  yearsOfPrimarySchool?: Maybe<Scalars['String']['output']>;
  yearsOfSecondarySchool?: Maybe<Scalars['String']['output']>;
};

export type InternationalApplicationEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type InternationalApplicationFilterInput = {
  and?: InputMaybe<Array<InternationalApplicationFilterInput>>;
  anticipatedGraduationDate?: InputMaybe<YearMonthFilterInput>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  canEatDairy?: InputMaybe<BooleanOperationFilterInput>;
  canEatFish?: InputMaybe<BooleanOperationFilterInput>;
  canEatPoultry?: InputMaybe<BooleanOperationFilterInput>;
  cannotLiveWithAnimals?: InputMaybe<ListFilterInputTypeOfCannotLiveWithAnimalFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  dietaryPreference?: InputMaybe<NullableOfDietaryPreferenceOperationFilterInput>;
  dietaryRestrictionsDescription?: InputMaybe<StringOperationFilterInput>;
  doesSmoke?: InputMaybe<BooleanOperationFilterInput>;
  familyMemberHostDescription?: InputMaybe<StringOperationFilterInput>;
  familyMemberParticipantDescription?: InputMaybe<StringOperationFilterInput>;
  frequentAbsencesDescription?: InputMaybe<StringOperationFilterInput>;
  hasDietaryRestrictions?: InputMaybe<BooleanOperationFilterInput>;
  hasFamilyMemberHosted?: InputMaybe<BooleanOperationFilterInput>;
  hasFamilyMemberParticipated?: InputMaybe<BooleanOperationFilterInput>;
  hasMedicalRequirements?: InputMaybe<BooleanOperationFilterInput>;
  hasMissedYearOrSemester?: InputMaybe<BooleanOperationFilterInput>;
  hasRelationLivingAbroad?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isFrequentlyAbsent?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  languageProficiencies?: InputMaybe<ListFilterInputTypeOfLanguageProficiencyFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  medicalRequirementsDescription?: InputMaybe<StringOperationFilterInput>;
  missedYearOrSemesterDescription?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InternationalApplicationFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  previousAbroadExperienceDescription?: InputMaybe<StringOperationFilterInput>;
  relationLivingAbroadDescription?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  willAbstainFromSmoking?: InputMaybe<BooleanOperationFilterInput>;
  yearsOfPrimarySchool?: InputMaybe<StringOperationFilterInput>;
  yearsOfSecondarySchool?: InputMaybe<StringOperationFilterInput>;
};

export type InternationalApplicationSortInput = {
  anticipatedGraduationDate?: InputMaybe<SortEnumType>;
  applicationId?: InputMaybe<SortEnumType>;
  canEatDairy?: InputMaybe<SortEnumType>;
  canEatFish?: InputMaybe<SortEnumType>;
  canEatPoultry?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  dietaryPreference?: InputMaybe<SortEnumType>;
  dietaryRestrictionsDescription?: InputMaybe<SortEnumType>;
  doesSmoke?: InputMaybe<SortEnumType>;
  familyMemberHostDescription?: InputMaybe<SortEnumType>;
  familyMemberParticipantDescription?: InputMaybe<SortEnumType>;
  frequentAbsencesDescription?: InputMaybe<SortEnumType>;
  hasDietaryRestrictions?: InputMaybe<SortEnumType>;
  hasFamilyMemberHosted?: InputMaybe<SortEnumType>;
  hasFamilyMemberParticipated?: InputMaybe<SortEnumType>;
  hasMedicalRequirements?: InputMaybe<SortEnumType>;
  hasMissedYearOrSemester?: InputMaybe<SortEnumType>;
  hasRelationLivingAbroad?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isFrequentlyAbsent?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  medicalRequirementsDescription?: InputMaybe<SortEnumType>;
  missedYearOrSemesterDescription?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  previousAbroadExperienceDescription?: InputMaybe<SortEnumType>;
  relationLivingAbroadDescription?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  willAbstainFromSmoking?: InputMaybe<SortEnumType>;
  yearsOfPrimarySchool?: InputMaybe<SortEnumType>;
  yearsOfSecondarySchool?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type InternationalApplicationsCollectionSegment = {
  __typename?: 'InternationalApplicationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<InternationalApplication>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Isee = {
  __typename?: 'Isee';
  amount?: Maybe<Scalars['String']['output']>;
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  maxScholarshipAmount?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  range?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  year?: Maybe<Scalars['String']['output']>;
};

export type IseeEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type IseeFilterInput = {
  amount?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<IseeFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  maxScholarshipAmount?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IseeFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  range?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  year?: InputMaybe<StringOperationFilterInput>;
};

export type IseeSortInput = {
  amount?: InputMaybe<SortEnumType>;
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  maxScholarshipAmount?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  range?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type IseesCollectionSegment = {
  __typename?: 'IseesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Isee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IsoDayOfWeekOperationFilterInput = {
  eq?: InputMaybe<Scalars['IsoDayOfWeek']['input']>;
  in?: InputMaybe<Array<Scalars['IsoDayOfWeek']['input']>>;
  neq?: InputMaybe<Scalars['IsoDayOfWeek']['input']>;
  nin?: InputMaybe<Array<Scalars['IsoDayOfWeek']['input']>>;
};

export type ItemIds = {
  __typename?: 'ItemIds';
  ids: Array<Scalars['UUID']['output']>;
};

export type JsonElementFilterInput = {
  and?: InputMaybe<Array<JsonElementFilterInput>>;
  or?: InputMaybe<Array<JsonElementFilterInput>>;
  valueKind?: InputMaybe<JsonValueKindOperationFilterInput>;
};

export enum JsonValueKind {
  Array = 'ARRAY',
  False = 'FALSE',
  Null = 'NULL',
  Number = 'NUMBER',
  Object = 'OBJECT',
  String = 'STRING',
  True = 'TRUE',
  Undefined = 'UNDEFINED',
}

export type JsonValueKindOperationFilterInput = {
  eq?: InputMaybe<JsonValueKind>;
  in?: InputMaybe<Array<JsonValueKind>>;
  neq?: InputMaybe<JsonValueKind>;
  nin?: InputMaybe<Array<JsonValueKind>>;
};

export type KeyValuePairOfDecimalAndInt32 = {
  __typename?: 'KeyValuePairOfDecimalAndInt32';
  key: Scalars['Decimal']['output'];
  value: Scalars['Int']['output'];
};

export type KeyValuePairOfStringAndBooleanInput = {
  key: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type KeyValuePairOfStringAndFlowUpdateInput = {
  key: Scalars['String']['input'];
  value: FlowUpdateInput;
};

export type KeyValuePairOfStringAndInt32 = {
  __typename?: 'KeyValuePairOfStringAndInt32';
  key: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type KeyValuePairOfStringAndString__ = {
  __typename?: 'KeyValuePairOfStringAndString__';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type Label = {
  __typename?: 'Label';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type LabelEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelFilterInput = {
  and?: InputMaybe<Array<LabelFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LabelFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  targetType?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type LabelSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  targetType?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type LabelsCollectionSegment = {
  __typename?: 'LabelsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Label>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type LanguageProficiency = {
  __typename?: 'LanguageProficiency';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  internationalApplicationId: Scalars['UUID']['output'];
  isPrimaryLanguage: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  language: Scalars['String']['output'];
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  listening: Scalars['Int']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  reading: Scalars['Int']['output'];
  speaking: Scalars['Int']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  writing: Scalars['Int']['output'];
  yearsOfStudy?: Maybe<Scalars['String']['output']>;
};

export type LanguageProficiencyEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LanguageProficiencyFilterInput = {
  and?: InputMaybe<Array<LanguageProficiencyFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  internationalApplicationId?: InputMaybe<UuidOperationFilterInput>;
  isPrimaryLanguage?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  language?: InputMaybe<StringOperationFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  listening?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LanguageProficiencyFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  reading?: InputMaybe<IntOperationFilterInput>;
  speaking?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  writing?: InputMaybe<IntOperationFilterInput>;
  yearsOfStudy?: InputMaybe<StringOperationFilterInput>;
};

export type Lead = {
  __typename?: 'Lead';
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  birthYear?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  contactId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  localCenter?: Maybe<LocalCenter>;
  localCenterId?: Maybe<Scalars['UUID']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  salesforceId?: Maybe<Scalars['String']['output']>;
  type: LeadType;
  typeId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type LeadEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadFilterInput = {
  and?: InputMaybe<Array<LeadFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  birthYear?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  localCenter?: InputMaybe<LocalCenterFilterInput>;
  localCenterId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<LeadFilterInput>>;
  origin?: InputMaybe<StringOperationFilterInput>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  salesforceId?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<LeadTypeFilterInput>;
  typeId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type LeadReference = {
  __typename?: 'LeadReference';
  leadId: Scalars['UUID']['output'];
};

export type LeadSortInput = {
  birthYear?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  localCenter?: InputMaybe<LocalCenterSortInput>;
  localCenterId?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  salesforceId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<LeadTypeSortInput>;
  typeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export type LeadType = {
  __typename?: 'LeadType';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type LeadTypeEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadTypeFilterInput = {
  and?: InputMaybe<Array<LeadTypeFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LeadTypeFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type LeadTypeSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type LeadTypesCollectionSegment = {
  __typename?: 'LeadTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<LeadType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type LeadsCollectionSegment = {
  __typename?: 'LeadsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Lead>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ListFilterInputTypeOfApplicationFilterInput = {
  all?: InputMaybe<ApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationFilterInput>;
  some?: InputMaybe<ApplicationFilterInput>;
};

export type ListFilterInputTypeOfCannotLiveWithAnimalFilterInput = {
  all?: InputMaybe<CannotLiveWithAnimalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CannotLiveWithAnimalFilterInput>;
  some?: InputMaybe<CannotLiveWithAnimalFilterInput>;
};

export type ListFilterInputTypeOfColumnOrdersViewFilterInput = {
  all?: InputMaybe<ColumnOrdersViewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ColumnOrdersViewFilterInput>;
  some?: InputMaybe<ColumnOrdersViewFilterInput>;
};

export type ListFilterInputTypeOfColumnsViewFilterInput = {
  all?: InputMaybe<ColumnsViewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ColumnsViewFilterInput>;
  some?: InputMaybe<ColumnsViewFilterInput>;
};

export type ListFilterInputTypeOfConsentFilterInput = {
  all?: InputMaybe<ConsentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsentFilterInput>;
  some?: InputMaybe<ConsentFilterInput>;
};

export type ListFilterInputTypeOfDocumentFilterInput = {
  all?: InputMaybe<DocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DocumentFilterInput>;
  some?: InputMaybe<DocumentFilterInput>;
};

export type ListFilterInputTypeOfEmergencyContactFilterInput = {
  all?: InputMaybe<EmergencyContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EmergencyContactFilterInput>;
  some?: InputMaybe<EmergencyContactFilterInput>;
};

export type ListFilterInputTypeOfEraFilterInput = {
  all?: InputMaybe<EraFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EraFilterInput>;
  some?: InputMaybe<EraFilterInput>;
};

export type ListFilterInputTypeOfHouseFilterInput = {
  all?: InputMaybe<HouseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<HouseFilterInput>;
  some?: InputMaybe<HouseFilterInput>;
};

export type ListFilterInputTypeOfIseeFilterInput = {
  all?: InputMaybe<IseeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<IseeFilterInput>;
  some?: InputMaybe<IseeFilterInput>;
};

export type ListFilterInputTypeOfJsonElementFilterInput = {
  all?: InputMaybe<JsonElementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JsonElementFilterInput>;
  some?: InputMaybe<JsonElementFilterInput>;
};

export type ListFilterInputTypeOfLabelFilterInput = {
  all?: InputMaybe<LabelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<LabelFilterInput>;
  some?: InputMaybe<LabelFilterInput>;
};

export type ListFilterInputTypeOfLanguageProficiencyFilterInput = {
  all?: InputMaybe<LanguageProficiencyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<LanguageProficiencyFilterInput>;
  some?: InputMaybe<LanguageProficiencyFilterInput>;
};

export type ListFilterInputTypeOfProgramApplicationAnswerFilterInput = {
  all?: InputMaybe<ProgramApplicationAnswerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProgramApplicationAnswerFilterInput>;
  some?: InputMaybe<ProgramApplicationAnswerFilterInput>;
};

export type ListFilterInputTypeOfProgramApplicationFilterInput = {
  all?: InputMaybe<ProgramApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProgramApplicationFilterInput>;
  some?: InputMaybe<ProgramApplicationFilterInput>;
};

export type ListFilterInputTypeOfProgramQuestionFilterInput = {
  all?: InputMaybe<ProgramQuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProgramQuestionFilterInput>;
  some?: InputMaybe<ProgramQuestionFilterInput>;
};

export type ListFilterInputTypeOfRelationshipFilterInput = {
  all?: InputMaybe<RelationshipFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RelationshipFilterInput>;
  some?: InputMaybe<RelationshipFilterInput>;
};

export type ListFilterInputTypeOfReportFilterInput = {
  all?: InputMaybe<ReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ReportFilterInput>;
  some?: InputMaybe<ReportFilterInput>;
};

export type ListFilterInputTypeOfScholarshipFeeFilterInput = {
  all?: InputMaybe<ScholarshipFeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipFeeFilterInput>;
  some?: InputMaybe<ScholarshipFeeFilterInput>;
};

export type ListFilterInputTypeOfScholarshipProgramFilterInput = {
  all?: InputMaybe<ScholarshipProgramFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipProgramFilterInput>;
  some?: InputMaybe<ScholarshipProgramFilterInput>;
};

export type ListFilterInputTypeOfScholarshipProgramRequestFilterInput = {
  all?: InputMaybe<ScholarshipProgramRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipProgramRequestFilterInput>;
  some?: InputMaybe<ScholarshipProgramRequestFilterInput>;
};

export type ListFilterInputTypeOfScholarshipQuestionFilterInput = {
  all?: InputMaybe<ScholarshipQuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipQuestionFilterInput>;
  some?: InputMaybe<ScholarshipQuestionFilterInput>;
};

export type ListFilterInputTypeOfScholarshipRequestAnswerFilterInput = {
  all?: InputMaybe<ScholarshipRequestAnswerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipRequestAnswerFilterInput>;
  some?: InputMaybe<ScholarshipRequestAnswerFilterInput>;
};

export type ListFilterInputTypeOfScholarshipRequestFilterInput = {
  all?: InputMaybe<ScholarshipRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScholarshipRequestFilterInput>;
  some?: InputMaybe<ScholarshipRequestFilterInput>;
};

export type ListFilterInputTypeOfSchoolReportFilterInput = {
  all?: InputMaybe<SchoolReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SchoolReportFilterInput>;
  some?: InputMaybe<SchoolReportFilterInput>;
};

export type ListFilterInputTypeOfSchoolReportItemFilterInput = {
  all?: InputMaybe<SchoolReportItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SchoolReportItemFilterInput>;
  some?: InputMaybe<SchoolReportItemFilterInput>;
};

export type ListFilterInputTypeOfSchoolReportSummaryFilterInput = {
  all?: InputMaybe<SchoolReportSummaryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SchoolReportSummaryFilterInput>;
  some?: InputMaybe<SchoolReportSummaryFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListProgramTypeOperationFilterInput = {
  all?: InputMaybe<ProgramTypeOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProgramTypeOperationFilterInput>;
  some?: InputMaybe<ProgramTypeOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type ListUuidOperationFilterInput = {
  all?: InputMaybe<UuidOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UuidOperationFilterInput>;
  some?: InputMaybe<UuidOperationFilterInput>;
};

export type LocalCenter = {
  __typename?: 'LocalCenter';
  address?: Maybe<Address>;
  area?: Maybe<Area>;
  areaId?: Maybe<Scalars['UUID']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  originLocalCenterId?: Maybe<Scalars['UUID']['output']>;
  president?: Maybe<Contact>;
  presidentId?: Maybe<Scalars['UUID']['output']>;
  search: Scalars['String']['output'];
  sendingResponsible?: Maybe<Contact>;
  sendingResponsibleId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type LocalCenterEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LocalCenterFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<LocalCenterFilterInput>>;
  area?: InputMaybe<AreaFilterInput>;
  areaId?: InputMaybe<UuidOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocalCenterFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originLocalCenterId?: InputMaybe<UuidOperationFilterInput>;
  president?: InputMaybe<ContactFilterInput>;
  presidentId?: InputMaybe<UuidOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  sendingResponsible?: InputMaybe<ContactFilterInput>;
  sendingResponsibleId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type LocalCenterSortInput = {
  address?: InputMaybe<AddressSortInput>;
  area?: InputMaybe<AreaSortInput>;
  areaId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originLocalCenterId?: InputMaybe<SortEnumType>;
  president?: InputMaybe<ContactSortInput>;
  presidentId?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  sendingResponsible?: InputMaybe<ContactSortInput>;
  sendingResponsibleId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type LocalCentersCollectionSegment = {
  __typename?: 'LocalCentersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<LocalCenter>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type LocalDateFilterInput = {
  and?: InputMaybe<Array<LocalDateFilterInput>>;
  calendar?: InputMaybe<CalendarSystemFilterInput>;
  day?: InputMaybe<IntOperationFilterInput>;
  dayOfWeek?: InputMaybe<IsoDayOfWeekOperationFilterInput>;
  dayOfYear?: InputMaybe<IntOperationFilterInput>;
  era?: InputMaybe<EraFilterInput>;
  month?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LocalDateFilterInput>>;
  year?: InputMaybe<IntOperationFilterInput>;
  yearOfEra?: InputMaybe<IntOperationFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type MailingLists = {
  __typename?: 'MailingLists';
  students: Array<Scalars['String']['output']>;
  studentsByFlowState: Array<StudentsByFlow>;
  studentsByLocalCenter: Array<StudentsByLocalCenter>;
  users: Array<Scalars['String']['output']>;
};

export type MailingListsStudentsArgs = {
  hasActiveApplication?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MailingListsStudentsByFlowStateArgs = {
  hasActiveApplication?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MailingListsStudentsByLocalCenterArgs = {
  hasActiveApplication?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MailingListsUsersArgs = {
  inRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Message = {
  __typename?: 'Message';
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  events?: Maybe<EventsCollectionSegment>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imapUid?: Maybe<Scalars['Long']['output']>;
  isSent: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  messageDate?: Maybe<Scalars['Instant']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  sentBy?: Maybe<Scalars['UUID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  threadId: Scalars['UUID']['output'];
  type: MessageType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type MessageEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type MessageFilterInput = {
  and?: InputMaybe<Array<MessageFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  html?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  imapUid?: InputMaybe<LongOperationFilterInput>;
  isSent?: InputMaybe<BooleanOperationFilterInput>;
  isStarred?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  messageDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  messageId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MessageFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  sentBy?: InputMaybe<UuidOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  threadId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<MessageTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type MessageSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  html?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  imapUid?: InputMaybe<SortEnumType>;
  isSent?: InputMaybe<SortEnumType>;
  isStarred?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  messageDate?: InputMaybe<SortEnumType>;
  messageId?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  sentBy?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  threadId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum MessageType {
  Contact = 'CONTACT',
  Intercultura = 'INTERCULTURA',
}

export type MessageTypeOperationFilterInput = {
  eq?: InputMaybe<MessageType>;
  in?: InputMaybe<Array<MessageType>>;
  neq?: InputMaybe<MessageType>;
  nin?: InputMaybe<Array<MessageType>>;
};

/** A segment of a collection. */
export type MessagesCollectionSegment = {
  __typename?: 'MessagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Message>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addApplicationLabels: AddApplicationLabelsResult;
  bulkSetAssignees: BulkSetAssigneesPayload;
  bulkSetLabels: BulkSetLabelsPayload;
  createAnswer: CreateAnswerPayload;
  createApiKey: CreateApiKeyPayload;
  createApplication: CreateApplicationPayload;
  createArea: CreateAreaPayload;
  createCannotLiveWithAnimal: CreateCannotLiveWithAnimalPayload;
  createConsent: CreateConsentPayload;
  createContact: CreateContactPayload;
  createContest: CreateContestPayload;
  createEligibilityTest: CreateEligibilityTestPayload;
  createEligibilityTestDate: CreateEligibilityTestDatePayload;
  createEmergencyContact: CreateEmergencyContactPayload;
  createFlowSchema: CreateFlowSchemaPayload;
  createHouse: CreateHousePayload;
  createInternationalApplication: CreateInternationalApplicationPayload;
  createIsee: CreateIseePayload;
  createLabel: CreateLabelPayload;
  createLanguageProficiency: CreateLanguageProficiencyPayload;
  createLead: CreateLeadPayload;
  createLeadType: CreateLeadTypePayload;
  createLocalCenter: CreateLocalCenterPayload;
  createMessage: CreateMessagePayload;
  createProgram: CreateProgramPayload;
  createProgramApplication: CreateProgramApplicationPayload;
  createProgramApplicationAnswer: CreateProgramApplicationAnswerPayload;
  createProgramQuestion: CreateProgramQuestionPayload;
  createRanking: CreateRankingPayload;
  createRelationship: CreateRelationshipPayload;
  createRelationshipType: CreateRelationshipTypePayload;
  createReport: CreateReportPayload;
  createRequest: CreateRequestPayload;
  createScholarship: CreateScholarshipPayload;
  createScholarshipFee: CreateScholarshipFeePayload;
  createScholarshipProgram: CreateScholarshipProgramPayload;
  createScholarshipProgramRequest: CreateScholarshipProgramRequestPayload;
  createScholarshipQuestion: CreateScholarshipQuestionPayload;
  createScholarshipRequest: CreateScholarshipRequestPayload;
  createScholarshipRequestAnswer: CreateScholarshipRequestAnswerPayload;
  createScholarshipType: CreateScholarshipTypePayload;
  createSchool: CreateSchoolPayload;
  createSchoolReport: CreateSchoolReportPayload;
  createSchoolReportItem: CreateSchoolReportItemPayload;
  createSchoolReportSummary: CreateSchoolReportSummaryPayload;
  createScore: CreateScorePayload;
  createSponsor: CreateSponsorPayload;
  createStudent: CreateStudentPayload;
  createTask: CreateTaskPayload;
  createThread: CreateThreadPayload;
  createUser: CreateUserPayload;
  createView: CreateViewPayload;
  createWorkflowInfo: CreateWorkflowInfoPayload;
  deleteAnswer: DeleteAnswerPayload;
  deleteApiKey: DeleteApiKeyPayload;
  deleteApplication: DeleteApplicationPayload;
  deleteArea: DeleteAreaPayload;
  deleteCannotLiveWithAnimal: DeleteCannotLiveWithAnimalPayload;
  deleteConsent: DeleteConsentPayload;
  deleteContact: DeleteContactPayload;
  deleteContest: DeleteContestPayload;
  deleteDocument: DeleteDocumentPayload;
  deleteEligibilityTest: DeleteEligibilityTestPayload;
  deleteEligibilityTestDate: DeleteEligibilityTestDatePayload;
  deleteEmergencyContact: DeleteEmergencyContactPayload;
  deleteFlowSchema: DeleteFlowSchemaPayload;
  deleteHouse: DeleteHousePayload;
  deleteInternationalApplication: DeleteInternationalApplicationPayload;
  deleteIsee: DeleteIseePayload;
  deleteLabel: DeleteLabelPayload;
  deleteLanguageProficiency: DeleteLanguageProficiencyPayload;
  deleteLead: DeleteLeadPayload;
  deleteLeadType: DeleteLeadTypePayload;
  deleteLocalCenter: DeleteLocalCenterPayload;
  deleteProgram: DeleteProgramPayload;
  deleteProgramApplication: DeleteProgramApplicationPayload;
  deleteProgramApplicationAnswer: DeleteProgramApplicationAnswerPayload;
  deleteProgramQuestion: DeleteProgramQuestionPayload;
  deleteRelationship: DeleteRelationshipPayload;
  deleteRelationshipType: DeleteRelationshipTypePayload;
  deleteReport: DeleteReportPayload;
  deleteRequest: DeleteRequestPayload;
  deleteScholarship: DeleteScholarshipPayload;
  deleteScholarshipFee: DeleteScholarshipFeePayload;
  deleteScholarshipProgram: DeleteScholarshipProgramPayload;
  deleteScholarshipProgramRequest: DeleteScholarshipProgramRequestPayload;
  deleteScholarshipQuestion: DeleteScholarshipQuestionPayload;
  deleteScholarshipRequest: DeleteScholarshipRequestPayload;
  deleteScholarshipRequestAnswer: DeleteScholarshipRequestAnswerPayload;
  deleteScholarshipType: DeleteScholarshipTypePayload;
  deleteSchool: DeleteSchoolPayload;
  deleteSchoolReport: DeleteSchoolReportPayload;
  deleteSchoolReportItem: DeleteSchoolReportItemPayload;
  deleteSchoolReportSummary: DeleteSchoolReportSummaryPayload;
  deleteScore: DeleteScorePayload;
  deleteSponsor: DeleteSponsorPayload;
  deleteStudent: DeleteStudentPayload;
  deleteTask: DeleteTaskPayload;
  deleteThread: DeleteThreadPayload;
  deleteView: DeleteViewPayload;
  deleteWorkflowInfo: DeleteWorkflowInfoPayload;
  setHouseResidents: SetHouseResidentsPayload;
  triggerWorkflow: TriggerWorkflowPayload;
  updateAnswer: UpdateAnswerPayload;
  updateApplication: UpdateApplicationPayload;
  updateArea: UpdateAreaPayload;
  updateCannotLiveWithAnimal: UpdateCannotLiveWithAnimalPayload;
  updateConsent: UpdateConsentPayload;
  updateContact: UpdateContactPayload;
  updateContest: UpdateContestPayload;
  updateEligibilityTest: UpdateEligibilityTestPayload;
  updateEligibilityTestDate: UpdateEligibilityTestDatePayload;
  updateEmergencyContact: UpdateEmergencyContactPayload;
  updateFlows: UpdateFlowsPayload;
  updateHouse: UpdateHousePayload;
  updateInternationalApplication: UpdateInternationalApplicationPayload;
  updateIsee: UpdateIseePayload;
  updateLabel: UpdateLabelPayload;
  updateLanguageProficiency: UpdateLanguageProficiencyPayload;
  updateLead: UpdateLeadPayload;
  updateLeadType: UpdateLeadTypePayload;
  updateLocalCenter: UpdateLocalCenterPayload;
  updateMessage: UpdateMessagePayload;
  updateProgram: UpdateProgramPayload;
  updateProgramApplication: UpdateProgramApplicationPayload;
  updateProgramApplicationAnswer: UpdateProgramApplicationAnswerPayload;
  updateProgramQuestion: UpdateProgramQuestionPayload;
  updateRanking: UpdateRankingPayload;
  updateRelationshipType: UpdateRelationshipTypePayload;
  updateReport: UpdateReportPayload;
  updateRequest: UpdateRequestPayload;
  updateScholarship: UpdateScholarshipPayload;
  updateScholarshipFee: UpdateScholarshipFeePayload;
  updateScholarshipProgram: UpdateScholarshipProgramPayload;
  updateScholarshipProgramRequest: UpdateScholarshipProgramRequestPayload;
  updateScholarshipQuestion: UpdateScholarshipQuestionPayload;
  updateScholarshipRequest: UpdateScholarshipRequestPayload;
  updateScholarshipRequestAnswer: UpdateScholarshipRequestAnswerPayload;
  updateScholarshipType: UpdateScholarshipTypePayload;
  updateSchool: UpdateSchoolPayload;
  updateSchoolReport: UpdateSchoolReportPayload;
  updateSchoolReportItem: UpdateSchoolReportItemPayload;
  updateSchoolReportSummary: UpdateSchoolReportSummaryPayload;
  updateScore: UpdateScorePayload;
  updateSponsor: UpdateSponsorPayload;
  updateStudent: UpdateStudentPayload;
  updateTask: UpdateTaskPayload;
  updateThread: UpdateThreadPayload;
  updateUser: UpdateUserPayload;
  updateView: UpdateViewPayload;
  updateWorkflowInfo: UpdateWorkflowInfoPayload;
  uploadDocument: UploadDocumentPayload;
};

export type MutationAddApplicationLabelsArgs = {
  labelIds: Array<Scalars['UUID']['input']>;
  where?: InputMaybe<AddApplicationLabelsResultFilterInput>;
};

export type MutationBulkSetAssigneesArgs = {
  input: BulkSetAssigneesInput;
};

export type MutationBulkSetLabelsArgs = {
  input: BulkSetLabelsInput;
};

export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationCreateAreaArgs = {
  input: CreateAreaInput;
};

export type MutationCreateCannotLiveWithAnimalArgs = {
  input: CreateCannotLiveWithAnimalInput;
};

export type MutationCreateConsentArgs = {
  input: CreateConsentInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateContestArgs = {
  input: CreateContestInput;
};

export type MutationCreateEligibilityTestArgs = {
  input: CreateEligibilityTestInput;
};

export type MutationCreateEligibilityTestDateArgs = {
  input: CreateEligibilityTestDateInput;
};

export type MutationCreateEmergencyContactArgs = {
  input: CreateEmergencyContactInput;
};

export type MutationCreateFlowSchemaArgs = {
  input: CreateFlowSchemaInput;
};

export type MutationCreateHouseArgs = {
  input: CreateHouseInput;
};

export type MutationCreateInternationalApplicationArgs = {
  input: CreateInternationalApplicationInput;
};

export type MutationCreateIseeArgs = {
  input: CreateIseeInput;
};

export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};

export type MutationCreateLanguageProficiencyArgs = {
  input: CreateLanguageProficiencyInput;
};

export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};

export type MutationCreateLeadTypeArgs = {
  input: CreateLeadTypeInput;
};

export type MutationCreateLocalCenterArgs = {
  input: CreateLocalCenterInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};

export type MutationCreateProgramApplicationArgs = {
  input: CreateProgramApplicationInput;
};

export type MutationCreateProgramApplicationAnswerArgs = {
  input: CreateProgramApplicationAnswerInput;
};

export type MutationCreateProgramQuestionArgs = {
  input: CreateProgramQuestionInput;
};

export type MutationCreateRankingArgs = {
  input: CreateRankingInput;
};

export type MutationCreateRelationshipArgs = {
  input: CreateRelationshipInput;
};

export type MutationCreateRelationshipTypeArgs = {
  input: CreateRelationshipTypeInput;
};

export type MutationCreateReportArgs = {
  input: CreateReportInput;
};

export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};

export type MutationCreateScholarshipArgs = {
  input: CreateScholarshipInput;
};

export type MutationCreateScholarshipFeeArgs = {
  input: CreateScholarshipFeeInput;
};

export type MutationCreateScholarshipProgramArgs = {
  input: CreateScholarshipProgramInput;
};

export type MutationCreateScholarshipProgramRequestArgs = {
  input: CreateScholarshipProgramRequestInput;
};

export type MutationCreateScholarshipQuestionArgs = {
  input: CreateScholarshipQuestionInput;
};

export type MutationCreateScholarshipRequestArgs = {
  input: CreateScholarshipRequestInput;
};

export type MutationCreateScholarshipRequestAnswerArgs = {
  input: CreateScholarshipRequestAnswerInput;
};

export type MutationCreateScholarshipTypeArgs = {
  input: CreateScholarshipTypeInput;
};

export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};

export type MutationCreateSchoolReportArgs = {
  input: CreateSchoolReportInput;
};

export type MutationCreateSchoolReportItemArgs = {
  input: CreateSchoolReportItemInput;
};

export type MutationCreateSchoolReportSummaryArgs = {
  input: CreateSchoolReportSummaryInput;
};

export type MutationCreateScoreArgs = {
  input: CreateScoreInput;
};

export type MutationCreateSponsorArgs = {
  input: CreateSponsorInput;
};

export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateThreadArgs = {
  input: CreateThreadInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateViewArgs = {
  input: CreateViewInput;
};

export type MutationCreateWorkflowInfoArgs = {
  input: CreateWorkflowInfoInput;
};

export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};

export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};

export type MutationDeleteApplicationArgs = {
  input: DeleteApplicationInput;
};

export type MutationDeleteAreaArgs = {
  input: DeleteAreaInput;
};

export type MutationDeleteCannotLiveWithAnimalArgs = {
  input: DeleteCannotLiveWithAnimalInput;
};

export type MutationDeleteConsentArgs = {
  input: DeleteConsentInput;
};

export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};

export type MutationDeleteContestArgs = {
  input: DeleteContestInput;
};

export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};

export type MutationDeleteEligibilityTestArgs = {
  input: DeleteEligibilityTestInput;
};

export type MutationDeleteEligibilityTestDateArgs = {
  input: DeleteEligibilityTestDateInput;
};

export type MutationDeleteEmergencyContactArgs = {
  input: DeleteEmergencyContactInput;
};

export type MutationDeleteFlowSchemaArgs = {
  input: DeleteFlowSchemaInput;
};

export type MutationDeleteHouseArgs = {
  input: DeleteHouseInput;
};

export type MutationDeleteInternationalApplicationArgs = {
  input: DeleteInternationalApplicationInput;
};

export type MutationDeleteIseeArgs = {
  input: DeleteIseeInput;
};

export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput;
};

export type MutationDeleteLanguageProficiencyArgs = {
  input: DeleteLanguageProficiencyInput;
};

export type MutationDeleteLeadArgs = {
  input: DeleteLeadInput;
};

export type MutationDeleteLeadTypeArgs = {
  input: DeleteLeadTypeInput;
};

export type MutationDeleteLocalCenterArgs = {
  input: DeleteLocalCenterInput;
};

export type MutationDeleteProgramArgs = {
  input: DeleteProgramInput;
};

export type MutationDeleteProgramApplicationArgs = {
  input: DeleteProgramApplicationInput;
};

export type MutationDeleteProgramApplicationAnswerArgs = {
  input: DeleteProgramApplicationAnswerInput;
};

export type MutationDeleteProgramQuestionArgs = {
  input: DeleteProgramQuestionInput;
};

export type MutationDeleteRelationshipArgs = {
  input: DeleteRelationshipInput;
};

export type MutationDeleteRelationshipTypeArgs = {
  input: DeleteRelationshipTypeInput;
};

export type MutationDeleteReportArgs = {
  input: DeleteReportInput;
};

export type MutationDeleteRequestArgs = {
  input: DeleteRequestInput;
};

export type MutationDeleteScholarshipArgs = {
  input: DeleteScholarshipInput;
};

export type MutationDeleteScholarshipFeeArgs = {
  input: DeleteScholarshipFeeInput;
};

export type MutationDeleteScholarshipProgramArgs = {
  input: DeleteScholarshipProgramInput;
};

export type MutationDeleteScholarshipProgramRequestArgs = {
  input: DeleteScholarshipProgramRequestInput;
};

export type MutationDeleteScholarshipQuestionArgs = {
  input: DeleteScholarshipQuestionInput;
};

export type MutationDeleteScholarshipRequestArgs = {
  input: DeleteScholarshipRequestInput;
};

export type MutationDeleteScholarshipRequestAnswerArgs = {
  input: DeleteScholarshipRequestAnswerInput;
};

export type MutationDeleteScholarshipTypeArgs = {
  input: DeleteScholarshipTypeInput;
};

export type MutationDeleteSchoolArgs = {
  input: DeleteSchoolInput;
};

export type MutationDeleteSchoolReportArgs = {
  input: DeleteSchoolReportInput;
};

export type MutationDeleteSchoolReportItemArgs = {
  input: DeleteSchoolReportItemInput;
};

export type MutationDeleteSchoolReportSummaryArgs = {
  input: DeleteSchoolReportSummaryInput;
};

export type MutationDeleteScoreArgs = {
  input: DeleteScoreInput;
};

export type MutationDeleteSponsorArgs = {
  input: DeleteSponsorInput;
};

export type MutationDeleteStudentArgs = {
  input: DeleteStudentInput;
};

export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};

export type MutationDeleteThreadArgs = {
  input: DeleteThreadInput;
};

export type MutationDeleteViewArgs = {
  input: DeleteViewInput;
};

export type MutationDeleteWorkflowInfoArgs = {
  input: DeleteWorkflowInfoInput;
};

export type MutationSetHouseResidentsArgs = {
  input: SetHouseResidentsInput;
};

export type MutationTriggerWorkflowArgs = {
  input: TriggerWorkflowInput;
};

export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateAreaArgs = {
  input: UpdateAreaInput;
};

export type MutationUpdateCannotLiveWithAnimalArgs = {
  input: UpdateCannotLiveWithAnimalInput;
};

export type MutationUpdateConsentArgs = {
  input: UpdateConsentInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpdateContestArgs = {
  input: UpdateContestInput;
};

export type MutationUpdateEligibilityTestArgs = {
  input: UpdateEligibilityTestInput;
};

export type MutationUpdateEligibilityTestDateArgs = {
  input: UpdateEligibilityTestDateInput;
};

export type MutationUpdateEmergencyContactArgs = {
  input: UpdateEmergencyContactInput;
};

export type MutationUpdateFlowsArgs = {
  input: UpdateFlowsInput;
};

export type MutationUpdateHouseArgs = {
  input: UpdateHouseInput;
};

export type MutationUpdateInternationalApplicationArgs = {
  input: UpdateInternationalApplicationInput;
};

export type MutationUpdateIseeArgs = {
  input: UpdateIseeInput;
};

export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput;
};

export type MutationUpdateLanguageProficiencyArgs = {
  input: UpdateLanguageProficiencyInput;
};

export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};

export type MutationUpdateLeadTypeArgs = {
  input: UpdateLeadTypeInput;
};

export type MutationUpdateLocalCenterArgs = {
  input: UpdateLocalCenterInput;
};

export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};

export type MutationUpdateProgramArgs = {
  input: UpdateProgramInput;
};

export type MutationUpdateProgramApplicationArgs = {
  input: UpdateProgramApplicationInput;
};

export type MutationUpdateProgramApplicationAnswerArgs = {
  input: UpdateProgramApplicationAnswerInput;
};

export type MutationUpdateProgramQuestionArgs = {
  input: UpdateProgramQuestionInput;
};

export type MutationUpdateRankingArgs = {
  input: UpdateRankingInput;
};

export type MutationUpdateRelationshipTypeArgs = {
  input: UpdateRelationshipTypeInput;
};

export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};

export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};

export type MutationUpdateScholarshipArgs = {
  input: UpdateScholarshipInput;
};

export type MutationUpdateScholarshipFeeArgs = {
  input: UpdateScholarshipFeeInput;
};

export type MutationUpdateScholarshipProgramArgs = {
  input: UpdateScholarshipProgramInput;
};

export type MutationUpdateScholarshipProgramRequestArgs = {
  input: UpdateScholarshipProgramRequestInput;
};

export type MutationUpdateScholarshipQuestionArgs = {
  input: UpdateScholarshipQuestionInput;
};

export type MutationUpdateScholarshipRequestArgs = {
  input: UpdateScholarshipRequestInput;
};

export type MutationUpdateScholarshipRequestAnswerArgs = {
  input: UpdateScholarshipRequestAnswerInput;
};

export type MutationUpdateScholarshipTypeArgs = {
  input: UpdateScholarshipTypeInput;
};

export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};

export type MutationUpdateSchoolReportArgs = {
  input: UpdateSchoolReportInput;
};

export type MutationUpdateSchoolReportItemArgs = {
  input: UpdateSchoolReportItemInput;
};

export type MutationUpdateSchoolReportSummaryArgs = {
  input: UpdateSchoolReportSummaryInput;
};

export type MutationUpdateScoreArgs = {
  input: UpdateScoreInput;
};

export type MutationUpdateSponsorArgs = {
  input: UpdateSponsorInput;
};

export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateThreadArgs = {
  input: UpdateThreadInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateViewArgs = {
  input: UpdateViewInput;
};

export type MutationUpdateWorkflowInfoArgs = {
  input: UpdateWorkflowInfoInput;
};

export type MutationUploadDocumentArgs = {
  input: UploadDocumentInput;
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  message: Scalars['String']['output'];
};

export type NullableOfDietaryPreferenceOperationFilterInput = {
  eq?: InputMaybe<DietaryPreference>;
  in?: InputMaybe<Array<InputMaybe<DietaryPreference>>>;
  neq?: InputMaybe<DietaryPreference>;
  nin?: InputMaybe<Array<InputMaybe<DietaryPreference>>>;
};

export type NullableOfProgramApplicationStateOperationFilterInput = {
  eq?: InputMaybe<ProgramApplicationState>;
  in?: InputMaybe<Array<InputMaybe<ProgramApplicationState>>>;
  neq?: InputMaybe<ProgramApplicationState>;
  nin?: InputMaybe<Array<InputMaybe<ProgramApplicationState>>>;
};

export type NullableOfRankingStatusOperationFilterInput = {
  eq?: InputMaybe<RankingStatus>;
  in?: InputMaybe<Array<InputMaybe<RankingStatus>>>;
  neq?: InputMaybe<RankingStatus>;
  nin?: InputMaybe<Array<InputMaybe<RankingStatus>>>;
};

export type Program = {
  __typename?: 'Program';
  code: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  departureInfo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destinationIOC?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  endAt: Scalars['Date']['output'];
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isReciprocal?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  language?: Maybe<Scalars['String']['output']>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  questions: Array<ProgramQuestion>;
  returnInfo?: Maybe<Scalars['String']['output']>;
  scholarshipPrograms: Array<ScholarshipProgram>;
  search: Scalars['String']['output'];
  startAt: Scalars['Date']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type: ProgramType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ProgramEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramApplication = {
  __typename?: 'ProgramApplication';
  answers: Array<ProgramApplicationAnswer>;
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  doesNotRequireScholarship?: Maybe<Scalars['Boolean']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  originServiceId?: Maybe<Scalars['String']['output']>;
  program: Program;
  programId: Scalars['UUID']['output'];
  scholarshipProgramRequests: Array<ScholarshipProgramRequest>;
  state?: Maybe<ProgramApplicationState>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ProgramApplicationEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramApplicationAnswer = {
  __typename?: 'ProgramApplicationAnswer';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  programApplicationId: Scalars['UUID']['output'];
  question: ProgramQuestion;
  questionId: Scalars['UUID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ProgramApplicationAnswerEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramApplicationAnswerFilterInput = {
  and?: InputMaybe<Array<ProgramApplicationAnswerFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ProgramApplicationAnswerFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  programApplicationId?: InputMaybe<UuidOperationFilterInput>;
  question?: InputMaybe<ProgramQuestionFilterInput>;
  questionId?: InputMaybe<UuidOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ProgramApplicationAnswerSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  programApplicationId?: InputMaybe<SortEnumType>;
  question?: InputMaybe<ProgramQuestionSortInput>;
  questionId?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ProgramApplicationAnswersCollectionSegment = {
  __typename?: 'ProgramApplicationAnswersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ProgramApplicationAnswer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProgramApplicationFilterInput = {
  and?: InputMaybe<Array<ProgramApplicationFilterInput>>;
  answers?: InputMaybe<ListFilterInputTypeOfProgramApplicationAnswerFilterInput>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  doesNotRequireScholarship?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ProgramApplicationFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originServiceId?: InputMaybe<StringOperationFilterInput>;
  program?: InputMaybe<ProgramFilterInput>;
  programId?: InputMaybe<UuidOperationFilterInput>;
  scholarshipProgramRequests?: InputMaybe<ListFilterInputTypeOfScholarshipProgramRequestFilterInput>;
  state?: InputMaybe<NullableOfProgramApplicationStateOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ProgramApplicationSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  doesNotRequireScholarship?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originServiceId?: InputMaybe<SortEnumType>;
  program?: InputMaybe<ProgramSortInput>;
  programId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum ProgramApplicationState {
  Accepted = 'ACCEPTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Reserve = 'RESERVE',
}

/** A segment of a collection. */
export type ProgramApplicationsCollectionSegment = {
  __typename?: 'ProgramApplicationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ProgramApplication>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProgramFilterInput = {
  and?: InputMaybe<Array<ProgramFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  departureInfo?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  destinationIOC?: InputMaybe<StringOperationFilterInput>;
  duration?: InputMaybe<StringOperationFilterInput>;
  endAt?: InputMaybe<DateOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isReciprocal?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  language?: InputMaybe<StringOperationFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ProgramFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  questions?: InputMaybe<ListFilterInputTypeOfProgramQuestionFilterInput>;
  returnInfo?: InputMaybe<StringOperationFilterInput>;
  scholarshipPrograms?: InputMaybe<ListFilterInputTypeOfScholarshipProgramFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  startAt?: InputMaybe<DateOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<ProgramTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ProgramQuestion = {
  __typename?: 'ProgramQuestion';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  programId: Scalars['UUID']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ProgramQuestionEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramQuestionFilterInput = {
  and?: InputMaybe<Array<ProgramQuestionFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ProgramQuestionFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  programId?: InputMaybe<UuidOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ProgramQuestionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  programId?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ProgramQuestionsCollectionSegment = {
  __typename?: 'ProgramQuestionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ProgramQuestion>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProgramSortInput = {
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  departureInfo?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  destinationIOC?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  endAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isReciprocal?: InputMaybe<SortEnumType>;
  language?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  returnInfo?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  startAt?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum ProgramType {
  Linguistic = 'LINGUISTIC',
  Scholastic = 'SCHOLASTIC',
}

export type ProgramTypeOperationFilterInput = {
  eq?: InputMaybe<ProgramType>;
  in?: InputMaybe<Array<ProgramType>>;
  neq?: InputMaybe<ProgramType>;
  nin?: InputMaybe<Array<ProgramType>>;
};

/** A segment of a collection. */
export type ProgramsCollectionSegment = {
  __typename?: 'ProgramsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Program>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  answer?: Maybe<Answer>;
  answers?: Maybe<AnswersCollectionSegment>;
  apiKey?: Maybe<ApiKey>;
  apiKeys?: Maybe<ApiKeysCollectionSegment>;
  application?: Maybe<Application>;
  applications?: Maybe<ApplicationsCollectionSegment>;
  area?: Maybe<Area>;
  areas?: Maybe<AreasCollectionSegment>;
  cannotLiveWithAnimal?: Maybe<CannotLiveWithAnimal>;
  cannotLiveWithAnimals?: Maybe<CannotLiveWithAnimalsCollectionSegment>;
  consent?: Maybe<Consent>;
  consents?: Maybe<ConsentsCollectionSegment>;
  contact?: Maybe<Contact>;
  contactReferences: Array<ContactReference>;
  contacts?: Maybe<ContactsCollectionSegment>;
  contest?: Maybe<Contest>;
  contests?: Maybe<ContestsCollectionSegment>;
  dashboard: Dashboard;
  document?: Maybe<Document>;
  documents?: Maybe<DocumentsCollectionSegment>;
  eligibilityTest?: Maybe<EligibilityTest>;
  eligibilityTestDate?: Maybe<EligibilityTestDate>;
  eligibilityTestDates?: Maybe<EligibilityTestDatesCollectionSegment>;
  eligibilityTests?: Maybe<EligibilityTestsCollectionSegment>;
  event?: Maybe<Event>;
  events?: Maybe<EventsCollectionSegment>;
  flowSchema?: Maybe<FlowSchema>;
  flowSchemas?: Maybe<FlowSchemasCollectionSegment>;
  house?: Maybe<House>;
  houses?: Maybe<HousesCollectionSegment>;
  internationalApplication?: Maybe<InternationalApplication>;
  internationalApplications?: Maybe<InternationalApplicationsCollectionSegment>;
  isee?: Maybe<Isee>;
  isees?: Maybe<IseesCollectionSegment>;
  label?: Maybe<Label>;
  labels?: Maybe<LabelsCollectionSegment>;
  lead?: Maybe<Lead>;
  leadType?: Maybe<LeadType>;
  leadTypes?: Maybe<LeadTypesCollectionSegment>;
  leads?: Maybe<LeadsCollectionSegment>;
  localCenter?: Maybe<LocalCenter>;
  localCenters?: Maybe<LocalCentersCollectionSegment>;
  mailingLists: MailingLists;
  me?: Maybe<User>;
  message?: Maybe<Message>;
  messages?: Maybe<MessagesCollectionSegment>;
  program?: Maybe<Program>;
  programApplication?: Maybe<ProgramApplication>;
  programApplicationAnswer?: Maybe<ProgramApplicationAnswer>;
  programApplicationAnswers?: Maybe<ProgramApplicationAnswersCollectionSegment>;
  programApplications?: Maybe<ProgramApplicationsCollectionSegment>;
  programQuestion?: Maybe<ProgramQuestion>;
  programQuestions?: Maybe<ProgramQuestionsCollectionSegment>;
  programs?: Maybe<ProgramsCollectionSegment>;
  ranking?: Maybe<Ranking>;
  relationship?: Maybe<Relationship>;
  relationshipType?: Maybe<RelationshipType>;
  relationshipTypes?: Maybe<RelationshipTypesCollectionSegment>;
  relationships?: Maybe<RelationshipsCollectionSegment>;
  report?: Maybe<Report>;
  reports?: Maybe<ReportsCollectionSegment>;
  request?: Maybe<Request>;
  requests?: Maybe<RequestsCollectionSegment>;
  scholarship?: Maybe<Scholarship>;
  scholarshipFee?: Maybe<ScholarshipFee>;
  scholarshipFees?: Maybe<ScholarshipFeesCollectionSegment>;
  scholarshipProgram?: Maybe<ScholarshipProgram>;
  scholarshipProgramRequest?: Maybe<ScholarshipProgramRequest>;
  scholarshipProgramRequests?: Maybe<ScholarshipProgramRequestsCollectionSegment>;
  scholarshipPrograms?: Maybe<ScholarshipProgramsCollectionSegment>;
  scholarshipQuestion?: Maybe<ScholarshipQuestion>;
  scholarshipQuestions?: Maybe<ScholarshipQuestionsCollectionSegment>;
  scholarshipRequest?: Maybe<ScholarshipRequest>;
  scholarshipRequestAnswer?: Maybe<ScholarshipRequestAnswer>;
  scholarshipRequestAnswers?: Maybe<ScholarshipRequestAnswersCollectionSegment>;
  scholarshipRequests?: Maybe<ScholarshipRequestsCollectionSegment>;
  scholarshipType?: Maybe<ScholarshipType>;
  scholarshipTypes?: Maybe<ScholarshipTypesCollectionSegment>;
  scholarships?: Maybe<ScholarshipsCollectionSegment>;
  school?: Maybe<School>;
  schoolReport?: Maybe<SchoolReport>;
  schoolReportSummary?: Maybe<SchoolReportSummary>;
  schoolReportSummarys?: Maybe<SchoolReportSummarysCollectionSegment>;
  schoolReports?: Maybe<SchoolReportsCollectionSegment>;
  schools?: Maybe<SchoolsCollectionSegment>;
  score?: Maybe<Score>;
  scores?: Maybe<ScoresCollectionSegment>;
  sponsor?: Maybe<Sponsor>;
  sponsors?: Maybe<SponsorsCollectionSegment>;
  student?: Maybe<Student>;
  students?: Maybe<StudentsCollectionSegment>;
  task?: Maybe<Task>;
  tasks?: Maybe<TasksCollectionSegment>;
  thread?: Maybe<Thread>;
  threads?: Maybe<ThreadsCollectionSegment>;
  user?: Maybe<User>;
  users?: Maybe<UsersCollectionSegment>;
  view?: Maybe<View>;
  views?: Maybe<ViewsCollectionSegment>;
  workflowInfo?: Maybe<WorkflowInfo>;
  workflowInfos?: Maybe<WorkflowInfosCollectionSegment>;
};

export type QueryAnswerArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAnswersArgs = {
  order?: InputMaybe<Array<AnswerSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AnswerFilterInput>;
};

export type QueryApiKeyArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryApiKeysArgs = {
  order?: InputMaybe<Array<ApiKeySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApiKeyFilterInput>;
};

export type QueryApplicationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryApplicationsArgs = {
  order?: InputMaybe<Array<ApplicationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationFilterInput>;
};

export type QueryAreaArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryAreasArgs = {
  order?: InputMaybe<Array<AreaSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AreaFilterInput>;
};

export type QueryCannotLiveWithAnimalArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCannotLiveWithAnimalsArgs = {
  order?: InputMaybe<Array<CannotLiveWithAnimalSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CannotLiveWithAnimalFilterInput>;
};

export type QueryConsentArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryConsentsArgs = {
  order?: InputMaybe<Array<ConsentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConsentFilterInput>;
};

export type QueryContactArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryContactReferencesArgs = {
  contactId: Scalars['UUID']['input'];
};

export type QueryContactsArgs = {
  order?: InputMaybe<Array<ContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactFilterInput>;
};

export type QueryContestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryContestsArgs = {
  order?: InputMaybe<Array<ContestSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContestFilterInput>;
};

export type QueryDashboardArgs = {
  filters?: InputMaybe<DashboardFiltersInput>;
};

export type QueryDocumentArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryDocumentsArgs = {
  order?: InputMaybe<Array<DocumentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentFilterInput>;
};

export type QueryEligibilityTestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryEligibilityTestDateArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryEligibilityTestDatesArgs = {
  order?: InputMaybe<Array<EligibilityTestDateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EligibilityTestDateFilterInput>;
};

export type QueryEligibilityTestsArgs = {
  order?: InputMaybe<Array<EligibilityTestSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EligibilityTestFilterInput>;
};

export type QueryEventArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventFilterInput>;
};

export type QueryFlowSchemaArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryFlowSchemasArgs = {
  order?: InputMaybe<Array<FlowSchemaSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FlowSchemaFilterInput>;
};

export type QueryHouseArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryHousesArgs = {
  order?: InputMaybe<Array<HouseSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HouseFilterInput>;
};

export type QueryInternationalApplicationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryInternationalApplicationsArgs = {
  order?: InputMaybe<Array<InternationalApplicationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InternationalApplicationFilterInput>;
};

export type QueryIseeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryIseesArgs = {
  order?: InputMaybe<Array<IseeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IseeFilterInput>;
};

export type QueryLabelArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryLabelsArgs = {
  order?: InputMaybe<Array<LabelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LabelFilterInput>;
};

export type QueryLeadArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryLeadTypeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryLeadTypesArgs = {
  order?: InputMaybe<Array<LeadTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LeadTypeFilterInput>;
};

export type QueryLeadsArgs = {
  order?: InputMaybe<Array<LeadSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LeadFilterInput>;
};

export type QueryLocalCenterArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryLocalCentersArgs = {
  order?: InputMaybe<Array<LocalCenterSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocalCenterFilterInput>;
};

export type QueryMessageArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryMessagesArgs = {
  order?: InputMaybe<Array<MessageSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageFilterInput>;
};

export type QueryProgramArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProgramApplicationArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProgramApplicationAnswerArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProgramApplicationAnswersArgs = {
  order?: InputMaybe<Array<ProgramApplicationAnswerSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramApplicationAnswerFilterInput>;
};

export type QueryProgramApplicationsArgs = {
  order?: InputMaybe<Array<ProgramApplicationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramApplicationFilterInput>;
};

export type QueryProgramQuestionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryProgramQuestionsArgs = {
  order?: InputMaybe<Array<ProgramQuestionSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramQuestionFilterInput>;
};

export type QueryProgramsArgs = {
  order?: InputMaybe<Array<ProgramSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramFilterInput>;
};

export type QueryRankingArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryRelationshipArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryRelationshipTypeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryRelationshipTypesArgs = {
  order?: InputMaybe<Array<RelationshipTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RelationshipTypeFilterInput>;
};

export type QueryRelationshipsArgs = {
  order?: InputMaybe<Array<RelationshipSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RelationshipFilterInput>;
};

export type QueryReportArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryReportsArgs = {
  order?: InputMaybe<Array<ReportSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportFilterInput>;
};

export type QueryRequestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryRequestsArgs = {
  order?: InputMaybe<Array<RequestSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RequestFilterInput>;
};

export type QueryScholarshipArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipFeeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipFeesArgs = {
  order?: InputMaybe<Array<ScholarshipFeeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipFeeFilterInput>;
};

export type QueryScholarshipProgramArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipProgramRequestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipProgramRequestsArgs = {
  order?: InputMaybe<Array<ScholarshipProgramRequestSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipProgramRequestFilterInput>;
};

export type QueryScholarshipProgramsArgs = {
  order?: InputMaybe<Array<ScholarshipProgramSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipProgramFilterInput>;
};

export type QueryScholarshipQuestionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipQuestionsArgs = {
  order?: InputMaybe<Array<ScholarshipQuestionSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipQuestionFilterInput>;
};

export type QueryScholarshipRequestArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipRequestAnswerArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipRequestAnswersArgs = {
  order?: InputMaybe<Array<ScholarshipRequestAnswerSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipRequestAnswerFilterInput>;
};

export type QueryScholarshipRequestsArgs = {
  order?: InputMaybe<Array<ScholarshipRequestSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipRequestFilterInput>;
};

export type QueryScholarshipTypeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScholarshipTypesArgs = {
  order?: InputMaybe<Array<ScholarshipTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipTypeFilterInput>;
};

export type QueryScholarshipsArgs = {
  order?: InputMaybe<Array<ScholarshipSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScholarshipFilterInput>;
};

export type QuerySchoolArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySchoolReportArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySchoolReportSummaryArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySchoolReportSummarysArgs = {
  order?: InputMaybe<Array<SchoolReportSummarySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolReportSummaryFilterInput>;
};

export type QuerySchoolReportsArgs = {
  order?: InputMaybe<Array<SchoolReportSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolReportFilterInput>;
};

export type QuerySchoolsArgs = {
  order?: InputMaybe<Array<SchoolSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolFilterInput>;
};

export type QueryScoreArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryScoresArgs = {
  order?: InputMaybe<Array<ScoreSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreFilterInput>;
};

export type QuerySponsorArgs = {
  id: Scalars['UUID']['input'];
};

export type QuerySponsorsArgs = {
  order?: InputMaybe<Array<SponsorSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SponsorFilterInput>;
};

export type QueryStudentArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryStudentsArgs = {
  order?: InputMaybe<Array<StudentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentFilterInput>;
};

export type QueryTaskArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryTasksArgs = {
  order?: InputMaybe<Array<TaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskFilterInput>;
};

export type QueryThreadArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryThreadsArgs = {
  order?: InputMaybe<Array<ThreadSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ThreadFilterInput>;
};

export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserFilterInput>;
};

export type QueryViewArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryViewsArgs = {
  order?: InputMaybe<Array<ViewSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ViewFilterInput>;
};

export type QueryWorkflowInfoArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryWorkflowInfosArgs = {
  order?: InputMaybe<Array<WorkflowInfoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkflowInfoFilterInput>;
};

export type Ranking = {
  __typename?: 'Ranking';
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  position: Scalars['Int']['output'];
  sponsorId: Scalars['UUID']['output'];
  status?: Maybe<RankingStatus>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type RankingEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RankingFilterInput = {
  and?: InputMaybe<Array<RankingFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RankingFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  position?: InputMaybe<IntOperationFilterInput>;
  sponsorId?: InputMaybe<UuidOperationFilterInput>;
  status?: InputMaybe<NullableOfRankingStatusOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type RankingSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isConfirmed?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  sponsorId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum RankingStatus {
  Loser = 'LOSER',
  Pending = 'PENDING',
  Winner = 'WINNER',
}

export type Relationship = {
  __typename?: 'Relationship';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  objectContactId: Scalars['UUID']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  relationshipType: RelationshipType;
  subjectContactId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type RelationshipEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RelationshipFilterInput = {
  and?: InputMaybe<Array<RelationshipFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  objectContactId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RelationshipFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  relationshipType?: InputMaybe<RelationshipTypeFilterInput>;
  subjectContactId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type RelationshipReference = {
  __typename?: 'RelationshipReference';
  relationshipTypeId: Scalars['UUID']['output'];
  targetId: Scalars['UUID']['output'];
};

export type RelationshipSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  objectContactId?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  relationshipType?: InputMaybe<RelationshipTypeSortInput>;
  subjectContactId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export type RelationshipType = {
  __typename?: 'RelationshipType';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  /** E.g. "Parent" in a parent-child relationship. */
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  /** E.g. Child relationship type in a parent-child relationship. */
  otherSideRelationshipTypeId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type RelationshipTypeEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RelationshipTypeFilterInput = {
  and?: InputMaybe<Array<RelationshipTypeFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  /** E.g. "Parent" in a parent-child relationship. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RelationshipTypeFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  /** E.g. Child relationship type in a parent-child relationship. */
  otherSideRelationshipTypeId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type RelationshipTypeSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  /** E.g. "Parent" in a parent-child relationship. */
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  /** E.g. Child relationship type in a parent-child relationship. */
  otherSideRelationshipTypeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RelationshipTypesCollectionSegment = {
  __typename?: 'RelationshipTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<RelationshipType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type RelationshipsCollectionSegment = {
  __typename?: 'RelationshipsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Relationship>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Report = {
  __typename?: 'Report';
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  reportText?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ReportEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportFilterInput = {
  and?: InputMaybe<Array<ReportFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ReportFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  reportText?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ReportSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  reportText?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ReportsCollectionSegment = {
  __typename?: 'ReportsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Report>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Request = {
  __typename?: 'Request';
  contact: Contact;
  contactId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deadline?: Maybe<Scalars['Instant']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  items?: Maybe<Array<RequestItem>>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type RequestEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestFilterInput = {
  and?: InputMaybe<Array<RequestFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  deadline?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isAnswered?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<RequestFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type RequestItem = {
  __typename?: 'RequestItem';
  data?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: RequestItemType;
};

export type RequestItemInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: RequestItemType;
};

export enum RequestItemType {
  DocumentUpload = 'DOCUMENT_UPLOAD',
  Text = 'TEXT',
  TextInput = 'TEXT_INPUT',
  Title = 'TITLE',
}

export type RequestSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  deadline?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAnswered?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RequestsCollectionSegment = {
  __typename?: 'RequestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SankeyItem = {
  __typename?: 'SankeyItem';
  count: Scalars['Int']['output'];
  exitedCount: Array<KeyValuePairOfStringAndInt32>;
  flowName: Scalars['String']['output'];
};

export type Scholarship = {
  __typename?: 'Scholarship';
  closesAtDate: Scalars['Date']['output'];
  closesAtTime: Scalars['TimeSpan']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  doesRequireIsee: Scalars['Boolean']['output'];
  doesRequireOAPayment: Scalars['Boolean']['output'];
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  questions: Array<ScholarshipQuestion>;
  sponsor: Sponsor;
  sponsorId: Scalars['UUID']['output'];
  type: ScholarshipType;
  typeId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipFee = {
  __typename?: 'ScholarshipFee';
  amount?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  scholarshipId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipFeeEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipFeeFilterInput = {
  amount?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ScholarshipFeeFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipFeeFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  scholarshipId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipFeeSortInput = {
  amount?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  scholarshipId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipFeesCollectionSegment = {
  __typename?: 'ScholarshipFeesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipFee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipFilterInput = {
  and?: InputMaybe<Array<ScholarshipFilterInput>>;
  closesAtDate?: InputMaybe<DateOperationFilterInput>;
  closesAtTime?: InputMaybe<TimeSpanOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  doesRequireIsee?: InputMaybe<BooleanOperationFilterInput>;
  doesRequireOAPayment?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  questions?: InputMaybe<ListFilterInputTypeOfScholarshipQuestionFilterInput>;
  sponsor?: InputMaybe<SponsorFilterInput>;
  sponsorId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<ScholarshipTypeFilterInput>;
  typeId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipProgram = {
  __typename?: 'ScholarshipProgram';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  fees: Array<ScholarshipFee>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  programId: Scalars['UUID']['output'];
  questions: Array<ScholarshipQuestion>;
  scholarshipId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipProgramEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipProgramFilterInput = {
  and?: InputMaybe<Array<ScholarshipProgramFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  fees?: InputMaybe<ListFilterInputTypeOfScholarshipFeeFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipProgramFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  programId?: InputMaybe<UuidOperationFilterInput>;
  questions?: InputMaybe<ListFilterInputTypeOfScholarshipQuestionFilterInput>;
  scholarshipId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipProgramRequest = {
  __typename?: 'ScholarshipProgramRequest';
  coverage: Scalars['Decimal']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  programApplicationId: Scalars['UUID']['output'];
  scholarshipRequest: ScholarshipRequest;
  scholarshipRequestId: Scalars['UUID']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipProgramRequestEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipProgramRequestFilterInput = {
  and?: InputMaybe<Array<ScholarshipProgramRequestFilterInput>>;
  coverage?: InputMaybe<DecimalOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipProgramRequestFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  programApplicationId?: InputMaybe<UuidOperationFilterInput>;
  scholarshipRequest?: InputMaybe<ScholarshipRequestFilterInput>;
  scholarshipRequestId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipProgramRequestSortInput = {
  coverage?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  programApplicationId?: InputMaybe<SortEnumType>;
  scholarshipRequest?: InputMaybe<ScholarshipRequestSortInput>;
  scholarshipRequestId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipProgramRequestsCollectionSegment = {
  __typename?: 'ScholarshipProgramRequestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipProgramRequest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipProgramSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  programId?: InputMaybe<SortEnumType>;
  scholarshipId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipProgramsCollectionSegment = {
  __typename?: 'ScholarshipProgramsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipProgram>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipQuestion = {
  __typename?: 'ScholarshipQuestion';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  scholarshipId: Scalars['UUID']['output'];
  sorting: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipQuestionEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipQuestionFilterInput = {
  and?: InputMaybe<Array<ScholarshipQuestionFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipQuestionFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  scholarshipId?: InputMaybe<UuidOperationFilterInput>;
  sorting?: InputMaybe<IntOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipQuestionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  scholarshipId?: InputMaybe<SortEnumType>;
  sorting?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipQuestionsCollectionSegment = {
  __typename?: 'ScholarshipQuestionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipQuestion>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipRequest = {
  __typename?: 'ScholarshipRequest';
  answers: Array<ScholarshipRequestAnswer>;
  applicationId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  scholarship: Scholarship;
  scholarshipId: Scalars['UUID']['output'];
  state: ScholarshipRequestState;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipRequestEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipRequestAnswer = {
  __typename?: 'ScholarshipRequestAnswer';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  question: ScholarshipQuestion;
  questionId: Scalars['UUID']['output'];
  scholarshipRequestId: Scalars['UUID']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipRequestAnswerEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipRequestAnswerFilterInput = {
  and?: InputMaybe<Array<ScholarshipRequestAnswerFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipRequestAnswerFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  question?: InputMaybe<ScholarshipQuestionFilterInput>;
  questionId?: InputMaybe<UuidOperationFilterInput>;
  scholarshipRequestId?: InputMaybe<UuidOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipRequestAnswerSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  question?: InputMaybe<ScholarshipQuestionSortInput>;
  questionId?: InputMaybe<SortEnumType>;
  scholarshipRequestId?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipRequestAnswersCollectionSegment = {
  __typename?: 'ScholarshipRequestAnswersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipRequestAnswer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipRequestFilterInput = {
  and?: InputMaybe<Array<ScholarshipRequestFilterInput>>;
  answers?: InputMaybe<ListFilterInputTypeOfScholarshipRequestAnswerFilterInput>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipRequestFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  scholarship?: InputMaybe<ScholarshipFilterInput>;
  scholarshipId?: InputMaybe<UuidOperationFilterInput>;
  state?: InputMaybe<ScholarshipRequestStateOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipRequestSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  scholarship?: InputMaybe<ScholarshipSortInput>;
  scholarshipId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum ScholarshipRequestState {
  Other = 'OTHER',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Won = 'WON',
}

export type ScholarshipRequestStateOperationFilterInput = {
  eq?: InputMaybe<ScholarshipRequestState>;
  in?: InputMaybe<Array<ScholarshipRequestState>>;
  neq?: InputMaybe<ScholarshipRequestState>;
  nin?: InputMaybe<Array<ScholarshipRequestState>>;
};

/** A segment of a collection. */
export type ScholarshipRequestsCollectionSegment = {
  __typename?: 'ScholarshipRequestsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipRequest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScholarshipSortInput = {
  closesAtDate?: InputMaybe<SortEnumType>;
  closesAtTime?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  doesRequireIsee?: InputMaybe<SortEnumType>;
  doesRequireOAPayment?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  sponsor?: InputMaybe<SponsorSortInput>;
  sponsorId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<ScholarshipTypeSortInput>;
  typeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export type ScholarshipType = {
  __typename?: 'ScholarshipType';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScholarshipTypeEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScholarshipTypeFilterInput = {
  and?: InputMaybe<Array<ScholarshipTypeFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScholarshipTypeFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScholarshipTypeSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScholarshipTypesCollectionSegment = {
  __typename?: 'ScholarshipTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScholarshipType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type ScholarshipsCollectionSegment = {
  __typename?: 'ScholarshipsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Scholarship>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type School = {
  __typename?: 'School';
  address?: Maybe<Address>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isVerified: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  localCenter?: Maybe<LocalCenter>;
  localCenterId?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  originSchoolId?: Maybe<Scalars['UUID']['output']>;
  search: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type SchoolEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SchoolFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<SchoolFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  localCenter?: InputMaybe<LocalCenterFilterInput>;
  localCenterId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SchoolFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originSchoolId?: InputMaybe<UuidOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type SchoolReport = {
  __typename?: 'SchoolReport';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  items: Array<SchoolReportItem>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars['UUID']['output']>;
  studentId: Scalars['UUID']['output'];
  type: AcademicResultsType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  year: Scalars['String']['output'];
};

export type SchoolReportEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SchoolReportFilterInput = {
  and?: InputMaybe<Array<SchoolReportFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfSchoolReportItemFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<SchoolReportFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  studentId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<AcademicResultsTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  year?: InputMaybe<StringOperationFilterInput>;
};

export type SchoolReportItem = {
  __typename?: 'SchoolReportItem';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  grade?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  schoolReportId: Scalars['UUID']['output'];
  subject: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type SchoolReportItemEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SchoolReportItemFilterInput = {
  and?: InputMaybe<Array<SchoolReportItemFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  grade?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<SchoolReportItemFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  schoolReportId?: InputMaybe<UuidOperationFilterInput>;
  subject?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type SchoolReportSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  schoolId?: InputMaybe<SortEnumType>;
  studentId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type SchoolReportSummary = {
  __typename?: 'SchoolReportSummary';
  averageGrade?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars['UUID']['output']>;
  studentId: Scalars['UUID']['output'];
  type: SchoolReportType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  year: Scalars['String']['output'];
};

export type SchoolReportSummaryEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SchoolReportSummaryFilterInput = {
  and?: InputMaybe<Array<SchoolReportSummaryFilterInput>>;
  averageGrade?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<SchoolReportSummaryFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  studentId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<SchoolReportTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  year?: InputMaybe<StringOperationFilterInput>;
};

export type SchoolReportSummarySortInput = {
  averageGrade?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  schoolId?: InputMaybe<SortEnumType>;
  studentId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SchoolReportSummarysCollectionSegment = {
  __typename?: 'SchoolReportSummarysCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SchoolReportSummary>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum SchoolReportType {
  Failed = 'FAILED',
  HighSchool = 'HIGH_SCHOOL',
  MiddleSchoolExamPassed = 'MIDDLE_SCHOOL_EXAM_PASSED',
}

export type SchoolReportTypeOperationFilterInput = {
  eq?: InputMaybe<SchoolReportType>;
  in?: InputMaybe<Array<SchoolReportType>>;
  neq?: InputMaybe<SchoolReportType>;
  nin?: InputMaybe<Array<SchoolReportType>>;
};

/** A segment of a collection. */
export type SchoolReportsCollectionSegment = {
  __typename?: 'SchoolReportsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SchoolReport>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SchoolSortInput = {
  address?: InputMaybe<AddressSortInput>;
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isVerified?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  localCenter?: InputMaybe<LocalCenterSortInput>;
  localCenterId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originSchoolId?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SchoolsCollectionSegment = {
  __typename?: 'SchoolsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<School>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Score = {
  __typename?: 'Score';
  applicationId: Scalars['UUID']['output'];
  committeeScore?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  giuntiTestScore?: Maybe<Scalars['Float']['output']>;
  hospitalityScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  localCenterScore?: Maybe<Scalars['Float']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  preselectionTotal?: Maybe<Scalars['Float']['output']>;
  scholasticScore?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ScoreEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ScoreFilterInput = {
  and?: InputMaybe<Array<ScoreFilterInput>>;
  applicationId?: InputMaybe<UuidOperationFilterInput>;
  committeeScore?: InputMaybe<FloatOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  giuntiTestScore?: InputMaybe<FloatOperationFilterInput>;
  hospitalityScore?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  localCenterScore?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<ScoreFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  preselectionTotal?: InputMaybe<FloatOperationFilterInput>;
  scholasticScore?: InputMaybe<FloatOperationFilterInput>;
  total?: InputMaybe<FloatOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ScoreSortInput = {
  applicationId?: InputMaybe<SortEnumType>;
  committeeScore?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  giuntiTestScore?: InputMaybe<SortEnumType>;
  hospitalityScore?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  localCenterScore?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  preselectionTotal?: InputMaybe<SortEnumType>;
  scholasticScore?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScoresCollectionSegment = {
  __typename?: 'ScoresCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Score>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SetHouseResidentsError = NotFoundError | UnauthorizedError;

export type SetHouseResidentsInput = {
  contactIds: Array<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};

export type SetHouseResidentsPayload = {
  __typename?: 'SetHouseResidentsPayload';
  errors?: Maybe<Array<SetHouseResidentsError>>;
  house?: Maybe<House>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Sponsor = {
  __typename?: 'Sponsor';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  search: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type SponsorEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SponsorFilterInput = {
  and?: InputMaybe<Array<SponsorFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SponsorFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type SponsorSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SponsorsCollectionSegment = {
  __typename?: 'SponsorsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Sponsor>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Student = {
  __typename?: 'Student';
  application?: Maybe<Application>;
  applications: Array<Application>;
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  contact: Contact;
  contactId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  doesRequireReligionServicesAccess?: Maybe<Scalars['Boolean']['output']>;
  emergencyContacts: Array<EmergencyContact>;
  events?: Maybe<EventsCollectionSegment>;
  expectationsDescription?: Maybe<Scalars['String']['output']>;
  externalLink?: Maybe<Scalars['URL']['output']>;
  familyDescription?: Maybe<Scalars['String']['output']>;
  growthDescription?: Maybe<Scalars['String']['output']>;
  healthDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isFamilyWillingToHost?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  localCenter?: Maybe<LocalCenter>;
  localCenterId?: Maybe<Scalars['UUID']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  originalSchool?: Maybe<School>;
  originalSchoolId?: Maybe<Scalars['UUID']['output']>;
  personalityDescription?: Maybe<Scalars['String']['output']>;
  religionAttendanceFrequency?: Maybe<Scalars['String']['output']>;
  religionDescription?: Maybe<Scalars['String']['output']>;
  school?: Maybe<School>;
  schoolDescription?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['UUID']['output']>;
  schoolReportSummaries: Array<SchoolReportSummary>;
  schoolReports: Array<SchoolReport>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  willNotPartecipateToNarritornoReason?: Maybe<Scalars['String']['output']>;
  willNotPartecipateToOrientationReason?: Maybe<Scalars['String']['output']>;
  willPartecipateToNarritorno?: Maybe<Scalars['Boolean']['output']>;
  willPartecipateToOrientation?: Maybe<Scalars['Boolean']['output']>;
};

export type StudentEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentBase = {
  __typename?: 'StudentBase';
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  contact: Contact;
  contactId: Scalars['UUID']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  documents: Array<Document>;
  doesRequireReligionServicesAccess?: Maybe<Scalars['Boolean']['output']>;
  emergencyContacts: Array<EmergencyContact>;
  events?: Maybe<EventsCollectionSegment>;
  expectationsDescription?: Maybe<Scalars['String']['output']>;
  externalLink?: Maybe<Scalars['URL']['output']>;
  familyDescription?: Maybe<Scalars['String']['output']>;
  growthDescription?: Maybe<Scalars['String']['output']>;
  healthDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isFamilyWillingToHost?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  localCenter?: Maybe<LocalCenter>;
  localCenterId?: Maybe<Scalars['UUID']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  originalSchool?: Maybe<School>;
  originalSchoolId?: Maybe<Scalars['UUID']['output']>;
  personalityDescription?: Maybe<Scalars['String']['output']>;
  religionAttendanceFrequency?: Maybe<Scalars['String']['output']>;
  religionDescription?: Maybe<Scalars['String']['output']>;
  school?: Maybe<School>;
  schoolDescription?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['UUID']['output']>;
  schoolReportSummaries: Array<SchoolReportSummary>;
  schoolReports: Array<SchoolReport>;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  willNotPartecipateToNarritornoReason?: Maybe<Scalars['String']['output']>;
  willNotPartecipateToOrientationReason?: Maybe<Scalars['String']['output']>;
  willPartecipateToNarritorno?: Maybe<Scalars['Boolean']['output']>;
  willPartecipateToOrientation?: Maybe<Scalars['Boolean']['output']>;
};

export type StudentBaseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentBaseFilterInput = {
  and?: InputMaybe<Array<StudentBaseFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  doesRequireReligionServicesAccess?: InputMaybe<BooleanOperationFilterInput>;
  emergencyContacts?: InputMaybe<ListFilterInputTypeOfEmergencyContactFilterInput>;
  expectationsDescription?: InputMaybe<StringOperationFilterInput>;
  externalLink?: InputMaybe<UrlOperationFilterInput>;
  familyDescription?: InputMaybe<StringOperationFilterInput>;
  growthDescription?: InputMaybe<StringOperationFilterInput>;
  healthDescription?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isFamilyWillingToHost?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  localCenter?: InputMaybe<LocalCenterFilterInput>;
  localCenterId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<StudentBaseFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originalSchool?: InputMaybe<SchoolFilterInput>;
  originalSchoolId?: InputMaybe<UuidOperationFilterInput>;
  personalityDescription?: InputMaybe<StringOperationFilterInput>;
  religionAttendanceFrequency?: InputMaybe<StringOperationFilterInput>;
  religionDescription?: InputMaybe<StringOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  schoolDescription?: InputMaybe<StringOperationFilterInput>;
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  schoolReportSummaries?: InputMaybe<ListFilterInputTypeOfSchoolReportSummaryFilterInput>;
  schoolReports?: InputMaybe<ListFilterInputTypeOfSchoolReportFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  willNotPartecipateToNarritornoReason?: InputMaybe<StringOperationFilterInput>;
  willNotPartecipateToOrientationReason?: InputMaybe<StringOperationFilterInput>;
  willPartecipateToNarritorno?: InputMaybe<BooleanOperationFilterInput>;
  willPartecipateToOrientation?: InputMaybe<BooleanOperationFilterInput>;
};

export type StudentBaseSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  doesRequireReligionServicesAccess?: InputMaybe<SortEnumType>;
  expectationsDescription?: InputMaybe<SortEnumType>;
  externalLink?: InputMaybe<UriSortInput>;
  familyDescription?: InputMaybe<SortEnumType>;
  growthDescription?: InputMaybe<SortEnumType>;
  healthDescription?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isFamilyWillingToHost?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  localCenter?: InputMaybe<LocalCenterSortInput>;
  localCenterId?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originalSchool?: InputMaybe<SchoolSortInput>;
  originalSchoolId?: InputMaybe<SortEnumType>;
  personalityDescription?: InputMaybe<SortEnumType>;
  religionAttendanceFrequency?: InputMaybe<SortEnumType>;
  religionDescription?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  schoolDescription?: InputMaybe<SortEnumType>;
  schoolId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  willNotPartecipateToNarritornoReason?: InputMaybe<SortEnumType>;
  willNotPartecipateToOrientationReason?: InputMaybe<SortEnumType>;
  willPartecipateToNarritorno?: InputMaybe<SortEnumType>;
  willPartecipateToOrientation?: InputMaybe<SortEnumType>;
};

export type StudentFilterInput = {
  and?: InputMaybe<Array<StudentFilterInput>>;
  applications?: InputMaybe<ListFilterInputTypeOfApplicationFilterInput>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  doesRequireReligionServicesAccess?: InputMaybe<BooleanOperationFilterInput>;
  emergencyContacts?: InputMaybe<ListFilterInputTypeOfEmergencyContactFilterInput>;
  expectationsDescription?: InputMaybe<StringOperationFilterInput>;
  externalLink?: InputMaybe<UrlOperationFilterInput>;
  familyDescription?: InputMaybe<StringOperationFilterInput>;
  growthDescription?: InputMaybe<StringOperationFilterInput>;
  healthDescription?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isFamilyWillingToHost?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  localCenter?: InputMaybe<LocalCenterFilterInput>;
  localCenterId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<StudentFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  originalSchool?: InputMaybe<SchoolFilterInput>;
  originalSchoolId?: InputMaybe<UuidOperationFilterInput>;
  personalityDescription?: InputMaybe<StringOperationFilterInput>;
  religionAttendanceFrequency?: InputMaybe<StringOperationFilterInput>;
  religionDescription?: InputMaybe<StringOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  schoolDescription?: InputMaybe<StringOperationFilterInput>;
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  schoolReportSummaries?: InputMaybe<ListFilterInputTypeOfSchoolReportSummaryFilterInput>;
  schoolReports?: InputMaybe<ListFilterInputTypeOfSchoolReportFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  willNotPartecipateToNarritornoReason?: InputMaybe<StringOperationFilterInput>;
  willNotPartecipateToOrientationReason?: InputMaybe<StringOperationFilterInput>;
  willPartecipateToNarritorno?: InputMaybe<BooleanOperationFilterInput>;
  willPartecipateToOrientation?: InputMaybe<BooleanOperationFilterInput>;
};

export type StudentReference = {
  __typename?: 'StudentReference';
  studentId: Scalars['UUID']['output'];
};

export type StudentSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  doesRequireReligionServicesAccess?: InputMaybe<SortEnumType>;
  expectationsDescription?: InputMaybe<SortEnumType>;
  externalLink?: InputMaybe<UriSortInput>;
  familyDescription?: InputMaybe<SortEnumType>;
  growthDescription?: InputMaybe<SortEnumType>;
  healthDescription?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isFamilyWillingToHost?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  localCenter?: InputMaybe<LocalCenterSortInput>;
  localCenterId?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  originalSchool?: InputMaybe<SchoolSortInput>;
  originalSchoolId?: InputMaybe<SortEnumType>;
  personalityDescription?: InputMaybe<SortEnumType>;
  religionAttendanceFrequency?: InputMaybe<SortEnumType>;
  religionDescription?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  schoolDescription?: InputMaybe<SortEnumType>;
  schoolId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  willNotPartecipateToNarritornoReason?: InputMaybe<SortEnumType>;
  willNotPartecipateToOrientationReason?: InputMaybe<SortEnumType>;
  willPartecipateToNarritorno?: InputMaybe<SortEnumType>;
  willPartecipateToOrientation?: InputMaybe<SortEnumType>;
};

export type StudentsByFlow = {
  __typename?: 'StudentsByFlow';
  flow: Scalars['String']['output'];
  states: Array<StudentsByState>;
};

export type StudentsByLocalCenter = {
  __typename?: 'StudentsByLocalCenter';
  emails: Array<Scalars['String']['output']>;
  localCenterName: Scalars['String']['output'];
};

export type StudentsByState = {
  __typename?: 'StudentsByState';
  emails: Array<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** A segment of a collection. */
export type StudentsCollectionSegment = {
  __typename?: 'StudentsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Student>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onEvent: Event;
};

export type SubscriptionOnEventArgs = {
  targetId?: InputMaybe<Scalars['UUID']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Task = {
  __typename?: 'Task';
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deadline?: Maybe<Scalars['Instant']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  state: TaskState;
  targetId?: Maybe<Scalars['UUID']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: TaskType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type TaskEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskFilterInput = {
  and?: InputMaybe<Array<TaskFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  deadline?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<TaskFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  state?: InputMaybe<TaskStateOperationFilterInput>;
  targetId?: InputMaybe<UuidOperationFilterInput>;
  targetType?: InputMaybe<StringOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<TaskTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type TaskSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  deadline?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  targetId?: InputMaybe<SortEnumType>;
  targetType?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum TaskState {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Open = 'OPEN',
}

export type TaskStateOperationFilterInput = {
  eq?: InputMaybe<TaskState>;
  in?: InputMaybe<Array<TaskState>>;
  neq?: InputMaybe<TaskState>;
  nin?: InputMaybe<Array<TaskState>>;
};

export enum TaskType {
  Call = 'CALL',
  Email = 'EMAIL',
  File = 'FILE',
  Note = 'NOTE',
  Task = 'TASK',
}

export type TaskTypeOperationFilterInput = {
  eq?: InputMaybe<TaskType>;
  in?: InputMaybe<Array<TaskType>>;
  neq?: InputMaybe<TaskType>;
  nin?: InputMaybe<Array<TaskType>>;
};

/** A segment of a collection. */
export type TasksCollectionSegment = {
  __typename?: 'TasksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Task>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Thread = {
  __typename?: 'Thread';
  assigneeIds: Array<Scalars['UUID']['output']>;
  assignees: Array<User>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isRead: Scalars['Boolean']['output'];
  isResolved: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  lastMessageDate?: Maybe<Scalars['Instant']['output']>;
  messages?: Maybe<MessagesCollectionSegment>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['UUID']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  type: ThreadType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ThreadEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreadMessagesArgs = {
  order?: InputMaybe<Array<MessageSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageFilterInput>;
};

export type ThreadFilterInput = {
  and?: InputMaybe<Array<ThreadFilterInput>>;
  assigneeIds?: InputMaybe<ListUuidOperationFilterInput>;
  assignees?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isRead?: InputMaybe<BooleanOperationFilterInput>;
  isResolved?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  lastMessageDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<ThreadFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  subject?: InputMaybe<StringOperationFilterInput>;
  targetId?: InputMaybe<UuidOperationFilterInput>;
  targetType?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<ThreadTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ThreadSortInput = {
  contact?: InputMaybe<ContactSortInput>;
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isRead?: InputMaybe<SortEnumType>;
  isResolved?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  lastMessageDate?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  subject?: InputMaybe<SortEnumType>;
  targetId?: InputMaybe<SortEnumType>;
  targetType?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

export enum ThreadType {
  Email = 'EMAIL',
  WhatsApp = 'WHATS_APP',
}

export type ThreadTypeOperationFilterInput = {
  eq?: InputMaybe<ThreadType>;
  in?: InputMaybe<Array<ThreadType>>;
  neq?: InputMaybe<ThreadType>;
  nin?: InputMaybe<Array<ThreadType>>;
};

/** A segment of a collection. */
export type ThreadsCollectionSegment = {
  __typename?: 'ThreadsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Thread>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TimeSeriesItemOfInt32 = {
  __typename?: 'TimeSeriesItemOfInt32';
  instant: Scalars['Instant']['output'];
  value: Scalars['Int']['output'];
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type TriggerWorkflowError = BadRequestError;

export type TriggerWorkflowInput = {
  inputs?: InputMaybe<Scalars['JSON']['input']>;
  workflowId: Scalars['String']['input'];
  workflowName: Scalars['String']['input'];
};

export type TriggerWorkflowPayload = {
  __typename?: 'TriggerWorkflowPayload';
  errors?: Maybe<Array<TriggerWorkflowError>>;
  string?: Maybe<Scalars['String']['output']>;
};

export type UnauthorizedError = Error & {
  __typename?: 'UnauthorizedError';
  message: Scalars['String']['output'];
};

export type UpdateAnswerError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateAnswerInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  items: Array<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateAnswerPayload = {
  __typename?: 'UpdateAnswerPayload';
  answer?: Maybe<Answer>;
  errors?: Maybe<Array<UpdateAnswerError>>;
};

export type UpdateApplicationError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateApplicationInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  code: Scalars['String']['input'];
  currentFlowIndex?: InputMaybe<Scalars['Int']['input']>;
  currentStateIndex?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  estimatedInterculturaScholarshipAmount?: InputMaybe<Scalars['Float']['input']>;
  hasConsent?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaidAdvancePaymentQuota?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaidRegistrationQuota?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  programTypes: Array<ProgramType>;
};

export type UpdateApplicationPayload = {
  __typename?: 'UpdateApplicationPayload';
  application?: Maybe<Application>;
  errors?: Maybe<Array<UpdateApplicationError>>;
};

export type UpdateAreaError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateAreaInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateAreaPayload = {
  __typename?: 'UpdateAreaPayload';
  area?: Maybe<Area>;
  errors?: Maybe<Array<UpdateAreaError>>;
};

export type UpdateCannotLiveWithAnimalError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateCannotLiveWithAnimalInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  indoor: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  outdoor: Scalars['Boolean']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCannotLiveWithAnimalPayload = {
  __typename?: 'UpdateCannotLiveWithAnimalPayload';
  cannotLiveWithAnimal?: Maybe<CannotLiveWithAnimal>;
  errors?: Maybe<Array<UpdateCannotLiveWithAnimalError>>;
};

export type UpdateConsentError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateConsentInput = {
  applicationId: Scalars['UUID']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isAccepted: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  relationshipId: Scalars['UUID']['input'];
};

export type UpdateConsentPayload = {
  __typename?: 'UpdateConsentPayload';
  consent?: Maybe<Consent>;
  errors?: Maybe<Array<UpdateConsentError>>;
};

export type UpdateContactError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateContactInput = {
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
  address?: InputMaybe<AddressInput>;
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  birthDate?: InputMaybe<Scalars['LocalDate']['input']>;
  birthPlace?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isDeceased?: InputMaybe<Scalars['Boolean']['input']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mainContactId?: InputMaybe<Scalars['UUID']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['UUID']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact?: Maybe<Contact>;
  errors?: Maybe<Array<UpdateContactError>>;
};

export type UpdateContestError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateContestInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  flowSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
  isCurrent: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  previousContestId?: InputMaybe<Scalars['UUID']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContestPayload = {
  __typename?: 'UpdateContestPayload';
  contest?: Maybe<Contest>;
  errors?: Maybe<Array<UpdateContestError>>;
};

export type UpdateEligibilityTestDateError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateEligibilityTestDateInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  date: Scalars['LocalDate']['input'];
  deadLineDate: Scalars['LocalDate']['input'];
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  seats: Scalars['Int']['input'];
};

export type UpdateEligibilityTestDatePayload = {
  __typename?: 'UpdateEligibilityTestDatePayload';
  eligibilityTestDate?: Maybe<EligibilityTestDate>;
  errors?: Maybe<Array<UpdateEligibilityTestDateError>>;
};

export type UpdateEligibilityTestError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateEligibilityTestInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  eligibilityTestDate?: InputMaybe<Scalars['Date']['input']>;
  eligibilityTestOutcome?: InputMaybe<Scalars['String']['input']>;
  giuntiCode?: InputMaybe<Scalars['String']['input']>;
  giuntiPassword?: InputMaybe<Scalars['String']['input']>;
  hasPassedEligibilityTest?: InputMaybe<Scalars['Boolean']['input']>;
  hasTakenEligibilityTest?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  lastCredentialsSend?: InputMaybe<Scalars['DateTime']['input']>;
  lastDateSend?: InputMaybe<Scalars['DateTime']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  testDateId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateEligibilityTestPayload = {
  __typename?: 'UpdateEligibilityTestPayload';
  eligibilityTest?: Maybe<EligibilityTest>;
  errors?: Maybe<Array<UpdateEligibilityTestError>>;
};

export type UpdateEmergencyContactError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateEmergencyContactInput = {
  contact: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmergencyContactPayload = {
  __typename?: 'UpdateEmergencyContactPayload';
  emergencyContact?: Maybe<EmergencyContact>;
  errors?: Maybe<Array<UpdateEmergencyContactError>>;
};

export type UpdateFlowsError = NotFoundError | UnauthorizedError;

export type UpdateFlowsInput = {
  id: Scalars['UUID']['input'];
  updates: Array<KeyValuePairOfStringAndFlowUpdateInput>;
};

export type UpdateFlowsPayload = {
  __typename?: 'UpdateFlowsPayload';
  application?: Maybe<Application>;
  errors?: Maybe<Array<UpdateFlowsError>>;
};

export type UpdateHouseError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateHouseInput = {
  address: AddressInput;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: HouseType;
};

export type UpdateHousePayload = {
  __typename?: 'UpdateHousePayload';
  errors?: Maybe<Array<UpdateHouseError>>;
  house?: Maybe<House>;
};

export type UpdateInternationalApplicationError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateInternationalApplicationInput = {
  anticipatedGraduationDate?: InputMaybe<Scalars['YearMonth']['input']>;
  canEatDairy?: InputMaybe<Scalars['Boolean']['input']>;
  canEatFish?: InputMaybe<Scalars['Boolean']['input']>;
  canEatPoultry?: InputMaybe<Scalars['Boolean']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  dietaryPreference?: InputMaybe<DietaryPreference>;
  dietaryRestrictionsDescription?: InputMaybe<Scalars['String']['input']>;
  doesSmoke?: InputMaybe<Scalars['Boolean']['input']>;
  familyMemberHostDescription?: InputMaybe<Scalars['String']['input']>;
  familyMemberParticipantDescription?: InputMaybe<Scalars['String']['input']>;
  frequentAbsencesDescription?: InputMaybe<Scalars['String']['input']>;
  hasDietaryRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  hasFamilyMemberHosted?: InputMaybe<Scalars['Boolean']['input']>;
  hasFamilyMemberParticipated?: InputMaybe<Scalars['Boolean']['input']>;
  hasMedicalRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissedYearOrSemester?: InputMaybe<Scalars['Boolean']['input']>;
  hasRelationLivingAbroad?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  isFrequentlyAbsent?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  medicalRequirementsDescription?: InputMaybe<Scalars['String']['input']>;
  missedYearOrSemesterDescription?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  previousAbroadExperienceDescription?: InputMaybe<Scalars['String']['input']>;
  relationLivingAbroadDescription?: InputMaybe<Scalars['String']['input']>;
  willAbstainFromSmoking?: InputMaybe<Scalars['Boolean']['input']>;
  yearsOfPrimarySchool?: InputMaybe<Scalars['String']['input']>;
  yearsOfSecondarySchool?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInternationalApplicationPayload = {
  __typename?: 'UpdateInternationalApplicationPayload';
  errors?: Maybe<Array<UpdateInternationalApplicationError>>;
  internationalApplication?: Maybe<InternationalApplication>;
};

export type UpdateIseeError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateIseeInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  maxScholarshipAmount?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIseePayload = {
  __typename?: 'UpdateIseePayload';
  errors?: Maybe<Array<UpdateIseeError>>;
  isee?: Maybe<Isee>;
};

export type UpdateLabelError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateLabelInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload';
  errors?: Maybe<Array<UpdateLabelError>>;
  label?: Maybe<Label>;
};

export type UpdateLanguageProficiencyError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateLanguageProficiencyInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isPrimaryLanguage: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  language: Scalars['String']['input'];
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  listening: Scalars['Int']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  reading: Scalars['Int']['input'];
  speaking: Scalars['Int']['input'];
  writing: Scalars['Int']['input'];
  yearsOfStudy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLanguageProficiencyPayload = {
  __typename?: 'UpdateLanguageProficiencyPayload';
  errors?: Maybe<Array<UpdateLanguageProficiencyError>>;
  languageProficiency?: Maybe<LanguageProficiency>;
};

export type UpdateLeadError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateLeadInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  birthYear?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeadPayload = {
  __typename?: 'UpdateLeadPayload';
  errors?: Maybe<Array<UpdateLeadError>>;
  lead?: Maybe<Lead>;
};

export type UpdateLeadTypeError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateLeadTypeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateLeadTypePayload = {
  __typename?: 'UpdateLeadTypePayload';
  errors?: Maybe<Array<UpdateLeadTypeError>>;
  leadType?: Maybe<LeadType>;
};

export type UpdateLocalCenterError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateLocalCenterInput = {
  address?: InputMaybe<AddressInput>;
  areaId?: InputMaybe<Scalars['UUID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originLocalCenterId?: InputMaybe<Scalars['UUID']['input']>;
  presidentId?: InputMaybe<Scalars['UUID']['input']>;
  sendingResponsibleId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateLocalCenterPayload = {
  __typename?: 'UpdateLocalCenterPayload';
  errors?: Maybe<Array<UpdateLocalCenterError>>;
  localCenter?: Maybe<LocalCenter>;
};

export type UpdateMessageError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateMessageInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isSent?: Scalars['Boolean']['input'];
  isStarred: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  sentBy?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  errors?: Maybe<Array<UpdateMessageError>>;
  message?: Maybe<Message>;
};

export type UpdateProgramApplicationAnswerError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateProgramApplicationAnswerInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProgramApplicationAnswerPayload = {
  __typename?: 'UpdateProgramApplicationAnswerPayload';
  errors?: Maybe<Array<UpdateProgramApplicationAnswerError>>;
  programApplicationAnswer?: Maybe<ProgramApplicationAnswer>;
};

export type UpdateProgramApplicationError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateProgramApplicationInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  doesNotRequireScholarship?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originServiceId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ProgramApplicationState>;
};

export type UpdateProgramApplicationPayload = {
  __typename?: 'UpdateProgramApplicationPayload';
  errors?: Maybe<Array<UpdateProgramApplicationError>>;
  programApplication?: Maybe<ProgramApplication>;
};

export type UpdateProgramError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateProgramInput = {
  code: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  departureInfo?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationIOC?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['Date']['input'];
  id: Scalars['UUID']['input'];
  isReciprocal?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  returnInfo?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['Date']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  type: ProgramType;
};

export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  errors?: Maybe<Array<UpdateProgramError>>;
  program?: Maybe<Program>;
};

export type UpdateProgramQuestionError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateProgramQuestionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  text: Scalars['String']['input'];
};

export type UpdateProgramQuestionPayload = {
  __typename?: 'UpdateProgramQuestionPayload';
  errors?: Maybe<Array<UpdateProgramQuestionError>>;
  programQuestion?: Maybe<ProgramQuestion>;
};

export type UpdateRankingError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateRankingInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  position: Scalars['Int']['input'];
  status?: InputMaybe<RankingStatus>;
};

export type UpdateRankingPayload = {
  __typename?: 'UpdateRankingPayload';
  errors?: Maybe<Array<UpdateRankingError>>;
  ranking?: Maybe<Ranking>;
};

export type UpdateRelationshipTypeError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateRelationshipTypeInput = {
  id: Scalars['UUID']['input'];
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateRelationshipTypePayload = {
  __typename?: 'UpdateRelationshipTypePayload';
  errors?: Maybe<Array<UpdateRelationshipTypeError>>;
  relationshipType?: Maybe<RelationshipType>;
};

export type UpdateReportError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateReportInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  reportText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  errors?: Maybe<Array<UpdateReportError>>;
  report?: Maybe<Report>;
};

export type UpdateRequestError = ConflictError | NotFoundError;

export type UpdateRequestInput = {
  contactId: Scalars['UUID']['input'];
  deadline?: InputMaybe<Scalars['Instant']['input']>;
  id: Scalars['UUID']['input'];
  items: Array<RequestItemInput>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateRequestPayload = {
  __typename?: 'UpdateRequestPayload';
  errors?: Maybe<Array<UpdateRequestError>>;
  request?: Maybe<Array<Request>>;
};

export type UpdateScholarshipError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipFeeError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipFeeInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateScholarshipFeePayload = {
  __typename?: 'UpdateScholarshipFeePayload';
  errors?: Maybe<Array<UpdateScholarshipFeeError>>;
  scholarshipFee?: Maybe<ScholarshipFee>;
};

export type UpdateScholarshipInput = {
  closesAtDate: Scalars['Date']['input'];
  closesAtTime: Scalars['TimeSpan']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  doesRequireIsee: Scalars['Boolean']['input'];
  doesRequireOAPayment: Scalars['Boolean']['input'];
  id: Scalars['UUID']['input'];
  isActive: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  sponsorId: Scalars['UUID']['input'];
  typeId: Scalars['UUID']['input'];
};

export type UpdateScholarshipPayload = {
  __typename?: 'UpdateScholarshipPayload';
  errors?: Maybe<Array<UpdateScholarshipError>>;
  scholarship?: Maybe<Scholarship>;
};

export type UpdateScholarshipProgramError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipProgramInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateScholarshipProgramPayload = {
  __typename?: 'UpdateScholarshipProgramPayload';
  errors?: Maybe<Array<UpdateScholarshipProgramError>>;
  scholarshipProgram?: Maybe<ScholarshipProgram>;
};

export type UpdateScholarshipProgramRequestError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipProgramRequestInput = {
  coverage: Scalars['Decimal']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateScholarshipProgramRequestPayload = {
  __typename?: 'UpdateScholarshipProgramRequestPayload';
  errors?: Maybe<Array<UpdateScholarshipProgramRequestError>>;
  scholarshipProgramRequest?: Maybe<ScholarshipProgramRequest>;
};

export type UpdateScholarshipQuestionError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipQuestionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  sorting: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type UpdateScholarshipQuestionPayload = {
  __typename?: 'UpdateScholarshipQuestionPayload';
  errors?: Maybe<Array<UpdateScholarshipQuestionError>>;
  scholarshipQuestion?: Maybe<ScholarshipQuestion>;
};

export type UpdateScholarshipRequestAnswerError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipRequestAnswerInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  text: Scalars['String']['input'];
};

export type UpdateScholarshipRequestAnswerPayload = {
  __typename?: 'UpdateScholarshipRequestAnswerPayload';
  errors?: Maybe<Array<UpdateScholarshipRequestAnswerError>>;
  scholarshipRequestAnswer?: Maybe<ScholarshipRequestAnswer>;
};

export type UpdateScholarshipRequestError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipRequestInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  state: ScholarshipRequestState;
};

export type UpdateScholarshipRequestPayload = {
  __typename?: 'UpdateScholarshipRequestPayload';
  errors?: Maybe<Array<UpdateScholarshipRequestError>>;
  scholarshipRequest?: Maybe<ScholarshipRequest>;
};

export type UpdateScholarshipTypeError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScholarshipTypeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateScholarshipTypePayload = {
  __typename?: 'UpdateScholarshipTypePayload';
  errors?: Maybe<Array<UpdateScholarshipTypeError>>;
  scholarshipType?: Maybe<ScholarshipType>;
};

export type UpdateSchoolError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateSchoolInput = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isVerified: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originSchoolId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateSchoolPayload = {
  __typename?: 'UpdateSchoolPayload';
  errors?: Maybe<Array<UpdateSchoolError>>;
  school?: Maybe<School>;
};

export type UpdateSchoolReportError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateSchoolReportInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: AcademicResultsType;
  year: Scalars['String']['input'];
};

export type UpdateSchoolReportItemError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateSchoolReportItemInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  grade?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  subject: Scalars['String']['input'];
};

export type UpdateSchoolReportItemPayload = {
  __typename?: 'UpdateSchoolReportItemPayload';
  errors?: Maybe<Array<UpdateSchoolReportItemError>>;
  schoolReportItem?: Maybe<SchoolReportItem>;
};

export type UpdateSchoolReportPayload = {
  __typename?: 'UpdateSchoolReportPayload';
  errors?: Maybe<Array<UpdateSchoolReportError>>;
  schoolReport?: Maybe<SchoolReport>;
};

export type UpdateSchoolReportSummaryError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateSchoolReportSummaryInput = {
  averageGrade?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: SchoolReportType;
  year: Scalars['String']['input'];
};

export type UpdateSchoolReportSummaryPayload = {
  __typename?: 'UpdateSchoolReportSummaryPayload';
  errors?: Maybe<Array<UpdateSchoolReportSummaryError>>;
  schoolReportSummary?: Maybe<SchoolReportSummary>;
};

export type UpdateScoreError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateScoreInput = {
  committeeScore?: InputMaybe<Scalars['Float']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  giuntiTestScore?: InputMaybe<Scalars['Float']['input']>;
  hospitalityScore?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterScore?: InputMaybe<Scalars['Float']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  scholasticScore?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateScorePayload = {
  __typename?: 'UpdateScorePayload';
  errors?: Maybe<Array<UpdateScoreError>>;
  score?: Maybe<Score>;
};

export type UpdateSponsorError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateSponsorInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateSponsorPayload = {
  __typename?: 'UpdateSponsorPayload';
  errors?: Maybe<Array<UpdateSponsorError>>;
  sponsor?: Maybe<Sponsor>;
};

export type UpdateStudentError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateStudentInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  documentsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  doesRequireReligionServicesAccess?: InputMaybe<Scalars['Boolean']['input']>;
  expectationsDescription?: InputMaybe<Scalars['String']['input']>;
  externalLink?: InputMaybe<Scalars['URL']['input']>;
  familyDescription?: InputMaybe<Scalars['String']['input']>;
  growthDescription?: InputMaybe<Scalars['String']['input']>;
  healthDescription?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isFamilyWillingToHost?: InputMaybe<Scalars['Boolean']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  originalSchoolId?: InputMaybe<Scalars['UUID']['input']>;
  personalityDescription?: InputMaybe<Scalars['String']['input']>;
  religionAttendanceFrequency?: InputMaybe<Scalars['String']['input']>;
  religionDescription?: InputMaybe<Scalars['String']['input']>;
  schoolDescription?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['UUID']['input']>;
  willNotPartecipateToNarritornoReason?: InputMaybe<Scalars['String']['input']>;
  willNotPartecipateToOrientationReason?: InputMaybe<Scalars['String']['input']>;
  willPartecipateToNarritorno?: InputMaybe<Scalars['Boolean']['input']>;
  willPartecipateToOrientation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStudentPayload = {
  __typename?: 'UpdateStudentPayload';
  errors?: Maybe<Array<UpdateStudentError>>;
  student?: Maybe<Student>;
};

export type UpdateTaskError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateTaskInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deadline?: InputMaybe<Scalars['Instant']['input']>;
  id: Scalars['UUID']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  state: TaskState;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  errors?: Maybe<Array<UpdateTaskError>>;
  task?: Maybe<Task>;
};

export type UpdateThreadError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateThreadInput = {
  assigneesIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isRead: Scalars['Boolean']['input'];
  isResolved: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateThreadPayload = {
  __typename?: 'UpdateThreadPayload';
  errors?: Maybe<Array<UpdateThreadError>>;
  thread?: Maybe<Thread>;
};

export type UpdateUserError = ConflictError | NotFoundError;

export type UpdateUserInput = {
  avatarId?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localCenterId?: InputMaybe<Scalars['UUID']['input']>;
  roleNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  errors?: Maybe<Array<UpdateUserError>>;
  user?: Maybe<User>;
};

export type UpdateViewError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateViewInput = {
  columnOrdersView?: InputMaybe<Array<ColumnOrdersViewInput>>;
  columnsView?: InputMaybe<Array<ColumnsViewInput>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['UUID']['input'];
  isDefault: Scalars['Boolean']['input'];
  isPublic: Scalars['Boolean']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  name: Scalars['String']['input'];
  originDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type UpdateViewPayload = {
  __typename?: 'UpdateViewPayload';
  errors?: Maybe<Array<UpdateViewError>>;
  view?: Maybe<View>;
};

export type UpdateWorkflowInfoError = ConflictError | NotFoundError | UnauthorizedError;

export type UpdateWorkflowInfoInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  effects?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['UUID']['input'];
  inputs?: InputMaybe<Scalars['JSON']['input']>;
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lastAccessAt?: InputMaybe<Scalars['Instant']['input']>;
  originDate?: InputMaybe<Scalars['Instant']['input']>;
  type: WorkflowType;
  workflowDescription?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkflowInfoPayload = {
  __typename?: 'UpdateWorkflowInfoPayload';
  errors?: Maybe<Array<UpdateWorkflowInfoError>>;
  workflowInfo?: Maybe<WorkflowInfo>;
};

export type UploadDocumentError = BadRequestError | UnauthorizedError;

export type UploadDocumentInput = {
  file: Scalars['Upload']['input'];
  labelsIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UploadDocumentPayload = {
  __typename?: 'UploadDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<UploadDocumentError>>;
};

export type UriSortInput = {
  absolutePath?: InputMaybe<SortEnumType>;
  absoluteUri?: InputMaybe<SortEnumType>;
  authority?: InputMaybe<SortEnumType>;
  dnsSafeHost?: InputMaybe<SortEnumType>;
  fragment?: InputMaybe<SortEnumType>;
  host?: InputMaybe<SortEnumType>;
  hostNameType?: InputMaybe<SortEnumType>;
  idnHost?: InputMaybe<SortEnumType>;
  isAbsoluteUri?: InputMaybe<SortEnumType>;
  isDefaultPort?: InputMaybe<SortEnumType>;
  isFile?: InputMaybe<SortEnumType>;
  isLoopback?: InputMaybe<SortEnumType>;
  isUnc?: InputMaybe<SortEnumType>;
  localPath?: InputMaybe<SortEnumType>;
  originalString?: InputMaybe<SortEnumType>;
  pathAndQuery?: InputMaybe<SortEnumType>;
  port?: InputMaybe<SortEnumType>;
  query?: InputMaybe<SortEnumType>;
  scheme?: InputMaybe<SortEnumType>;
  userEscaped?: InputMaybe<SortEnumType>;
  userInfo?: InputMaybe<SortEnumType>;
};

export type UrlOperationFilterInput = {
  eq?: InputMaybe<Scalars['URL']['input']>;
  gt?: InputMaybe<Scalars['URL']['input']>;
  gte?: InputMaybe<Scalars['URL']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  lt?: InputMaybe<Scalars['URL']['input']>;
  lte?: InputMaybe<Scalars['URL']['input']>;
  neq?: InputMaybe<Scalars['URL']['input']>;
  ngt?: InputMaybe<Scalars['URL']['input']>;
  ngte?: InputMaybe<Scalars['URL']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['URL']['input']>>>;
  nlt?: InputMaybe<Scalars['URL']['input']>;
  nlte?: InputMaybe<Scalars['URL']['input']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Document>;
  avatarId?: Maybe<Scalars['UUID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  localCenterId?: Maybe<Scalars['UUID']['output']>;
  roles: Array<Scalars['String']['output']>;
  search: Scalars['String']['output'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  avatar?: InputMaybe<DocumentFilterInput>;
  avatarId?: InputMaybe<UuidOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  localCenterId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  roles?: InputMaybe<ListStringOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
};

export type UserSortInput = {
  avatar?: InputMaybe<DocumentSortInput>;
  avatarId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isEnabled?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  localCenterId?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type UsersCollectionSegment = {
  __typename?: 'UsersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type View = {
  __typename?: 'View';
  columnOrdersView?: Maybe<Array<ColumnOrdersView>>;
  columnsView?: Maybe<Array<ColumnsView>>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  isDefault: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  name: Scalars['String']['output'];
  originDate?: Maybe<Scalars['Instant']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type ViewEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ViewFilterInput = {
  and?: InputMaybe<Array<ViewFilterInput>>;
  columnOrdersView?: InputMaybe<ListFilterInputTypeOfColumnOrdersViewFilterInput>;
  columnsView?: InputMaybe<ListFilterInputTypeOfColumnsViewFilterInput>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ViewFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
};

export type ViewSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isPublic?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ViewsCollectionSegment = {
  __typename?: 'ViewsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<View>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkflowInfo = {
  __typename?: 'WorkflowInfo';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['UUID']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  effects?: Maybe<Array<Scalars['String']['output']>>;
  events?: Maybe<EventsCollectionSegment>;
  id: Scalars['UUID']['output'];
  inputs?: Maybe<Scalars['JSON']['output']>;
  labelIds: Array<Scalars['UUID']['output']>;
  labels: Array<Label>;
  lastAccessAt?: Maybe<Scalars['Instant']['output']>;
  originDate?: Maybe<Scalars['Instant']['output']>;
  search: Scalars['String']['output'];
  type: WorkflowType;
  updatedAt: Scalars['Instant']['output'];
  updatedBy: Scalars['UUID']['output'];
  workflowDescription?: Maybe<Scalars['String']['output']>;
  workflowId?: Maybe<Scalars['String']['output']>;
  workflowName?: Maybe<Scalars['String']['output']>;
};

export type WorkflowInfoEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowInfoFilterInput = {
  and?: InputMaybe<Array<WorkflowInfoFilterInput>>;
  createdAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  data?: InputMaybe<JsonElementFilterInput>;
  effects?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  inputs?: InputMaybe<JsonElementFilterInput>;
  labelIds?: InputMaybe<ListUuidOperationFilterInput>;
  labels?: InputMaybe<ListFilterInputTypeOfLabelFilterInput>;
  lastAccessAt?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowInfoFilterInput>>;
  originDate?: InputMaybe<ComparableNullableOfInstantOperationFilterInput>;
  search?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<WorkflowTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableInstantOperationFilterInput>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  workflowDescription?: InputMaybe<StringOperationFilterInput>;
  workflowId?: InputMaybe<StringOperationFilterInput>;
  workflowName?: InputMaybe<StringOperationFilterInput>;
};

export type WorkflowInfoSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inputs?: InputMaybe<SortEnumType>;
  lastAccessAt?: InputMaybe<SortEnumType>;
  originDate?: InputMaybe<SortEnumType>;
  search?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<SortEnumType>;
  workflowDescription?: InputMaybe<SortEnumType>;
  workflowId?: InputMaybe<SortEnumType>;
  workflowName?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type WorkflowInfosCollectionSegment = {
  __typename?: 'WorkflowInfosCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkflowInfo>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum WorkflowType {
  Pull = 'PULL',
  Send = 'SEND',
  Sync = 'SYNC',
  Test = 'TEST',
}

export type WorkflowTypeOperationFilterInput = {
  eq?: InputMaybe<WorkflowType>;
  in?: InputMaybe<Array<WorkflowType>>;
  neq?: InputMaybe<WorkflowType>;
  nin?: InputMaybe<Array<WorkflowType>>;
};

export type YearMonthFilterInput = {
  and?: InputMaybe<Array<YearMonthFilterInput>>;
  calendar?: InputMaybe<CalendarSystemFilterInput>;
  era?: InputMaybe<EraFilterInput>;
  month?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<YearMonthFilterInput>>;
  year?: InputMaybe<IntOperationFilterInput>;
  yearOfEra?: InputMaybe<IntOperationFilterInput>;
};

export type GetWorkflowsInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkflowsInfoQuery = {
  __typename?: 'Query';
  workflowInfos?: {
    __typename?: 'WorkflowInfosCollectionSegment';
    items?: Array<{
      __typename?: 'WorkflowInfo';
      workflowId?: string | null;
      workflowName?: string | null;
      inputs?: any | null;
    }> | null;
  } | null;
};

export type TriggerWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String']['input'];
  workflowName: Scalars['String']['input'];
  workflowInputs?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type TriggerWorkflowMutation = {
  __typename?: 'Mutation';
  triggerWorkflow: { __typename?: 'TriggerWorkflowPayload'; string?: string | null };
};

export type CreateInternationalApplicationMutationVariables = Exact<{
  input: CreateInternationalApplicationInput;
}>;

export type CreateInternationalApplicationMutation = {
  __typename?: 'Mutation';
  createInternationalApplication: {
    __typename?: 'CreateInternationalApplicationPayload';
    internationalApplication?: { __typename?: 'InternationalApplication'; id: any } | null;
  };
};

export type DeleteInternationalApplicationMutationVariables = Exact<{
  input: DeleteInternationalApplicationInput;
}>;

export type DeleteInternationalApplicationMutation = {
  __typename?: 'Mutation';
  deleteInternationalApplication: {
    __typename?: 'DeleteInternationalApplicationPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateInternationalApplicationMutationVariables = Exact<{
  input: UpdateInternationalApplicationInput;
}>;

export type UpdateInternationalApplicationMutation = {
  __typename?: 'Mutation';
  updateInternationalApplication: {
    __typename?: 'UpdateInternationalApplicationPayload';
    internationalApplication?: { __typename?: 'InternationalApplication'; id: any } | null;
  };
};

export type InternationalApplicationQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type InternationalApplicationQuery = {
  __typename?: 'Query';
  internationalApplication?: {
    __typename?: 'InternationalApplication';
    anticipatedGraduationDate?: any | null;
    canEatDairy?: boolean | null;
    canEatFish?: boolean | null;
    canEatPoultry?: boolean | null;
    dietaryPreference?: DietaryPreference | null;
    dietaryRestrictionsDescription?: string | null;
    doesSmoke?: boolean | null;
    familyMemberHostDescription?: string | null;
    familyMemberParticipantDescription?: string | null;
    frequentAbsencesDescription?: string | null;
    hasDietaryRestrictions?: boolean | null;
    hasFamilyMemberHosted?: boolean | null;
    hasFamilyMemberParticipated?: boolean | null;
    hasMedicalRequirements?: boolean | null;
    hasMissedYearOrSemester?: boolean | null;
    hasRelationLivingAbroad?: boolean | null;
    id: any;
    isFrequentlyAbsent?: boolean | null;
    medicalRequirementsDescription?: string | null;
    missedYearOrSemesterDescription?: string | null;
    previousAbroadExperienceDescription?: string | null;
    relationLivingAbroadDescription?: string | null;
    willAbstainFromSmoking?: boolean | null;
    yearsOfPrimarySchool?: string | null;
    yearsOfSecondarySchool?: string | null;
    cannotLiveWithAnimals: Array<{
      __typename?: 'CannotLiveWithAnimal';
      reason?: string | null;
      name: string;
      internationalApplicationId: any;
      id: any;
      indoor: boolean;
      outdoor: boolean;
    }>;
    labels: Array<{ __typename?: 'Label'; id: any }>;
    languageProficiencies: Array<{
      __typename?: 'LanguageProficiency';
      id: any;
      internationalApplicationId: any;
      language: string;
      listening: number;
      reading: number;
      speaking: number;
      writing: number;
      yearsOfStudy?: string | null;
      isPrimaryLanguage: boolean;
    }>;
  } | null;
};

export type CreateCannotLiveWithAnimalMutationVariables = Exact<{
  input: CreateCannotLiveWithAnimalInput;
}>;

export type CreateCannotLiveWithAnimalMutation = {
  __typename?: 'Mutation';
  createCannotLiveWithAnimal: {
    __typename?: 'CreateCannotLiveWithAnimalPayload';
    cannotLiveWithAnimal?: { __typename?: 'CannotLiveWithAnimal'; name: string } | null;
  };
};

export type DeleteCannotLiveWithAnimalMutationVariables = Exact<{
  input: DeleteCannotLiveWithAnimalInput;
}>;

export type DeleteCannotLiveWithAnimalMutation = {
  __typename?: 'Mutation';
  deleteCannotLiveWithAnimal: {
    __typename?: 'DeleteCannotLiveWithAnimalPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateCannotLiveWithAnimalMutationVariables = Exact<{
  input: UpdateCannotLiveWithAnimalInput;
}>;

export type UpdateCannotLiveWithAnimalMutation = {
  __typename?: 'Mutation';
  updateCannotLiveWithAnimal: {
    __typename?: 'UpdateCannotLiveWithAnimalPayload';
    cannotLiveWithAnimal?: { __typename?: 'CannotLiveWithAnimal'; name: string } | null;
  };
};

export type CreateLanguageProficiencyMutationVariables = Exact<{
  input: CreateLanguageProficiencyInput;
}>;

export type CreateLanguageProficiencyMutation = {
  __typename?: 'Mutation';
  createLanguageProficiency: {
    __typename?: 'CreateLanguageProficiencyPayload';
    languageProficiency?: { __typename?: 'LanguageProficiency'; id: any } | null;
  };
};

export type DeleteLanguageProficiencyMutationVariables = Exact<{
  input: DeleteLanguageProficiencyInput;
}>;

export type DeleteLanguageProficiencyMutation = {
  __typename?: 'Mutation';
  deleteLanguageProficiency: {
    __typename?: 'DeleteLanguageProficiencyPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateLanguageProficiencyMutationVariables = Exact<{
  input: UpdateLanguageProficiencyInput;
}>;

export type UpdateLanguageProficiencyMutation = {
  __typename?: 'Mutation';
  updateLanguageProficiency: {
    __typename?: 'UpdateLanguageProficiencyPayload';
    languageProficiency?: { __typename?: 'LanguageProficiency'; id: any } | null;
  };
};

export type GetApplicationTypesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ApplicationSortInput> | ApplicationSortInput>;
}>;

export type GetApplicationTypesQuery = {
  __typename?: 'Query';
  applications?: {
    __typename?: 'ApplicationsCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Application'; id: any }> | null;
  } | null;
};

export type GetApplicationTypesSimpleQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ApplicationSortInput> | ApplicationSortInput>;
}>;

export type GetApplicationTypesSimpleQuery = {
  __typename?: 'Query';
  applications?: {
    __typename?: 'ApplicationsCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Application'; id: any }> | null;
  } | null;
};

export type CreateApplicationTypeMutationVariables = Exact<{
  input: CreateApplicationInput;
}>;

export type CreateApplicationTypeMutation = {
  __typename?: 'Mutation';
  createApplication: {
    __typename?: 'CreateApplicationPayload';
    application?: { __typename?: 'Application'; id: any } | null;
  };
};

export type UpdateApplicationTypeMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationTypeMutation = {
  __typename?: 'Mutation';
  updateApplication: {
    __typename?: 'UpdateApplicationPayload';
    application?: { __typename?: 'Application'; id: any } | null;
  };
};

export type DeleteApplicationTypeMutationVariables = Exact<{
  input: DeleteApplicationInput;
}>;

export type DeleteApplicationTypeMutation = {
  __typename?: 'Mutation';
  deleteApplication: { __typename?: 'DeleteApplicationPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInput;
}>;

export type CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication: {
    __typename?: 'CreateApplicationPayload';
    application?: { __typename?: 'Application'; id: any } | null;
  };
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetApplicationQuery = {
  __typename?: 'Query';
  application?: {
    __typename?: 'Application';
    id: any;
    code: string;
    hasPaidRegistrationQuota?: boolean | null;
    hasPaidAdvancePaymentQuota?: boolean | null;
    studentId: any;
    originDate?: any | null;
    lastAccessAt?: any | null;
    currentFlowIndex?: number | null;
    programTypes: Array<ProgramType>;
    isClosed?: boolean | null;
    updatedAt: any;
    data?: any | null;
    hasConsent?: boolean | null;
    estimatedInterculturaScholarshipAmount?: number | null;
    currentStateIndex?: number | null;
    consents: Array<{
      __typename?: 'Consent';
      id: any;
      applicationId: any;
      data?: any | null;
      isAccepted: boolean;
      lastAccessAt?: any | null;
      originDate?: any | null;
      relationshipId: any;
      relationship: {
        __typename?: 'Relationship';
        id: any;
        relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
      };
    }>;
    score?: {
      __typename?: 'Score';
      id: any;
      applicationId: any;
      committeeScore?: number | null;
      giuntiTestScore?: number | null;
      hospitalityScore?: number | null;
      localCenterScore?: number | null;
      scholasticScore?: number | null;
      preselectionTotal?: number | null;
      total?: number | null;
    } | null;
    ranking?: {
      __typename?: 'Ranking';
      id: any;
      applicationId: any;
      name?: string | null;
      position: number;
      sponsorId: any;
      status?: RankingStatus | null;
    } | null;
    flows?: Array<{
      __typename?: 'Flow';
      name: string;
      states: Array<{ __typename?: 'FlowState'; name: string; isCompleted: boolean }>;
    }> | null;
    assignees: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }>;
    contest?: { __typename?: 'Contest'; id: any; name: string; year?: string | null } | null;
    documents: Array<{
      __typename?: 'Document';
      id: any;
      fileName: string;
      uri: string;
      contentType: string;
      contentLength: any;
    }>;
    eligibilityTest?: {
      __typename?: 'EligibilityTest';
      id: any;
      giuntiCode?: string | null;
      giuntiPassword?: string | null;
      testDateId?: any | null;
      hasTakenEligibilityTest?: boolean | null;
      hasPassedEligibilityTest?: boolean | null;
      eligibilityTestOutcome?: string | null;
    } | null;
    iseEs: Array<{
      __typename?: 'Isee';
      id: any;
      year?: string | null;
      range?: string | null;
      amount?: string | null;
      maxScholarshipAmount?: string | null;
      documents: Array<{
        __typename?: 'Document';
        contentLength: any;
        contentType: string;
        fileName: string;
        id: any;
        uri: string;
      }>;
    }>;
    labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null; targetType?: string | null }>;
    scholarshipRequests: Array<{
      __typename?: 'ScholarshipRequest';
      id: any;
      state: ScholarshipRequestState;
      scholarship: {
        __typename?: 'Scholarship';
        id: any;
        closesAtTime: any;
        doesRequireIsee: boolean;
        doesRequireOAPayment: boolean;
        closesAtDate: any;
        isActive: boolean;
        sponsor: { __typename?: 'Sponsor'; id: any; name: string };
        type: { __typename?: 'ScholarshipType'; id: any; name: string };
      };
      answers: Array<{
        __typename?: 'ScholarshipRequestAnswer';
        id: any;
        text: string;
        questionId: any;
        question: { __typename?: 'ScholarshipQuestion'; id: any; text: string };
      }>;
    }>;
    programApplications: Array<{
      __typename?: 'ProgramApplication';
      applicationId: any;
      programId: any;
      id: any;
      state?: ProgramApplicationState | null;
      originDate?: any | null;
      lastAccessAt?: any | null;
      answers: Array<{
        __typename?: 'ProgramApplicationAnswer';
        id: any;
        text?: string | null;
        questionId: any;
        documents: Array<{
          __typename?: 'Document';
          contentLength: any;
          contentType: string;
          fileName: string;
          id: any;
          uri: string;
        }>;
        question: { __typename?: 'ProgramQuestion'; id: any; text: string };
      }>;
      program: {
        __typename?: 'Program';
        code: string;
        departureInfo?: string | null;
        description?: string | null;
        destinationIOC?: string | null;
        duration?: string | null;
        endAt: any;
        id: any;
        isReciprocal?: boolean | null;
        language?: string | null;
        returnInfo?: string | null;
        startAt: any;
        type: ProgramType;
        questions: Array<{ __typename?: 'ProgramQuestion'; id: any; text: string }>;
      };
      scholarshipProgramRequests: Array<{
        __typename?: 'ScholarshipProgramRequest';
        coverage: any;
        id: any;
        programApplicationId: any;
        scholarshipRequestId: any;
        scholarshipRequest: {
          __typename?: 'ScholarshipRequest';
          id: any;
          state: ScholarshipRequestState;
          originDate?: any | null;
          lastAccessAt?: any | null;
          scholarship: {
            __typename?: 'Scholarship';
            id: any;
            closesAtTime: any;
            doesRequireIsee: boolean;
            doesRequireOAPayment: boolean;
            closesAtDate: any;
            isActive: boolean;
            questions: Array<{ __typename?: 'ScholarshipQuestion'; id: any; text: string }>;
            sponsor: { __typename?: 'Sponsor'; id: any; name: string };
            type: { __typename?: 'ScholarshipType'; id: any; name: string };
          };
          answers: Array<{
            __typename?: 'ScholarshipRequestAnswer';
            id: any;
            text: string;
            questionId: any;
            question: { __typename?: 'ScholarshipQuestion'; id: any; text: string };
          }>;
        };
      }>;
    }>;
    reports: Array<{
      __typename?: 'Report';
      id: any;
      reportText?: string | null;
      documents: Array<{ __typename?: 'Document'; uri: string }>;
    }>;
    internationalApplication?: {
      __typename?: 'InternationalApplication';
      anticipatedGraduationDate?: any | null;
      canEatDairy?: boolean | null;
      canEatFish?: boolean | null;
      canEatPoultry?: boolean | null;
      dietaryPreference?: DietaryPreference | null;
      dietaryRestrictionsDescription?: string | null;
      doesSmoke?: boolean | null;
      familyMemberHostDescription?: string | null;
      familyMemberParticipantDescription?: string | null;
      frequentAbsencesDescription?: string | null;
      hasDietaryRestrictions?: boolean | null;
      hasFamilyMemberHosted?: boolean | null;
      hasFamilyMemberParticipated?: boolean | null;
      hasMedicalRequirements?: boolean | null;
      hasMissedYearOrSemester?: boolean | null;
      hasRelationLivingAbroad?: boolean | null;
      id: any;
      isFrequentlyAbsent?: boolean | null;
      medicalRequirementsDescription?: string | null;
      missedYearOrSemesterDescription?: string | null;
      previousAbroadExperienceDescription?: string | null;
      relationLivingAbroadDescription?: string | null;
      willAbstainFromSmoking?: boolean | null;
      yearsOfPrimarySchool?: string | null;
      yearsOfSecondarySchool?: string | null;
      cannotLiveWithAnimals: Array<{
        __typename?: 'CannotLiveWithAnimal';
        reason?: string | null;
        name: string;
        internationalApplicationId: any;
        id: any;
        indoor: boolean;
        outdoor: boolean;
      }>;
      labels: Array<{ __typename?: 'Label'; id: any }>;
      languageProficiencies: Array<{
        __typename?: 'LanguageProficiency';
        id: any;
        internationalApplicationId: any;
        language: string;
        listening: number;
        reading: number;
        speaking: number;
        writing: number;
        yearsOfStudy?: string | null;
        isPrimaryLanguage: boolean;
      }>;
    } | null;
  } | null;
};

export type GetApplicationsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ApplicationSortInput> | ApplicationSortInput>;
  filter?: InputMaybe<ApplicationFilterInput>;
}>;

export type GetApplicationsQuery = {
  __typename?: 'Query';
  applications?: {
    __typename?: 'ApplicationsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Application';
      data?: any | null;
      originDate?: any | null;
      createdAt: any;
      lastAccessAt?: any | null;
      id: any;
      estimatedInterculturaScholarshipAmount?: number | null;
      isClosed?: boolean | null;
      hasPaidRegistrationQuota?: boolean | null;
      hasPaidAdvancePaymentQuota?: boolean | null;
      studentId: any;
      code: string;
      programTypes: Array<ProgramType>;
      currentFlowIndex?: number | null;
      currentStateIndex?: number | null;
      consents: Array<{ __typename?: 'Consent'; id: any; isAccepted: boolean }>;
      documents: Array<{ __typename?: 'Document'; fileName: string }>;
      iseEs: Array<{ __typename?: 'Isee'; amount?: string | null; maxScholarshipAmount?: string | null }>;
      labels: Array<{ __typename?: 'Label'; name: string; id: any; data?: any | null }>;
      ranking?: {
        __typename?: 'Ranking';
        id: any;
        applicationId: any;
        name?: string | null;
        position: number;
        sponsorId: any;
        status?: RankingStatus | null;
      } | null;
      score?: {
        __typename?: 'Score';
        committeeScore?: number | null;
        giuntiTestScore?: number | null;
        hospitalityScore?: number | null;
        localCenterScore?: number | null;
        scholasticScore?: number | null;
        preselectionTotal?: number | null;
        total?: number | null;
      } | null;
      programApplications: Array<{
        __typename?: 'ProgramApplication';
        state?: ProgramApplicationState | null;
        scholarshipProgramRequests: Array<{
          __typename?: 'ScholarshipProgramRequest';
          coverage: any;
          scholarshipRequest: {
            __typename?: 'ScholarshipRequest';
            state: ScholarshipRequestState;
            scholarship: { __typename?: 'Scholarship'; sponsor: { __typename?: 'Sponsor'; id: any; name: string } };
          };
        }>;
        program: { __typename?: 'Program'; code: string; destinationIOC?: string | null };
      }>;
      eligibilityTest?: {
        __typename?: 'EligibilityTest';
        eligibilityTestDate?: any | null;
        giuntiCode?: string | null;
        giuntiPassword?: string | null;
        testDateId?: any | null;
        hasTakenEligibilityTest?: boolean | null;
        hasPassedEligibilityTest?: boolean | null;
        eligibilityTestOutcome?: string | null;
      } | null;
      internationalApplication?: {
        __typename?: 'InternationalApplication';
        languageProficiencies: Array<{ __typename?: 'LanguageProficiency'; language: string }>;
      } | null;
      scholarshipRequests: Array<{
        __typename?: 'ScholarshipRequest';
        scholarship: { __typename?: 'Scholarship'; sponsor: { __typename?: 'Sponsor'; id: any; name: string } };
      }>;
      contest?: { __typename?: 'Contest'; year?: string | null } | null;
      flows?: Array<{
        __typename?: 'Flow';
        name: string;
        states: Array<{ __typename?: 'FlowState'; name: string; isCompleted: boolean }>;
      }> | null;
      student: {
        __typename?: 'StudentBase';
        lastAccessAt?: any | null;
        expectationsDescription?: string | null;
        familyDescription?: string | null;
        growthDescription?: string | null;
        healthDescription?: string | null;
        personalityDescription?: string | null;
        religionDescription?: string | null;
        schoolDescription?: string | null;
        isFamilyWillingToHost?: boolean | null;
        schoolId?: any | null;
        createdAt: any;
        updatedAt: any;
        localCenter?: {
          __typename?: 'LocalCenter';
          name: string;
          area?: { __typename?: 'Area'; name: string } | null;
          address?: { __typename?: 'Address'; administrativeArea?: string | null } | null;
        } | null;
        school?: { __typename?: 'School'; name: string; code?: string | null; id: any } | null;
        schoolReportSummaries: Array<{
          __typename?: 'SchoolReportSummary';
          averageGrade?: string | null;
          year: string;
        }>;
        contact: {
          __typename?: 'Contact';
          fiscalCode?: string | null;
          code?: string | null;
          birthDate?: any | null;
          email?: string | null;
          phoneNumber?: string | null;
          lastName?: string | null;
          firstName?: string | null;
          address?: {
            __typename?: 'Address';
            country?: string | null;
            locality?: string | null;
            postalCode?: string | null;
          } | null;
          picture?: {
            __typename?: 'Document';
            id: any;
            fileName: string;
            uri: string;
            contentType: string;
            contentLength: any;
          } | null;
          relationships: Array<{
            __typename?: 'Relationship';
            objectContactId: any;
            id: any;
            relationshipType: { __typename?: 'RelationshipType'; name: string; id: any };
          }>;
        };
      };
    }> | null;
  } | null;
};

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationMutation = {
  __typename?: 'Mutation';
  updateApplication: {
    __typename?: 'UpdateApplicationPayload';
    application?: { __typename?: 'Application'; id: any } | null;
  };
};

export type DeleteApplicationMutationVariables = Exact<{
  input: DeleteApplicationInput;
}>;

export type DeleteApplicationMutation = {
  __typename?: 'Mutation';
  deleteApplication: { __typename?: 'DeleteApplicationPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type GetEligibilityTestDatesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  filter?: InputMaybe<EligibilityTestDateFilterInput>;
  order?: InputMaybe<Array<EligibilityTestDateSortInput> | EligibilityTestDateSortInput>;
}>;

export type GetEligibilityTestDatesQuery = {
  __typename?: 'Query';
  eligibilityTestDates?: {
    __typename?: 'EligibilityTestDatesCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'EligibilityTestDate'; id: any; date: any; availableSeats: number }> | null;
  } | null;
};

export type GetEligibilityTestDateQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetEligibilityTestDateQuery = {
  __typename?: 'Query';
  eligibilityTestDate?: { __typename?: 'EligibilityTestDate'; id: any; date: any } | null;
};

export type CreateEligibilityTestMutationVariables = Exact<{
  input: CreateEligibilityTestInput;
}>;

export type CreateEligibilityTestMutation = {
  __typename?: 'Mutation';
  createEligibilityTest: {
    __typename?: 'CreateEligibilityTestPayload';
    eligibilityTest?: { __typename?: 'EligibilityTest'; id: any } | null;
  };
};

export type UpdateEligibilityTestMutationVariables = Exact<{
  input: UpdateEligibilityTestInput;
}>;

export type UpdateEligibilityTestMutation = {
  __typename?: 'Mutation';
  updateEligibilityTest: {
    __typename?: 'UpdateEligibilityTestPayload';
    eligibilityTest?: { __typename?: 'EligibilityTest'; id: any } | null;
  };
};

export type DeleteEligibilityTestMutationVariables = Exact<{
  input: DeleteEligibilityTestInput;
}>;

export type DeleteEligibilityTestMutation = {
  __typename?: 'Mutation';
  deleteEligibilityTest: {
    __typename?: 'DeleteEligibilityTestPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type CreateConsentMutationVariables = Exact<{
  input: CreateConsentInput;
}>;

export type CreateConsentMutation = {
  __typename?: 'Mutation';
  createConsent: { __typename?: 'CreateConsentPayload'; consent?: { __typename?: 'Consent'; id: any } | null };
};

export type UpdateConsentMutationVariables = Exact<{
  input: UpdateConsentInput;
}>;

export type UpdateConsentMutation = {
  __typename?: 'Mutation';
  updateConsent: { __typename?: 'UpdateConsentPayload'; consent?: { __typename?: 'Consent'; id: any } | null };
};

export type DeleteConsentMutationVariables = Exact<{
  input: DeleteConsentInput;
}>;

export type DeleteConsentMutation = {
  __typename?: 'Mutation';
  deleteConsent: { __typename?: 'DeleteConsentPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateIseeMutationVariables = Exact<{
  input: CreateIseeInput;
}>;

export type CreateIseeMutation = {
  __typename?: 'Mutation';
  createIsee: { __typename?: 'CreateIseePayload'; isee?: { __typename?: 'Isee'; id: any } | null };
};

export type UpdateIseeMutationVariables = Exact<{
  input: UpdateIseeInput;
}>;

export type UpdateIseeMutation = {
  __typename?: 'Mutation';
  updateIsee: { __typename?: 'UpdateIseePayload'; isee?: { __typename?: 'Isee'; id: any } | null };
};

export type DeleteIseeMutationVariables = Exact<{
  input: DeleteIseeInput;
}>;

export type DeleteIseeMutation = {
  __typename?: 'Mutation';
  deleteIsee: { __typename?: 'DeleteIseePayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateProgramMutationVariables = Exact<{
  input: CreateProgramInput;
}>;

export type CreateProgramMutation = {
  __typename?: 'Mutation';
  createProgram: {
    __typename?: 'CreateProgramPayload';
    program?: { __typename?: 'Program'; id: any; code: string; type: ProgramType } | null;
  };
};

export type GetProgramsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  filter?: InputMaybe<ProgramFilterInput>;
}>;

export type GetProgramsQuery = {
  __typename?: 'Query';
  programs?: {
    __typename?: 'ProgramsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Program';
      code: string;
      id: any;
      departureInfo?: string | null;
      returnInfo?: string | null;
      destinationIOC?: string | null;
      duration?: string | null;
      endAt: any;
      isReciprocal?: boolean | null;
      language?: string | null;
      description?: string | null;
      type: ProgramType;
      startAt: any;
      status?: string | null;
      questions: Array<{ __typename?: 'ProgramQuestion'; id: any; text: string }>;
    }> | null;
  } | null;
};

export type UpdateProgramMutationVariables = Exact<{
  input: UpdateProgramInput;
}>;

export type UpdateProgramMutation = {
  __typename?: 'Mutation';
  updateProgram: {
    __typename?: 'UpdateProgramPayload';
    program?: { __typename?: 'Program'; id: any; code: string; type: ProgramType } | null;
  };
};

export type DeleteProgramMutationVariables = Exact<{
  input: DeleteProgramInput;
}>;

export type DeleteProgramMutation = {
  __typename?: 'Mutation';
  deleteProgram: { __typename?: 'DeleteProgramPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type GetProgramApplicationsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  filter?: InputMaybe<ProgramApplicationFilterInput>;
}>;

export type GetProgramApplicationsQuery = {
  __typename?: 'Query';
  programApplications?: {
    __typename?: 'ProgramApplicationsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'ProgramApplication';
      applicationId: any;
      programId: any;
      originDate?: any | null;
      lastAccessAt?: any | null;
      id: any;
      state?: ProgramApplicationState | null;
      answers: Array<{
        __typename?: 'ProgramApplicationAnswer';
        id: any;
        text?: string | null;
        documents: Array<{
          __typename?: 'Document';
          contentLength: any;
          contentType: string;
          fileName: string;
          id: any;
          uri: string;
        }>;
        question: { __typename?: 'ProgramQuestion'; id: any; text: string };
      }>;
      program: {
        __typename?: 'Program';
        code: string;
        departureInfo?: string | null;
        description?: string | null;
        destinationIOC?: string | null;
        duration?: string | null;
        endAt: any;
        id: any;
        isReciprocal?: boolean | null;
        language?: string | null;
        returnInfo?: string | null;
        startAt: any;
        type: ProgramType;
      };
      scholarshipProgramRequests: Array<{
        __typename?: 'ScholarshipProgramRequest';
        coverage: any;
        id: any;
        programApplicationId: any;
        scholarshipRequestId: any;
        scholarshipRequest: {
          __typename?: 'ScholarshipRequest';
          id: any;
          state: ScholarshipRequestState;
          scholarship: {
            __typename?: 'Scholarship';
            id: any;
            closesAtTime: any;
            doesRequireIsee: boolean;
            doesRequireOAPayment: boolean;
            closesAtDate: any;
            isActive: boolean;
            sponsor: { __typename?: 'Sponsor'; id: any; name: string };
            type: { __typename?: 'ScholarshipType'; id: any; name: string };
          };
          answers: Array<{
            __typename?: 'ScholarshipRequestAnswer';
            id: any;
            text: string;
            question: { __typename?: 'ScholarshipQuestion'; id: any; text: string };
          }>;
        };
      }>;
    }> | null;
  } | null;
};

export type CreateProgramApplicationMutationVariables = Exact<{
  input: CreateProgramApplicationInput;
}>;

export type CreateProgramApplicationMutation = {
  __typename?: 'Mutation';
  createProgramApplication: {
    __typename?: 'CreateProgramApplicationPayload';
    programApplication?: { __typename?: 'ProgramApplication'; id: any } | null;
  };
};

export type UpdateProgramApplicationMutationVariables = Exact<{
  input: UpdateProgramApplicationInput;
}>;

export type UpdateProgramApplicationMutation = {
  __typename?: 'Mutation';
  updateProgramApplication: {
    __typename?: 'UpdateProgramApplicationPayload';
    programApplication?: { __typename?: 'ProgramApplication'; id: any } | null;
  };
};

export type DeleteProgramApplicationMutationVariables = Exact<{
  input: DeleteProgramApplicationInput;
}>;

export type DeleteProgramApplicationMutation = {
  __typename?: 'Mutation';
  deleteProgramApplication: {
    __typename?: 'DeleteProgramApplicationPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type CreateScholarshipProgramRequestMutationVariables = Exact<{
  input: CreateScholarshipProgramRequestInput;
}>;

export type CreateScholarshipProgramRequestMutation = {
  __typename?: 'Mutation';
  createScholarshipProgramRequest: {
    __typename?: 'CreateScholarshipProgramRequestPayload';
    scholarshipProgramRequest?: { __typename?: 'ScholarshipProgramRequest'; id: any } | null;
  };
};

export type UpdateScholarshipProgramRequestMutationVariables = Exact<{
  input: UpdateScholarshipProgramRequestInput;
}>;

export type UpdateScholarshipProgramRequestMutation = {
  __typename?: 'Mutation';
  updateScholarshipProgramRequest: {
    __typename?: 'UpdateScholarshipProgramRequestPayload';
    scholarshipProgramRequest?: { __typename?: 'ScholarshipProgramRequest'; id: any } | null;
  };
};

export type DeleteScholarshipProgramRequestMutationVariables = Exact<{
  input: DeleteScholarshipProgramRequestInput;
}>;

export type DeleteScholarshipProgramRequestMutation = {
  __typename?: 'Mutation';
  deleteScholarshipProgramRequest: {
    __typename?: 'DeleteScholarshipProgramRequestPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type CreateScholarshipRequestMutationVariables = Exact<{
  input: CreateScholarshipRequestInput;
}>;

export type CreateScholarshipRequestMutation = {
  __typename?: 'Mutation';
  createScholarshipRequest: {
    __typename?: 'CreateScholarshipRequestPayload';
    scholarshipRequest?: { __typename?: 'ScholarshipRequest'; id: any } | null;
  };
};

export type UpdateScholarshipRequestMutationVariables = Exact<{
  input: UpdateScholarshipRequestInput;
}>;

export type UpdateScholarshipRequestMutation = {
  __typename?: 'Mutation';
  updateScholarshipRequest: {
    __typename?: 'UpdateScholarshipRequestPayload';
    scholarshipRequest?: { __typename?: 'ScholarshipRequest'; id: any } | null;
  };
};

export type DeleteScholarshipRequestMutationVariables = Exact<{
  input: DeleteScholarshipRequestInput;
}>;

export type DeleteScholarshipRequestMutation = {
  __typename?: 'Mutation';
  deleteScholarshipRequest: {
    __typename?: 'DeleteScholarshipRequestPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    id: any;
    roles: Array<string>;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    avatar?: { __typename?: 'Document'; uri: string } | null;
  } | null;
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: { __typename?: 'CreateContactPayload'; contact?: { __typename?: 'Contact'; id: any } | null };
};

export type DeleteContactMutationMutationVariables = Exact<{
  input: DeleteContactInput;
}>;

export type DeleteContactMutationMutation = {
  __typename?: 'Mutation';
  deleteContact: { __typename?: 'DeleteContactPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type GetContactsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ContactSortInput> | ContactSortInput>;
  filter?: InputMaybe<ContactFilterInput>;
}>;

export type GetContactsQuery = {
  __typename?: 'Query';
  contacts?: {
    __typename?: 'ContactsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Contact';
      originDate?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      code?: string | null;
      originId?: string | null;
      birthDate?: any | null;
      fiscalCode?: string | null;
      phoneNumber?: string | null;
      lastAccessAt?: any | null;
      email?: string | null;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      address?: {
        __typename?: 'Address';
        postalCode?: string | null;
        locality?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
        country?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetContactAliasesQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetContactAliasesQuery = {
  __typename?: 'Query';
  contact?: { __typename?: 'Contact'; aliasesIds: Array<any>; id: any; email?: string | null } | null;
};

export type GetContactQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetContactQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    id: any;
    birthDate?: any | null;
    email?: string | null;
    firstName?: string | null;
    fiscalCode?: string | null;
    gender?: string | null;
    height?: string | null;
    code?: string | null;
    isDeceased?: boolean | null;
    isEmailVerified?: boolean | null;
    originDate?: any | null;
    lastAccessAt?: any | null;
    lastName?: string | null;
    middleName?: string | null;
    originId?: string | null;
    phoneNumber?: string | null;
    pictureId?: any | null;
    weight?: string | null;
    additionalInfo?: {
      __typename?: 'AdditionalInfo';
      emails?: Array<string> | null;
      phoneNumbers?: Array<string> | null;
    } | null;
    documents: Array<{
      __typename?: 'Document';
      contentLength: any;
      contentType: string;
      fileName: string;
      uri: string;
      createdAt: any;
      id: any;
    }>;
    labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
    birthPlace?: {
      __typename?: 'Address';
      country?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      administrativeArea?: string | null;
      streetNumber?: string | null;
    } | null;
    address?: {
      __typename?: 'Address';
      country?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      administrativeArea?: string | null;
      streetNumber?: string | null;
    } | null;
    picture?: {
      __typename?: 'Document';
      id: any;
      fileName: string;
      uri: string;
      contentType: string;
      contentLength: any;
    } | null;
    relationships: Array<{
      __typename?: 'Relationship';
      id: any;
      objectContactId: any;
      subjectContactId: any;
      relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
    }>;
    houses: Array<{
      __typename?: 'House';
      id: any;
      type: HouseType;
      data?: any | null;
      name?: string | null;
      residentsIds: Array<any>;
      address: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
      };
      labels: Array<{ __typename?: 'Label'; id: any; name: string; targetType?: string | null }>;
    }>;
  } | null;
};

export type ContactReferencesQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type ContactReferencesQuery = {
  __typename?: 'Query';
  contactReferences: Array<
    | { __typename: 'LeadReference'; leadId: any }
    | { __typename: 'RelationshipReference'; targetId: any; relationshipTypeId: any }
    | { __typename: 'StudentReference'; studentId: any }
  >;
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: { __typename?: 'UpdateContactPayload'; contact?: { __typename?: 'Contact'; id: any } | null };
};

export type GetContestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ContestSortInput> | ContestSortInput>;
  filter?: InputMaybe<ContestFilterInput>;
}>;

export type GetContestsQuery = {
  __typename?: 'Query';
  contests?: {
    __typename?: 'ContestsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Contest';
      data?: any | null;
      isCurrent: boolean;
      id: any;
      name: string;
      year?: string | null;
      flowSchema?: {
        __typename?: 'FlowSchema';
        data?: any | null;
        id: any;
        name: string;
        flows?: Array<{ __typename?: 'Flow'; name: string }> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetContestQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetContestQuery = {
  __typename?: 'Query';
  contest?: {
    __typename?: 'Contest';
    previousContestId?: any | null;
    year?: string | null;
    data?: any | null;
    id: any;
    isCurrent: boolean;
    name: string;
    eligibilityTestDates: Array<{
      __typename?: 'EligibilityTestDate';
      id: any;
      date: any;
      deadLineDate: any;
      seats: number;
    }>;
    flowSchema?: {
      __typename?: 'FlowSchema';
      data?: any | null;
      id: any;
      name: string;
      flows?: Array<{ __typename?: 'Flow'; name: string }> | null;
    } | null;
  } | null;
};

export type CreateContestMutationVariables = Exact<{
  input: CreateContestInput;
}>;

export type CreateContestMutation = {
  __typename?: 'Mutation';
  createContest: { __typename?: 'CreateContestPayload'; contest?: { __typename?: 'Contest'; id: any } | null };
};

export type UpdateContestMutationVariables = Exact<{
  input: UpdateContestInput;
}>;

export type UpdateContestMutation = {
  __typename?: 'Mutation';
  updateContest: { __typename?: 'UpdateContestPayload'; contest?: { __typename?: 'Contest'; id: any } | null };
};

export type DeleteContestMutationVariables = Exact<{
  input: DeleteContestInput;
}>;

export type DeleteContestMutation = {
  __typename?: 'Mutation';
  deleteContest: { __typename?: 'DeleteContestPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateEligibilityTestDateMutationVariables = Exact<{
  input: CreateEligibilityTestDateInput;
}>;

export type CreateEligibilityTestDateMutation = {
  __typename?: 'Mutation';
  createEligibilityTestDate: {
    __typename?: 'CreateEligibilityTestDatePayload';
    eligibilityTestDate?: { __typename?: 'EligibilityTestDate'; id: any } | null;
  };
};

export type DeleteEligibilityTestDateMutationVariables = Exact<{
  input: DeleteEligibilityTestDateInput;
}>;

export type DeleteEligibilityTestDateMutation = {
  __typename?: 'Mutation';
  deleteEligibilityTestDate: {
    __typename?: 'DeleteEligibilityTestDatePayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateEligibilityTestDateMutationVariables = Exact<{
  input: UpdateEligibilityTestDateInput;
}>;

export type UpdateEligibilityTestDateMutation = {
  __typename?: 'Mutation';
  updateEligibilityTestDate: {
    __typename?: 'UpdateEligibilityTestDatePayload';
    eligibilityTestDate?: { __typename?: 'EligibilityTestDate'; id: any } | null;
  };
};

export type GetDashboardQueryVariables = Exact<{
  filters?: InputMaybe<DashboardFiltersInput>;
}>;

export type GetDashboardQuery = {
  __typename?: 'Query';
  dashboard: {
    __typename?: 'Dashboard';
    newAccountsCurrentCount: number;
    newAccountsPreviousCount: number;
    completedAccountsCurrentCount: number;
    completedAccountsPreviousCount: number;
    closedAccountsCurrentCount: number;
    closedAccountsPreviousCount: number;
    scholasticProgramCount: number;
    linguisticProgramCount: number;
    bothProgramCount: number;
    applicationsWithProgramsCount: number;
    assignedProgramCount: number;
    reserveProgramCount: number;
    pendingProgramCount: number;
    nhProgramCount: number;
    shProgramCount: number;
    departedCount: number;
    newLeadCurrentCount: number;
    newLeadPreviousCount: number;
    convertedLeadCurrentCount: number;
    convertedLeadCurrentRatio: number;
    convertedLeadPreviousRatio: number;
    convertedToStudentCurrentCount: number;
    convertedToStudentPreviousCount: number;
    completedAccountsCurrentTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    completedAccountsPreviousTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    allScholarshipsCurrentTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    allScholarshipsPreviousTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    inpsScholarshipsCurrentTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    inpsScholarshipsPreviousTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    interculturaScholarshipsCurrentTimeSeries: Array<{
      __typename?: 'TimeSeriesItemOfInt32';
      instant: any;
      value: number;
    }>;
    interculturaScholarshipsPreviousTimeSeries: Array<{
      __typename?: 'TimeSeriesItemOfInt32';
      instant: any;
      value: number;
    }>;
    partnersScholarshipsCurrentTimeSeries: Array<{ __typename?: 'TimeSeriesItemOfInt32'; instant: any; value: number }>;
    partnersScholarshipsPreviousTimeSeries: Array<{
      __typename?: 'TimeSeriesItemOfInt32';
      instant: any;
      value: number;
    }>;
    interculturaScholarshipsByCoverageCurrent: Array<{
      __typename?: 'KeyValuePairOfDecimalAndInt32';
      key: any;
      value: number;
    }>;
    interculturaScholarshipsByCoveragePrevious: Array<{
      __typename?: 'KeyValuePairOfDecimalAndInt32';
      key: any;
      value: number;
    }>;
    applicationClosedProgramSankey: Array<{
      __typename?: 'SankeyItem';
      flowName: string;
      count: number;
      exitedCount: Array<{ __typename?: 'KeyValuePairOfStringAndInt32'; key: string; value: number }>;
    }>;
    departedByDestinationCounts: Array<{ __typename?: 'CountItem'; name: string; count: number }>;
    convertedToRelationshipCurrentCounts: Array<{ __typename?: 'CountItem'; count: number; name: string }>;
    convertedToRelationshipPreviousCounts: Array<{ __typename?: 'CountItem'; count: number; name: string }>;
  };
};

export type UploadDocumentMutationVariables = Exact<{
  input: UploadDocumentInput;
}>;

export type UploadDocumentMutation = {
  __typename?: 'Mutation';
  uploadDocument: {
    __typename?: 'UploadDocumentPayload';
    document?: {
      __typename?: 'Document';
      id: any;
      uri: string;
      fileName: string;
      contentLength: any;
      contentType: string;
      createdAt: any;
      labels: Array<{ __typename?: 'Label'; name: string; id: any }>;
    } | null;
  };
};

export type GetDocumentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<DocumentSortInput> | DocumentSortInput>;
  filter?: InputMaybe<DocumentFilterInput>;
}>;

export type GetDocumentsQuery = {
  __typename?: 'Query';
  documents?: {
    __typename?: 'DocumentsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Document';
      id: any;
      contentLength: any;
      contentType: string;
      fileName: string;
      createdAt: any;
      uri: string;
      labels: Array<{ __typename?: 'Label'; name: string; id: any }>;
    }> | null;
  } | null;
};

export type GetDocumentQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetDocumentQuery = {
  __typename?: 'Query';
  document?: {
    __typename?: 'Document';
    contentLength: any;
    contentType: string;
    id: any;
    uri: string;
    fileName: string;
    labels: Array<{ __typename?: 'Label'; name: string; id: any }>;
  } | null;
};

export type DeleteDocumentMutationVariables = Exact<{
  input: DeleteDocumentInput;
}>;

export type DeleteDocumentMutation = {
  __typename?: 'Mutation';
  deleteDocument: { __typename?: 'DeleteDocumentPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateEmergencyContactMutationVariables = Exact<{
  contact: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
  studentId: Scalars['UUID']['input'];
}>;

export type CreateEmergencyContactMutation = {
  __typename?: 'Mutation';
  createEmergencyContact: {
    __typename?: 'CreateEmergencyContactPayload';
    emergencyContact?: { __typename?: 'EmergencyContact'; id: any } | null;
  };
};

export type UpdateEmergencyContactMutationVariables = Exact<{
  contact: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
}>;

export type UpdateEmergencyContactMutation = {
  __typename?: 'Mutation';
  updateEmergencyContact: {
    __typename?: 'UpdateEmergencyContactPayload';
    emergencyContact?: { __typename?: 'EmergencyContact'; id: any } | null;
  };
};

export type DeleteEmergencyContactMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteEmergencyContactMutation = {
  __typename?: 'Mutation';
  deleteEmergencyContact: {
    __typename?: 'DeleteEmergencyContactPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type FlowSchemasQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<FlowSchemaSortInput> | FlowSchemaSortInput>;
}>;

export type FlowSchemasQuery = {
  __typename?: 'Query';
  flowSchemas?: {
    __typename?: 'FlowSchemasCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'FlowSchema';
      name: string;
      id: any;
      flows?: Array<{
        __typename?: 'Flow';
        name: string;
        states: Array<{ __typename?: 'FlowState'; isCompleted: boolean; name: string }>;
      }> | null;
    }> | null;
  } | null;
};

export type GetHousesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<HouseSortInput> | HouseSortInput>;
}>;

export type GetHousesQuery = {
  __typename?: 'Query';
  houses?: {
    __typename?: 'HousesCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'House';
      id: any;
      type: HouseType;
      data?: any | null;
      name?: string | null;
      residentsIds: Array<any>;
      address: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
      };
      labels: Array<{ __typename?: 'Label'; id: any; name: string; targetType?: string | null }>;
    }> | null;
  } | null;
};

export type UpdateHouseMutationVariables = Exact<{
  input: UpdateHouseInput;
}>;

export type UpdateHouseMutation = {
  __typename?: 'Mutation';
  updateHouse: { __typename?: 'UpdateHousePayload'; house?: { __typename?: 'House'; id: any } | null };
};

export type CreateHouseMutationVariables = Exact<{
  input: CreateHouseInput;
}>;

export type CreateHouseMutation = {
  __typename?: 'Mutation';
  createHouse: { __typename?: 'CreateHousePayload'; house?: { __typename?: 'House'; id: any } | null };
};

export type DeleteHouseMutationVariables = Exact<{
  input: DeleteHouseInput;
}>;

export type DeleteHouseMutation = {
  __typename?: 'Mutation';
  deleteHouse: { __typename?: 'DeleteHousePayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type SetHouseResidentsMutationVariables = Exact<{
  input: SetHouseResidentsInput;
}>;

export type SetHouseResidentsMutation = {
  __typename?: 'Mutation';
  setHouseResidents: { __typename?: 'SetHouseResidentsPayload'; house?: { __typename?: 'House'; id: any } | null };
};

export type LabelsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<LabelSortInput> | LabelSortInput>;
  filter?: InputMaybe<LabelFilterInput>;
}>;

export type LabelsQuery = {
  __typename?: 'Query';
  labels?: {
    __typename?: 'LabelsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Label';
      id: any;
      name: string;
      data?: any | null;
      targetType?: string | null;
    }> | null;
  } | null;
};

export type CreateLabelMutationVariables = Exact<{
  input: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: 'Mutation';
  createLabel: { __typename?: 'CreateLabelPayload'; label?: { __typename?: 'Label'; id: any; name: string } | null };
};

export type UpdateLabelMutationVariables = Exact<{
  input: UpdateLabelInput;
}>;

export type UpdateLabelMutation = {
  __typename?: 'Mutation';
  updateLabel: { __typename?: 'UpdateLabelPayload'; label?: { __typename?: 'Label'; id: any } | null };
};

export type DeleteLabelMutationVariables = Exact<{
  input: DeleteLabelInput;
}>;

export type DeleteLabelMutation = {
  __typename?: 'Mutation';
  deleteLabel: { __typename?: 'DeleteLabelPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type BulkSetLabelsMutationVariables = Exact<{
  input: BulkSetLabelsInput;
}>;

export type BulkSetLabelsMutation = {
  __typename?: 'Mutation';
  bulkSetLabels: { __typename?: 'BulkSetLabelsPayload'; itemIds?: { __typename?: 'ItemIds'; ids: Array<any> } | null };
};

export type GetLeadTypesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<LeadTypeSortInput> | LeadTypeSortInput>;
}>;

export type GetLeadTypesQuery = {
  __typename?: 'Query';
  leadTypes?: {
    __typename?: 'LeadTypesCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'LeadType'; id: any; name: string }> | null;
  } | null;
};

export type GetLeadsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<LeadSortInput> | LeadSortInput>;
  filter: LeadFilterInput;
}>;

export type GetLeadsQuery = {
  __typename?: 'Query';
  leads?: {
    __typename?: 'LeadsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Lead';
      id: any;
      originDate?: any | null;
      createdBy: any;
      createdAt: any;
      lastAccessAt?: any | null;
      origin?: string | null;
      assignees: Array<{
        __typename?: 'User';
        id: any;
        isEnabled: boolean;
        roles: Array<string>;
        avatar?: { __typename?: 'Document'; uri: string; fileName: string } | null;
      }>;
      contact: {
        __typename?: 'Contact';
        code?: string | null;
        originId?: string | null;
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        createdAt: any;
        picture?: {
          __typename?: 'Document';
          id: any;
          fileName: string;
          uri: string;
          contentType: string;
          contentLength: any;
        } | null;
      };
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      type: { __typename?: 'LeadType'; id: any; name: string };
    }> | null;
  } | null;
};

export type LeadQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type LeadQuery = {
  __typename?: 'Query';
  lead?: {
    __typename?: 'Lead';
    id: any;
    assignees: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }>;
    labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null; targetType?: string | null }>;
    contact: {
      __typename?: 'Contact';
      id: any;
      aliasesIds: Array<any>;
      birthDate?: any | null;
      email?: string | null;
      firstName?: string | null;
      fiscalCode?: string | null;
      gender?: string | null;
      height?: string | null;
      code?: string | null;
      originId?: string | null;
      isDeceased?: boolean | null;
      isEmailVerified?: boolean | null;
      lastName?: string | null;
      middleName?: string | null;
      phoneNumber?: string | null;
      pictureId?: any | null;
      weight?: string | null;
      originDate?: any | null;
      lastAccessAt?: any | null;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
      birthPlace?: {
        __typename?: 'Address';
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        administrativeArea?: string | null;
        streetNumber?: string | null;
      } | null;
    };
  } | null;
};

export type CreateLeadMutationVariables = Exact<{
  typeId: Scalars['UUID']['input'];
  contactId: Scalars['UUID']['input'];
}>;

export type CreateLeadMutation = {
  __typename?: 'Mutation';
  createLead: { __typename?: 'CreateLeadPayload'; lead?: { __typename?: 'Lead'; id: any; contactId: any } | null };
};

export type DeleteLeadMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteLeadMutation = {
  __typename?: 'Mutation';
  deleteLead: { __typename?: 'DeleteLeadPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type UpdateLeadTypeMutationVariables = Exact<{
  input: UpdateLeadTypeInput;
}>;

export type UpdateLeadTypeMutation = {
  __typename?: 'Mutation';
  updateLeadType: { __typename?: 'UpdateLeadTypePayload'; leadType?: { __typename?: 'LeadType'; id: any } | null };
};

export type LocalCentersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<LocalCenterSortInput> | LocalCenterSortInput>;
  filter?: InputMaybe<LocalCenterFilterInput>;
}>;

export type LocalCentersQuery = {
  __typename?: 'Query';
  localCenters?: {
    __typename?: 'LocalCentersCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'LocalCenter';
      id: any;
      name: string;
      area?: { __typename?: 'Area'; name: string } | null;
      address?: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type LocalCentersSimplifiedQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<LocalCenterSortInput> | LocalCenterSortInput>;
  filter?: InputMaybe<LocalCenterFilterInput>;
}>;

export type LocalCentersSimplifiedQuery = {
  __typename?: 'Query';
  localCenters?: {
    __typename?: 'LocalCentersCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'LocalCenter'; id: any; name: string }> | null;
  } | null;
};

export type AreasQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<AreaSortInput> | AreaSortInput>;
  filter?: InputMaybe<AreaFilterInput>;
}>;

export type AreasQuery = {
  __typename?: 'Query';
  areas?: {
    __typename?: 'AreasCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Area'; id: any; name: string }> | null;
  } | null;
};

export type LocalCenterQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type LocalCenterQuery = {
  __typename?: 'Query';
  localCenter?: {
    __typename?: 'LocalCenter';
    id: any;
    name: string;
    updatedAt: any;
    updatedBy: any;
    address?: {
      __typename?: 'Address';
      administrativeArea?: string | null;
      country?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      streetNumber?: string | null;
    } | null;
  } | null;
};

export type MessagesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<MessageSortInput> | MessageSortInput>;
  filter?: InputMaybe<MessageFilterInput>;
}>;

export type MessagesQuery = {
  __typename?: 'Query';
  messages?: {
    __typename?: 'MessagesCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Message';
      id: any;
      createdBy: any;
      isStarred: boolean;
      text?: string | null;
      threadId: any;
      type: MessageType;
      html?: string | null;
      messageDate?: any | null;
      updatedAt: any;
      createdAt: any;
      documents: Array<{
        __typename?: 'Document';
        id: any;
        uri: string;
        fileName: string;
        contentLength: any;
        contentType: string;
      }>;
    }> | null;
  } | null;
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: { __typename?: 'CreateMessagePayload'; message?: { __typename?: 'Message'; id: any } | null };
};

export type UpdateMessageMutationVariables = Exact<{
  input: UpdateMessageInput;
}>;

export type UpdateMessageMutation = {
  __typename?: 'Mutation';
  updateMessage: { __typename?: 'UpdateMessagePayload'; message?: { __typename?: 'Message'; id: any } | null };
};

export type GetParentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ContactSortInput> | ContactSortInput>;
  filter: ContactFilterInput;
}>;

export type GetParentsQuery = {
  __typename?: 'Query';
  contacts?: {
    __typename?: 'ContactsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Contact';
      originDate?: any | null;
      createdAt: any;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      code?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      isEmailVerified?: boolean | null;
      fiscalCode?: string | null;
      birthDate?: any | null;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
      address?: { __typename?: 'Address'; locality?: string | null; postalCode?: string | null } | null;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; targetType?: string | null; data?: any | null }>;
      relationships: Array<{
        __typename?: 'Relationship';
        id: any;
        objectContactId: any;
        subjectContactId: any;
        relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
      }>;
      houses: Array<{
        __typename?: 'House';
        id: any;
        type: HouseType;
        data?: any | null;
        name?: string | null;
        residentsIds: Array<any>;
        address: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          postalCode?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
        };
      }>;
      documents: Array<{
        __typename?: 'Document';
        contentLength: any;
        contentType: string;
        fileName: string;
        uri: string;
      }>;
    }> | null;
  } | null;
};

export type GetStudentParentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ContactSortInput> | ContactSortInput>;
  filter: ContactFilterInput;
}>;

export type GetStudentParentsQuery = {
  __typename?: 'Query';
  contacts?: {
    __typename?: 'ContactsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Contact';
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      fiscalCode?: string | null;
      birthDate?: any | null;
      code?: string | null;
      houses: Array<{
        __typename?: 'House';
        id: any;
        type: HouseType;
        data?: any | null;
        name?: string | null;
        residentsIds: Array<any>;
        address: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          postalCode?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
        };
        labels: Array<{
          __typename?: 'Label';
          createdBy: any;
          id: any;
          name: string;
          data?: any | null;
          targetType?: string | null;
        }>;
      }>;
      documents: Array<{
        __typename?: 'Document';
        fileName: string;
        contentLength: any;
        contentType: string;
        createdAt: any;
        id: any;
        uri: string;
      }>;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
      labels: Array<{ __typename?: 'Label'; id: any }>;
      relationships: Array<{
        __typename?: 'Relationship';
        id: any;
        objectContactId: any;
        subjectContactId: any;
        relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
      }>;
    }> | null;
  } | null;
};

export type UpdateParentContactMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateParentContactMutation = {
  __typename?: 'Mutation';
  updateContact: {
    __typename?: 'UpdateContactPayload';
    contact?: {
      __typename?: 'Contact';
      code?: string | null;
      originId?: string | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      createdAt: any;
    } | null;
  };
};

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContact: { __typename?: 'DeleteContactPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type WorkflowInfosQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<WorkflowInfoSortInput> | WorkflowInfoSortInput>;
  filter?: InputMaybe<WorkflowInfoFilterInput>;
}>;

export type WorkflowInfosQuery = {
  __typename?: 'Query';
  workflowInfos?: {
    __typename?: 'WorkflowInfosCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'WorkflowInfo';
      createdAt: any;
      id: any;
      inputs?: any | null;
      workflowId?: string | null;
      workflowName?: string | null;
      workflowDescription?: string | null;
      effects?: Array<string> | null;
    }> | null;
  } | null;
};

export type TriggerWorkflowsMutationVariables = Exact<{
  input: TriggerWorkflowInput;
}>;

export type TriggerWorkflowsMutation = {
  __typename?: 'Mutation';
  triggerWorkflow: { __typename?: 'TriggerWorkflowPayload'; string?: string | null };
};

export type CreateRankingMutationVariables = Exact<{
  input: CreateRankingInput;
}>;

export type CreateRankingMutation = {
  __typename?: 'Mutation';
  createRanking: { __typename?: 'CreateRankingPayload'; ranking?: { __typename?: 'Ranking'; id: any } | null };
};

export type UpdateRankingMutationVariables = Exact<{
  input: UpdateRankingInput;
}>;

export type UpdateRankingMutation = {
  __typename?: 'Mutation';
  updateRanking: { __typename?: 'UpdateRankingPayload'; ranking?: { __typename?: 'Ranking'; id: any } | null };
};

export type CreateScoreMutationVariables = Exact<{
  input: CreateScoreInput;
}>;

export type CreateScoreMutation = {
  __typename?: 'Mutation';
  createScore: { __typename?: 'CreateScorePayload'; score?: { __typename?: 'Score'; id: any } | null };
};

export type UpdateScoreMutationVariables = Exact<{
  input: UpdateScoreInput;
}>;

export type UpdateScoreMutation = {
  __typename?: 'Mutation';
  updateScore: { __typename?: 'UpdateScorePayload'; score?: { __typename?: 'Score'; id: any } | null };
};

export type GetRelationTypeQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<RelationshipTypeSortInput> | RelationshipTypeSortInput>;
}>;

export type GetRelationTypeQuery = {
  __typename?: 'Query';
  relationshipTypes?: {
    __typename?: 'RelationshipTypesCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'RelationshipType'; id: any; name: string }> | null;
  } | null;
};

export type ListRelationshipsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<RelationshipSortInput> | RelationshipSortInput>;
  filter?: InputMaybe<RelationshipFilterInput>;
}>;

export type ListRelationshipsQuery = {
  __typename?: 'Query';
  relationships?: {
    __typename?: 'RelationshipsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Relationship';
      id: any;
      objectContactId: any;
      subjectContactId: any;
      relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
    }> | null;
  } | null;
};

export type CreateRelationshipMutationVariables = Exact<{
  input: CreateRelationshipInput;
}>;

export type CreateRelationshipMutation = {
  __typename?: 'Mutation';
  createRelationship: {
    __typename?: 'CreateRelationshipPayload';
    relationship?: {
      __typename?: 'Relationship';
      id: any;
      objectContactId: any;
      subjectContactId: any;
      relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
    } | null;
  };
};

export type DeleteRelationshipMutationVariables = Exact<{
  input: DeleteRelationshipInput;
}>;

export type DeleteRelationshipMutation = {
  __typename?: 'Mutation';
  deleteRelationship: {
    __typename?: 'DeleteRelationshipPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: 'Mutation';
  createRequest: { __typename?: 'CreateRequestPayload'; request?: { __typename?: 'Request'; id: any } | null };
};

export type DeleteRequestMutationVariables = Exact<{
  input: DeleteRequestInput;
}>;

export type DeleteRequestMutation = {
  __typename?: 'Mutation';
  deleteRequest: { __typename?: 'DeleteRequestPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type GetRequestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<RequestSortInput> | RequestSortInput>;
  filter?: InputMaybe<RequestFilterInput>;
}>;

export type GetRequestsQuery = {
  __typename?: 'Query';
  requests?: {
    __typename?: 'RequestsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Request';
      id: any;
      deadline?: any | null;
      items?: Array<{
        __typename?: 'RequestItem';
        data?: string | null;
        description?: string | null;
        name?: string | null;
        type: RequestItemType;
      }> | null;
      contact: {
        __typename?: 'Contact';
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        code?: string | null;
        originId?: string | null;
        email?: string | null;
        picture?: {
          __typename?: 'Document';
          id: any;
          fileName: string;
          uri: string;
          contentType: string;
          contentLength: any;
        } | null;
      };
    }> | null;
  } | null;
};

export type GetAnswersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<AnswerSortInput> | AnswerSortInput>;
  filter?: InputMaybe<AnswerFilterInput>;
}>;

export type GetAnswersQuery = {
  __typename?: 'Query';
  answers?: {
    __typename?: 'AnswersCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Answer'; id: any; requestId?: any | null; items: Array<any> }> | null;
  } | null;
};

export type GetRequestQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetRequestQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: any;
    deadline?: any | null;
    items?: Array<{
      __typename?: 'RequestItem';
      data?: string | null;
      description?: string | null;
      name?: string | null;
      type: RequestItemType;
    }> | null;
    contact: {
      __typename?: 'Contact';
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      code?: string | null;
      originId?: string | null;
      email?: string | null;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
    };
  } | null;
};

export type UpdateRequestMutationVariables = Exact<{
  input: UpdateRequestInput;
}>;

export type UpdateRequestMutation = {
  __typename?: 'Mutation';
  updateRequest: { __typename?: 'UpdateRequestPayload'; request?: Array<{ __typename?: 'Request'; id: any }> | null };
};

export type ScholarshipProgramRequestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ScholarshipProgramRequestSortInput> | ScholarshipProgramRequestSortInput>;
}>;

export type ScholarshipProgramRequestsQuery = {
  __typename?: 'Query';
  scholarshipProgramRequests?: {
    __typename?: 'ScholarshipProgramRequestsCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'ScholarshipProgramRequest'; id: any }> | null;
  } | null;
};

export type ScholarshipRequestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ScholarshipRequestSortInput> | ScholarshipRequestSortInput>;
}>;

export type ScholarshipRequestsQuery = {
  __typename?: 'Query';
  scholarshipRequests?: {
    __typename?: 'ScholarshipRequestsCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'ScholarshipRequest'; id: any }> | null;
  } | null;
};

export type GetScholarshipsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ScholarshipSortInput> | ScholarshipSortInput>;
  filter?: InputMaybe<ScholarshipFilterInput>;
}>;

export type GetScholarshipsQuery = {
  __typename?: 'Query';
  scholarships?: {
    __typename?: 'ScholarshipsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Scholarship';
      id: any;
      closesAtTime: any;
      doesRequireIsee: boolean;
      doesRequireOAPayment: boolean;
      closesAtDate: any;
      isActive: boolean;
      sponsor: { __typename?: 'Sponsor'; id: any; name: string };
      type: { __typename?: 'ScholarshipType'; id: any; name: string };
    }> | null;
  } | null;
};

export type UpdateScholarshipMutationVariables = Exact<{
  input: UpdateScholarshipInput;
}>;

export type UpdateScholarshipMutation = {
  __typename?: 'Mutation';
  updateScholarship: {
    __typename?: 'UpdateScholarshipPayload';
    scholarship?: { __typename?: 'Scholarship'; id: any } | null;
  };
};

export type GetSponsorQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetSponsorQuery = {
  __typename?: 'Query';
  sponsor?: { __typename?: 'Sponsor'; id: any; name: string } | null;
};

export type ListSchoolsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<SchoolSortInput> | SchoolSortInput>;
  filter?: InputMaybe<SchoolFilterInput>;
}>;

export type ListSchoolsQuery = {
  __typename?: 'Query';
  schools?: {
    __typename?: 'SchoolsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'School';
      id: any;
      name: string;
      email?: string | null;
      code?: string | null;
      isVerified: boolean;
      address?: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
        postalCode?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetSchoolQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetSchoolQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'School';
    id: any;
    name: string;
    email?: string | null;
    code?: string | null;
    isVerified: boolean;
    address?: {
      __typename?: 'Address';
      administrativeArea?: string | null;
      country?: string | null;
      locality?: string | null;
      streetAddress?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
    } | null;
  } | null;
};

export type UpdateSchoolMutationVariables = Exact<{
  input: UpdateSchoolInput;
}>;

export type UpdateSchoolMutation = {
  __typename?: 'Mutation';
  updateSchool: { __typename?: 'UpdateSchoolPayload'; school?: { __typename?: 'School'; id: any } | null };
};

export type CreateSchoolMutationVariables = Exact<{
  input: CreateSchoolInput;
}>;

export type CreateSchoolMutation = {
  __typename?: 'Mutation';
  createSchool: { __typename?: 'CreateSchoolPayload'; school?: { __typename?: 'School'; id: any } | null };
};

export type SponsorsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<SponsorSortInput> | SponsorSortInput>;
}>;

export type SponsorsQuery = {
  __typename?: 'Query';
  sponsors?: {
    __typename?: 'SponsorsCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Sponsor'; id: any; name: string }> | null;
  } | null;
};

export type GetStudentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<StudentSortInput> | StudentSortInput>;
  filter?: InputMaybe<StudentFilterInput>;
}>;

export type GetStudentsQuery = {
  __typename?: 'Query';
  students?: {
    __typename?: 'StudentsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Student';
      id: any;
      externalLink?: any | null;
      originDate?: any | null;
      createdAt: any;
      updatedAt: any;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      application?: {
        __typename?: 'Application';
        id: any;
        currentFlowIndex?: number | null;
        currentStateIndex?: number | null;
        isClosed?: boolean | null;
        flows?: Array<{
          __typename?: 'Flow';
          name: string;
          states: Array<{ __typename?: 'FlowState'; name: string; isCompleted: boolean }>;
        }> | null;
      } | null;
      school?: {
        __typename?: 'School';
        name: string;
        email?: string | null;
        code?: string | null;
        address?: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
          postalCode?: string | null;
        } | null;
      } | null;
      originalSchool?: {
        __typename?: 'School';
        id: any;
        isVerified: boolean;
        name: string;
        email?: string | null;
        code?: string | null;
        address?: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
          postalCode?: string | null;
        } | null;
      } | null;
      contact: {
        __typename?: 'Contact';
        id: any;
        code?: string | null;
        gender?: string | null;
        originId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        birthDate?: any | null;
        fiscalCode?: string | null;
        phoneNumber?: string | null;
        isEmailVerified?: boolean | null;
        email?: string | null;
        picture?: {
          __typename?: 'Document';
          id: any;
          fileName: string;
          uri: string;
          contentType: string;
          contentLength: any;
        } | null;
        address?: {
          __typename?: 'Address';
          postalCode?: string | null;
          locality?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
          country?: string | null;
        } | null;
        houses: Array<{ __typename?: 'House'; id: any }>;
        relationships: Array<{
          __typename?: 'Relationship';
          id: any;
          objectContactId: any;
          subjectContactId: any;
          relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
        }>;
      };
      localCenter?: {
        __typename?: 'LocalCenter';
        id: any;
        name: string;
        area?: { __typename?: 'Area'; name: string } | null;
        address?: { __typename?: 'Address'; administrativeArea?: string | null } | null;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        avatarId?: any | null;
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
      }>;
    }> | null;
  } | null;
};

export type GetStudentQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type GetStudentQuery = {
  __typename?: 'Query';
  student?: {
    __typename?: 'Student';
    id: any;
    createdAt: any;
    lastAccessAt?: any | null;
    originDate?: any | null;
    isFamilyWillingToHost?: boolean | null;
    expectationsDescription?: string | null;
    familyDescription?: string | null;
    growthDescription?: string | null;
    healthDescription?: string | null;
    personalityDescription?: string | null;
    religionDescription?: string | null;
    schoolDescription?: string | null;
    religionAttendanceFrequency?: string | null;
    willNotPartecipateToOrientationReason?: string | null;
    willPartecipateToOrientation?: boolean | null;
    willNotPartecipateToNarritornoReason?: string | null;
    willPartecipateToNarritorno?: boolean | null;
    updatedAt: any;
    doesRequireReligionServicesAccess?: boolean | null;
    documents: Array<{ __typename?: 'Document'; id: any; fileName: string; uri: string; contentLength: any }>;
    labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
    originalSchool?: {
      __typename?: 'School';
      id: any;
      isVerified: boolean;
      name: string;
      email?: string | null;
      code?: string | null;
      address?: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
        postalCode?: string | null;
      } | null;
    } | null;
    schoolReports: Array<{
      __typename?: 'SchoolReport';
      id: any;
      schoolId?: any | null;
      studentId: any;
      type: AcademicResultsType;
      year: string;
      items: Array<{
        __typename?: 'SchoolReportItem';
        grade?: string | null;
        id: any;
        schoolReportId: any;
        subject: string;
      }>;
      school?: {
        __typename?: 'School';
        name: string;
        isVerified: boolean;
        email?: string | null;
        code?: string | null;
        address?: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
          postalCode?: string | null;
        } | null;
      } | null;
    }>;
    schoolReportSummaries: Array<{
      __typename?: 'SchoolReportSummary';
      averageGrade?: string | null;
      id: any;
      schoolId?: any | null;
      studentId: any;
      type: SchoolReportType;
      year: string;
      documents: Array<{
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      }>;
      school?: {
        __typename?: 'School';
        isVerified: boolean;
        name: string;
        email?: string | null;
        code?: string | null;
        address?: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
          postalCode?: string | null;
        } | null;
      } | null;
    }>;
    application?: {
      __typename?: 'Application';
      id: any;
      currentFlowIndex?: number | null;
      studentId: any;
      code: string;
      programTypes: Array<ProgramType>;
      createdAt: any;
      currentStateIndex?: number | null;
      isClosed?: boolean | null;
      contest?: { __typename?: 'Contest'; year?: string | null } | null;
      flows?: Array<{
        __typename?: 'Flow';
        name: string;
        states: Array<{ __typename?: 'FlowState'; name: string; isCompleted: boolean }>;
      }> | null;
      iseEs: Array<{
        __typename?: 'Isee';
        id: any;
        year?: string | null;
        range?: string | null;
        amount?: string | null;
        maxScholarshipAmount?: string | null;
        documents: Array<{
          __typename?: 'Document';
          contentLength: any;
          contentType: string;
          createdBy: any;
          fileName: string;
          id: any;
          uri: string;
        }>;
      }>;
      scholarshipRequests: Array<{
        __typename?: 'ScholarshipRequest';
        scholarship: { __typename?: 'Scholarship'; sponsor: { __typename?: 'Sponsor'; name: string } };
      }>;
      programApplications: Array<{
        __typename?: 'ProgramApplication';
        id: any;
        program: { __typename?: 'Program'; code: string; type: ProgramType };
        scholarshipProgramRequests: Array<{ __typename?: 'ScholarshipProgramRequest'; id: any; coverage: any }>;
      }>;
      documents: Array<{
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      }>;
      internationalApplication?: {
        __typename?: 'InternationalApplication';
        anticipatedGraduationDate?: any | null;
        canEatDairy?: boolean | null;
        canEatFish?: boolean | null;
        canEatPoultry?: boolean | null;
        dietaryPreference?: DietaryPreference | null;
        dietaryRestrictionsDescription?: string | null;
        doesSmoke?: boolean | null;
        familyMemberHostDescription?: string | null;
        familyMemberParticipantDescription?: string | null;
        frequentAbsencesDescription?: string | null;
        hasDietaryRestrictions?: boolean | null;
        hasFamilyMemberHosted?: boolean | null;
        hasFamilyMemberParticipated?: boolean | null;
        hasMedicalRequirements?: boolean | null;
        hasMissedYearOrSemester?: boolean | null;
        hasRelationLivingAbroad?: boolean | null;
        id: any;
        isFrequentlyAbsent?: boolean | null;
        medicalRequirementsDescription?: string | null;
        missedYearOrSemesterDescription?: string | null;
        previousAbroadExperienceDescription?: string | null;
        relationLivingAbroadDescription?: string | null;
        willAbstainFromSmoking?: boolean | null;
        yearsOfPrimarySchool?: string | null;
        yearsOfSecondarySchool?: string | null;
        cannotLiveWithAnimals: Array<{
          __typename?: 'CannotLiveWithAnimal';
          name: string;
          internationalApplicationId: any;
          id: any;
          indoor: boolean;
          outdoor: boolean;
        }>;
        languageProficiencies: Array<{
          __typename?: 'LanguageProficiency';
          id: any;
          internationalApplicationId: any;
          language: string;
          listening: number;
          reading: number;
          speaking: number;
          writing: number;
          yearsOfStudy?: string | null;
          isPrimaryLanguage: boolean;
        }>;
        labels: Array<{ __typename?: 'Label'; id: any }>;
      } | null;
    } | null;
    school?: { __typename?: 'School'; isVerified: boolean; id: any; name: string } | null;
    contact: {
      __typename?: 'Contact';
      code?: string | null;
      originId?: string | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      birthDate?: any | null;
      fiscalCode?: string | null;
      phoneNumber?: string | null;
      pictureId?: any | null;
      email?: string | null;
      weight?: string | null;
      height?: string | null;
      gender?: string | null;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      additionalInfo?: {
        __typename?: 'AdditionalInfo';
        emails?: Array<string> | null;
        phoneNumbers?: Array<string> | null;
      } | null;
      houses: Array<{
        __typename?: 'House';
        id: any;
        type: HouseType;
        createdAt: any;
        createdBy: any;
        data?: any | null;
        name?: string | null;
        residentsIds: Array<any>;
        updatedAt: any;
        updatedBy: any;
        address: {
          __typename?: 'Address';
          administrativeArea?: string | null;
          country?: string | null;
          locality?: string | null;
          postalCode?: string | null;
          streetAddress?: string | null;
          streetNumber?: string | null;
        };
        labels: Array<{ __typename?: 'Label'; id: any; name: string; targetType?: string | null }>;
      }>;
      picture?: {
        __typename?: 'Document';
        id: any;
        fileName: string;
        uri: string;
        contentType: string;
        contentLength: any;
      } | null;
      relationships: Array<{
        __typename?: 'Relationship';
        id: any;
        objectContactId: any;
        subjectContactId: any;
        relationshipType: { __typename?: 'RelationshipType'; id: any; name: string };
      }>;
      address?: {
        __typename?: 'Address';
        country?: string | null;
        locality?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
        postalCode?: string | null;
        administrativeArea?: string | null;
      } | null;
      birthPlace?: {
        __typename?: 'Address';
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
        administrativeArea?: string | null;
      } | null;
    };
    localCenter?: {
      __typename?: 'LocalCenter';
      id: any;
      name: string;
      area?: { __typename?: 'Area'; name: string } | null;
      address?: {
        __typename?: 'Address';
        administrativeArea?: string | null;
        country?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        streetAddress?: string | null;
        streetNumber?: string | null;
      } | null;
    } | null;
    emergencyContacts: Array<{
      __typename?: 'EmergencyContact';
      id: any;
      contact: string;
      fullName?: string | null;
      relation?: string | null;
    }>;
    assignees: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }>;
  } | null;
};

export type CreateStudentMutationVariables = Exact<{
  contactId: Scalars['UUID']['input'];
}>;

export type CreateStudentMutation = {
  __typename?: 'Mutation';
  createStudent: { __typename?: 'CreateStudentPayload'; student?: { __typename?: 'Student'; id: any } | null };
};

export type UpdateStudentMutationVariables = Exact<{
  input: UpdateStudentInput;
}>;

export type UpdateStudentMutation = {
  __typename?: 'Mutation';
  updateStudent: { __typename?: 'UpdateStudentPayload'; student?: { __typename?: 'Student'; id: any } | null };
};

export type DeleteStudentMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteStudentMutation = {
  __typename?: 'Mutation';
  deleteStudent: { __typename?: 'DeleteStudentPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type CreateSchoolReportSummaryMutationVariables = Exact<{
  input: CreateSchoolReportSummaryInput;
}>;

export type CreateSchoolReportSummaryMutation = {
  __typename?: 'Mutation';
  createSchoolReportSummary: {
    __typename?: 'CreateSchoolReportSummaryPayload';
    schoolReportSummary?: { __typename?: 'SchoolReportSummary'; id: any } | null;
  };
};

export type DeleteSchoolReportSummaryMutationVariables = Exact<{
  input: DeleteSchoolReportSummaryInput;
}>;

export type DeleteSchoolReportSummaryMutation = {
  __typename?: 'Mutation';
  deleteSchoolReportSummary: {
    __typename?: 'DeleteSchoolReportSummaryPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateSchoolReportSummaryMutationVariables = Exact<{
  input: UpdateSchoolReportSummaryInput;
}>;

export type UpdateSchoolReportSummaryMutation = {
  __typename?: 'Mutation';
  updateSchoolReportSummary: {
    __typename?: 'UpdateSchoolReportSummaryPayload';
    schoolReportSummary?: { __typename?: 'SchoolReportSummary'; id: any } | null;
  };
};

export type CreateSchoolReportMutationVariables = Exact<{
  input: CreateSchoolReportInput;
}>;

export type CreateSchoolReportMutation = {
  __typename?: 'Mutation';
  createSchoolReport: {
    __typename?: 'CreateSchoolReportPayload';
    schoolReport?: { __typename?: 'SchoolReport'; id: any } | null;
  };
};

export type DeleteSchoolReportMutationVariables = Exact<{
  input: DeleteSchoolReportInput;
}>;

export type DeleteSchoolReportMutation = {
  __typename?: 'Mutation';
  deleteSchoolReport: {
    __typename?: 'DeleteSchoolReportPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateSchoolReportMutationVariables = Exact<{
  input: UpdateSchoolReportInput;
}>;

export type UpdateSchoolReportMutation = {
  __typename?: 'Mutation';
  updateSchoolReport: {
    __typename?: 'UpdateSchoolReportPayload';
    schoolReport?: { __typename?: 'SchoolReport'; id: any } | null;
  };
};

export type CreateSchoolReportItemMutationVariables = Exact<{
  input: CreateSchoolReportItemInput;
}>;

export type CreateSchoolReportItemMutation = {
  __typename?: 'Mutation';
  createSchoolReportItem: {
    __typename?: 'CreateSchoolReportItemPayload';
    schoolReportItem?: { __typename?: 'SchoolReportItem'; id: any } | null;
  };
};

export type DeleteSchoolReportItemMutationVariables = Exact<{
  input: DeleteSchoolReportItemInput;
}>;

export type DeleteSchoolReportItemMutation = {
  __typename?: 'Mutation';
  deleteSchoolReportItem: {
    __typename?: 'DeleteSchoolReportItemPayload';
    deleted?: { __typename?: 'Deleted'; id: any } | null;
  };
};

export type UpdateSchoolReportItemMutationVariables = Exact<{
  input: UpdateSchoolReportItemInput;
}>;

export type UpdateSchoolReportItemMutation = {
  __typename?: 'Mutation';
  updateSchoolReportItem: {
    __typename?: 'UpdateSchoolReportItemPayload';
    schoolReportItem?: { __typename?: 'SchoolReportItem'; id: any } | null;
  };
};

export type GetTasksQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<TaskSortInput> | TaskSortInput>;
  filter?: InputMaybe<TaskFilterInput>;
}>;

export type GetTasksQuery = {
  __typename?: 'Query';
  tasks?: {
    __typename?: 'TasksCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Task';
      id: any;
      targetId?: any | null;
      targetType?: string | null;
      type: TaskType;
      data?: any | null;
      state: TaskState;
      createdAt: any;
      updatedAt: any;
      createdBy: any;
      deadline?: any | null;
      text?: string | null;
      assignees: Array<{
        __typename?: 'User';
        avatarId?: any | null;
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
      }>;
      labels: Array<{ __typename?: 'Label'; id: any; name: string }>;
      contact?: {
        __typename?: 'Contact';
        firstName?: string | null;
        lastName?: string | null;
        code?: string | null;
        phoneNumber?: string | null;
        picture?: { __typename?: 'Document'; uri: string } | null;
      } | null;
      events?: {
        __typename?: 'EventsCollectionSegment';
        items?: Array<{ __typename?: 'Event'; id: any; data?: any | null; firedAt: any }> | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: { __typename?: 'CreateTaskPayload'; task?: { __typename?: 'Task'; id: any } | null };
};

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: { __typename?: 'UpdateTaskPayload'; task?: { __typename?: 'Task'; id: any } | null };
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type DeleteTaskMutation = {
  __typename?: 'Mutation';
  deleteTask: { __typename?: 'DeleteTaskPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type ThreadsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ThreadSortInput> | ThreadSortInput>;
  filter?: InputMaybe<ThreadFilterInput>;
}>;

export type ThreadsQuery = {
  __typename?: 'Query';
  threads?: {
    __typename?: 'ThreadsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Thread';
      subject?: string | null;
      targetType?: string | null;
      type: ThreadType;
      updatedAt: any;
      createdAt: any;
      isResolved: boolean;
      lastMessageDate?: any | null;
      isRead: boolean;
      id: any;
      labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null }>;
      assignees: Array<{
        __typename?: 'User';
        avatarId?: any | null;
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
      }>;
      contact?: {
        __typename?: 'Contact';
        code?: string | null;
        originId?: string | null;
        id: any;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        picture?: {
          __typename?: 'Document';
          id: any;
          fileName: string;
          uri: string;
          contentType: string;
          contentLength: any;
        } | null;
      } | null;
      messages?: {
        __typename?: 'MessagesCollectionSegment';
        items?: Array<{
          __typename?: 'Message';
          text?: string | null;
          type: MessageType;
          createdAt: any;
          createdBy: any;
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type ThreadQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type ThreadQuery = {
  __typename?: 'Query';
  thread?: {
    __typename?: 'Thread';
    subject?: string | null;
    targetType?: string | null;
    type: ThreadType;
    updatedAt: any;
    createdAt: any;
    isResolved: boolean;
    isRead: boolean;
    updatedBy: any;
    id: any;
    lastMessageDate?: any | null;
    labels: Array<{ __typename?: 'Label'; id: any; name: string; data?: any | null; targetType?: string | null }>;
    assignees: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }>;
    contact?: { __typename?: 'Contact'; mainContactId?: any | null; email?: string | null; id: any } | null;
  } | null;
};

export type CreateThreadMutationVariables = Exact<{
  input: CreateThreadInput;
}>;

export type CreateThreadMutation = {
  __typename?: 'Mutation';
  createThread: { __typename?: 'CreateThreadPayload'; thread?: { __typename?: 'Thread'; id: any } | null };
};

export type UpdateThreadMutationVariables = Exact<{
  input: UpdateThreadInput;
}>;

export type UpdateThreadMutation = {
  __typename?: 'Mutation';
  updateThread: { __typename?: 'UpdateThreadPayload'; thread?: { __typename?: 'Thread'; id: any } | null };
};

export type DeleteThreadMutationVariables = Exact<{
  input: DeleteThreadInput;
}>;

export type DeleteThreadMutation = {
  __typename?: 'Mutation';
  deleteThread: { __typename?: 'DeleteThreadPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export type GetUserQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
  filter?: InputMaybe<UserFilterInput>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UsersCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }> | null;
  } | null;
};

export type GetUserExtendedQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
  filter?: InputMaybe<UserFilterInput>;
}>;

export type GetUserExtendedQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UsersCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'User';
      avatarId?: any | null;
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      isEnabled: boolean;
      roles: Array<string>;
      email?: string | null;
      localCenterId?: any | null;
      avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
    }> | null;
  } | null;
};

export type UserQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    avatarId?: any | null;
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    avatar?: { __typename?: 'Document'; id: any; uri: string } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'CreateUserPayload'; user?: { __typename?: 'User'; id: any } | null };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'UpdateUserPayload'; user?: { __typename?: 'User'; id: any } | null };
};

export type BulkSetAssigneesMutationVariables = Exact<{
  input: BulkSetAssigneesInput;
}>;

export type BulkSetAssigneesMutation = {
  __typename?: 'Mutation';
  bulkSetAssignees: {
    __typename?: 'BulkSetAssigneesPayload';
    itemIds?: { __typename?: 'ItemIds'; ids: Array<any> } | null;
  };
};

export type GetViewsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  order?: InputMaybe<Array<ViewSortInput> | ViewSortInput>;
  filter?: InputMaybe<ViewFilterInput>;
}>;

export type GetViewsQuery = {
  __typename?: 'Query';
  views?: {
    __typename?: 'ViewsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'View';
      createdAt: any;
      createdBy: any;
      data?: any | null;
      id: any;
      isDefault: boolean;
      isPublic: boolean;
      name: string;
      type: string;
      columnOrdersView?: Array<{ __typename?: 'ColumnOrdersView'; name: string; order: ColumnOrderType }> | null;
      columnsView?: Array<{ __typename?: 'ColumnsView'; color: string; isHidden: boolean; name: string }> | null;
    }> | null;
  } | null;
};

export type CreateViewMutationVariables = Exact<{
  input: CreateViewInput;
}>;

export type CreateViewMutation = {
  __typename?: 'Mutation';
  createView: { __typename?: 'CreateViewPayload'; view?: { __typename?: 'View'; id: any } | null };
};

export type UpdateViewMutationVariables = Exact<{
  input: UpdateViewInput;
}>;

export type UpdateViewMutation = {
  __typename?: 'Mutation';
  updateView: { __typename?: 'UpdateViewPayload'; view?: { __typename?: 'View'; id: any } | null };
};

export type DeleteViewMutationVariables = Exact<{
  input: DeleteViewInput;
}>;

export type DeleteViewMutation = {
  __typename?: 'Mutation';
  deleteView: { __typename?: 'DeleteViewPayload'; deleted?: { __typename?: 'Deleted'; id: any } | null };
};

export const GetWorkflowsInfoDocument = {
  __meta__: { hash: '034834b9855e925abc5941e65ffd2426fe3088ec' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWorkflowsInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workflowInfos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'workflowId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workflowName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inputs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkflowsInfoQuery, GetWorkflowsInfoQueryVariables>;
export const TriggerWorkflowDocument = {
  __meta__: { hash: 'c4cb2d95b23b57ce044451b1b80bf7a7da6de421' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workflowInputs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workflowName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workflowName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workflowId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workflowId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'inputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'workflowInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'string' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerWorkflowMutation, TriggerWorkflowMutationVariables>;
export const CreateInternationalApplicationDocument = {
  __meta__: { hash: '3b91ebc7af60701d48b97044f473d227b840b799' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createInternationalApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateInternationalApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInternationalApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internationalApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInternationalApplicationMutation, CreateInternationalApplicationMutationVariables>;
export const DeleteInternationalApplicationDocument = {
  __meta__: { hash: '35e6e486f2fac986074abc66ba3b0ee0b1b579d0' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInternationalApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteInternationalApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInternationalApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInternationalApplicationMutation, DeleteInternationalApplicationMutationVariables>;
export const UpdateInternationalApplicationDocument = {
  __meta__: { hash: '805ef3477e57854acf936b4c0d3c0fe08f212199' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInternationalApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateInternationalApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInternationalApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internationalApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInternationalApplicationMutation, UpdateInternationalApplicationMutationVariables>;
export const InternationalApplicationDocument = {
  __meta__: { hash: '1f0e3766f60bc09d5be54d487b5662b95f23cccd' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InternationalApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internationalApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'anticipatedGraduationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEatDairy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEatFish' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEatPoultry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cannotLiveWithAnimals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indoor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outdoor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dietaryPreference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dietaryRestrictionsDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'doesSmoke' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyMemberHostDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyMemberParticipantDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequentAbsencesDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasDietaryRestrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberHosted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberParticipated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMedicalRequirements' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMissedYearOrSemester' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasRelationLivingAbroad' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFrequentlyAbsent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'medicalRequirementsDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'missedYearOrSemesterDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previousAbroadExperienceDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relationLivingAbroadDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willAbstainFromSmoking' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yearsOfPrimarySchool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yearsOfSecondarySchool' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languageProficiencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listening' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reading' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'speaking' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'writing' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearsOfStudy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryLanguage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InternationalApplicationQuery, InternationalApplicationQueryVariables>;
export const CreateCannotLiveWithAnimalDocument = {
  __meta__: { hash: '601040868d4524315f2c98801709efdb1438c567' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCannotLiveWithAnimal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCannotLiveWithAnimalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCannotLiveWithAnimal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cannotLiveWithAnimal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCannotLiveWithAnimalMutation, CreateCannotLiveWithAnimalMutationVariables>;
export const DeleteCannotLiveWithAnimalDocument = {
  __meta__: { hash: 'ea54944d4f448baa8a997a4610477e04f0ffc438' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCannotLiveWithAnimal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteCannotLiveWithAnimalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCannotLiveWithAnimal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCannotLiveWithAnimalMutation, DeleteCannotLiveWithAnimalMutationVariables>;
export const UpdateCannotLiveWithAnimalDocument = {
  __meta__: { hash: '3cd1336badf2b9c08caa8329f469052dd8552916' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCannotLiveWithAnimal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCannotLiveWithAnimalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCannotLiveWithAnimal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cannotLiveWithAnimal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCannotLiveWithAnimalMutation, UpdateCannotLiveWithAnimalMutationVariables>;
export const CreateLanguageProficiencyDocument = {
  __meta__: { hash: '351986121a4c9ede62895a0948cee9359d75aa73' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLanguageProficiency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateLanguageProficiencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLanguageProficiency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languageProficiency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLanguageProficiencyMutation, CreateLanguageProficiencyMutationVariables>;
export const DeleteLanguageProficiencyDocument = {
  __meta__: { hash: '621792b88a601809a89cbcbd769260310df98894' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLanguageProficiency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteLanguageProficiencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLanguageProficiency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLanguageProficiencyMutation, DeleteLanguageProficiencyMutationVariables>;
export const UpdateLanguageProficiencyDocument = {
  __meta__: { hash: 'eeeb701f27bbca74b845ffe97e8d86ac158b122f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLanguageProficiency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateLanguageProficiencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLanguageProficiency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languageProficiency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLanguageProficiencyMutation, UpdateLanguageProficiencyMutationVariables>;
export const GetApplicationTypesDocument = {
  __meta__: { hash: 'e8fbd4d4b58a5be00496556b596658e50078e06c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicationTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApplicationTypesQuery, GetApplicationTypesQueryVariables>;
export const GetApplicationTypesSimpleDocument = {
  __meta__: { hash: '6bc29c36f2088db490569522e427c64192e5cbed' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicationTypesSimple' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApplicationTypesSimpleQuery, GetApplicationTypesSimpleQueryVariables>;
export const CreateApplicationTypeDocument = {
  __meta__: { hash: 'bd195c31c8bb5ebe52c13769d0916133a2d4d314' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateApplicationType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateApplicationTypeMutation, CreateApplicationTypeMutationVariables>;
export const UpdateApplicationTypeDocument = {
  __meta__: { hash: '81693002e7f80ffff2852ff11dc088364d583f78' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApplicationType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateApplicationTypeMutation, UpdateApplicationTypeMutationVariables>;
export const DeleteApplicationTypeDocument = {
  __meta__: { hash: 'ff2411dd67d26ec5380360aedb262826c6d26a2f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteApplicationType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteApplicationTypeMutation, DeleteApplicationTypeMutationVariables>;
export const CreateApplicationDocument = {
  __meta__: { hash: '2ed9458e40af430e0f8e408bd789bc21ab4ced92' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const GetApplicationDocument = {
  __meta__: { hash: '03e983ab64f1c472519b114179ced47cea2b52ba' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'application' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPaidRegistrationQuota' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPaidAdvancePaymentQuota' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentFlowIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'programTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasConsent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimatedInterculturaScholarshipAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationship' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relationshipType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationshipId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'score' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'committeeScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giuntiTestScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hospitalityScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'localCenterScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scholasticScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preselectionTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ranking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sponsorId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'states' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giuntiCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giuntiPassword' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'testDateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasTakenEligibilityTest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPassedEligibilityTest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eligibilityTestOutcome' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iseEs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'range' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxScholarshipAmount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarshipRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scholarship' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'closesAtTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'doesRequireIsee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'doesRequireOAPayment' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'closesAtDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sponsor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'programApplications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'program' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'departureInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'destinationIOC' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isReciprocal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'returnInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scholarshipProgramRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarshipRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scholarship' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'closesAtTime' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'doesRequireIsee' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'doesRequireOAPayment' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'closesAtDate' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'questions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'sponsor' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'answers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'question' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'coverage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'programApplicationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scholarshipRequestId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reportText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currentFlowIndex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentStateIndex' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internationalApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'anticipatedGraduationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canEatDairy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canEatFish' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canEatPoultry' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cannotLiveWithAnimals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'indoor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'outdoor' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dietaryPreference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dietaryRestrictionsDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doesSmoke' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'familyMemberHostDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'familyMemberParticipantDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequentAbsencesDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasDietaryRestrictions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberHosted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberParticipated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMedicalRequirements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMissedYearOrSemester' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRelationLivingAbroad' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFrequentlyAbsent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'medicalRequirementsDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'missedYearOrSemesterDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousAbroadExperienceDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationLivingAbroadDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'willAbstainFromSmoking' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearsOfPrimarySchool' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'yearsOfSecondarySchool' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageProficiencies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'listening' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reading' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'speaking' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'yearsOfStudy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryLanguage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApplicationQuery, GetApplicationQueryVariables>;
export const GetApplicationsDocument = {
  __meta__: { hash: '0ea495f3b855604bd6d0d883674e365a953a35be' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'consents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAccepted' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedInterculturaScholarshipAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fileName' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iseEs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxScholarshipAmount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ranking' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sponsorId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'score' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'committeeScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giuntiTestScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hospitalityScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'localCenterScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scholasticScore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preselectionTotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'programApplications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarshipProgramRequests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'coverage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scholarshipRequest' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'scholarship' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sponsor' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'program' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'destinationIOC' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibilityTest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'eligibilityTestDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giuntiCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giuntiPassword' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testDateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasTakenEligibilityTest' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPassedEligibilityTest' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eligibilityTestOutcome' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internationalApplication' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'languageProficiencies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'language' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPaidRegistrationQuota' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPaidAdvancePaymentQuota' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scholarshipRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarship' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sponsor' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programTypes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentFlowIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStateIndex' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'states' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expectationsDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'familyDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'growthDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'healthDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'personalityDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'religionDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'schoolDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFamilyWillingToHost' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localCenter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'area' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'schoolId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'school' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schoolReportSummaries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'averageGrade' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contact' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'picture' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'relationships' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'relationshipType' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApplicationsQuery, GetApplicationsQueryVariables>;
export const UpdateApplicationDocument = {
  __meta__: { hash: 'f5aa78d2bf47b5d379ee10713390d476934cea77' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const DeleteApplicationDocument = {
  __meta__: { hash: 'e2c15311c5e6acad2e308479542e2cfb11b989d5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteApplicationMutation, DeleteApplicationMutationVariables>;
export const GetEligibilityTestDatesDocument = {
  __meta__: { hash: '3ac00bc68afe22e8b2c66912d0cf5e55219626f5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEligibilityTestDates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EligibilityTestDateFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EligibilityTestDateSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityTestDates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availableSeats' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEligibilityTestDatesQuery, GetEligibilityTestDatesQueryVariables>;
export const GetEligibilityTestDateDocument = {
  __meta__: { hash: 'b134920898e6eb7499a291e1ebb2471f08fb9cb5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEligibilityTestDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eligibilityTestDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEligibilityTestDateQuery, GetEligibilityTestDateQueryVariables>;
export const CreateEligibilityTestDocument = {
  __meta__: { hash: 'fd3adf78c38150856c29c9414633084651259362' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEligibilityTest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEligibilityTestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEligibilityTest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEligibilityTestMutation, CreateEligibilityTestMutationVariables>;
export const UpdateEligibilityTestDocument = {
  __meta__: { hash: 'a3886a88f4ca66017c66dd505d94d400a758ea05' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEligibilityTest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEligibilityTestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEligibilityTest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEligibilityTestMutation, UpdateEligibilityTestMutationVariables>;
export const DeleteEligibilityTestDocument = {
  __meta__: { hash: '94da7f2e572882a1f912bd9c27229b29fe75914b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEligibilityTest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteEligibilityTestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEligibilityTest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEligibilityTestMutation, DeleteEligibilityTestMutationVariables>;
export const CreateConsentDocument = {
  __meta__: { hash: '5866f548e751b3b6b128ca5d4bae05024ab499fc' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateConsent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateConsentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateConsentMutation, CreateConsentMutationVariables>;
export const UpdateConsentDocument = {
  __meta__: { hash: 'df668c79e47749dc8bae0bfbc24ff04c1c106a43' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateConsent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateConsentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateConsentMutation, UpdateConsentMutationVariables>;
export const DeleteConsentDocument = {
  __meta__: { hash: '470373689e972163dab83deff09fd703f9e4ab6c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteConsent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteConsentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteConsentMutation, DeleteConsentMutationVariables>;
export const CreateIseeDocument = {
  __meta__: { hash: 'e4d4f8946f2a0256fb417b15d010109ffeede7fd' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIsee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateIseeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIsee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIseeMutation, CreateIseeMutationVariables>;
export const UpdateIseeDocument = {
  __meta__: { hash: 'bf0ba2139c494c5da90dd58bb503ba8878af8cc8' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateIsee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateIseeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIsee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIseeMutation, UpdateIseeMutationVariables>;
export const DeleteIseeDocument = {
  __meta__: { hash: 'ad0fbeeed07f7a21cad619da49cc5d7276c8a818' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIsee' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteIseeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIsee' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIseeMutation, DeleteIseeMutationVariables>;
export const CreateProgramDocument = {
  __meta__: { hash: 'c56f2dfa33ae5c744c4abd450c9899d62eea9814' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProgramInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'program' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProgramMutation, CreateProgramMutationVariables>;
export const GetProgramsDocument = {
  __meta__: { hash: '57c25f6629f39679490a9880816427e4baab9b2c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrograms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProgramFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'programs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'departureInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'destinationIOC' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isReciprocal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProgramsQuery, GetProgramsQueryVariables>;
export const UpdateProgramDocument = {
  __meta__: { hash: '1b063a2fba0c6e8cd4c0a097dfe3feca7dee253e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProgramInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'program' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const DeleteProgramDocument = {
  __meta__: { hash: 'bc569c5554c275bb7e6b6840e81559b060ef78bc' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProgram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteProgramInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProgram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const GetProgramApplicationsDocument = {
  __meta__: { hash: '596e9cff6a21c3b1e4430d6a3395d6bd971ffc05' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProgramApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProgramApplicationFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'programApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'program' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'departureInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'destinationIOC' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isReciprocal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'returnInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scholarshipProgramRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarshipRequest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scholarship' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'closesAtTime' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'doesRequireIsee' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'doesRequireOAPayment' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'closesAtDate' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'sponsor' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'answers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'question' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'coverage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'programApplicationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scholarshipRequestId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProgramApplicationsQuery, GetProgramApplicationsQueryVariables>;
export const CreateProgramApplicationDocument = {
  __meta__: { hash: '2fb97df4d347dec650cfbffb3b817399a16630b2' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProgramApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProgramApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProgramApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'programApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProgramApplicationMutation, CreateProgramApplicationMutationVariables>;
export const UpdateProgramApplicationDocument = {
  __meta__: { hash: '29547e59e27d9e585d62d9325d622ab78c07a6af' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProgramApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProgramApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProgramApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'programApplication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProgramApplicationMutation, UpdateProgramApplicationMutationVariables>;
export const DeleteProgramApplicationDocument = {
  __meta__: { hash: '48c1607fb3ac611c507bff20570eccd0d639f076' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProgramApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteProgramApplicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProgramApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProgramApplicationMutation, DeleteProgramApplicationMutationVariables>;
export const CreateScholarshipProgramRequestDocument = {
  __meta__: { hash: '7f5632318a4529129efa0776987aeb7d50d4f85f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScholarshipProgramRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateScholarshipProgramRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScholarshipProgramRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarshipProgramRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateScholarshipProgramRequestMutation, CreateScholarshipProgramRequestMutationVariables>;
export const UpdateScholarshipProgramRequestDocument = {
  __meta__: { hash: '357a6d09502a54ae18d5dd6e93be62f1c1d7ad4b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateScholarshipProgramRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateScholarshipProgramRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScholarshipProgramRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarshipProgramRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateScholarshipProgramRequestMutation, UpdateScholarshipProgramRequestMutationVariables>;
export const DeleteScholarshipProgramRequestDocument = {
  __meta__: { hash: 'ef5a315f4d156b574e65285798ace2aff2cc912c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteScholarshipProgramRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteScholarshipProgramRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteScholarshipProgramRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteScholarshipProgramRequestMutation, DeleteScholarshipProgramRequestMutationVariables>;
export const CreateScholarshipRequestDocument = {
  __meta__: { hash: '585fa9d7b8a2010c1fbebd8ee0ef383543aa4e98' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScholarshipRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateScholarshipRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScholarshipRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarshipRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateScholarshipRequestMutation, CreateScholarshipRequestMutationVariables>;
export const UpdateScholarshipRequestDocument = {
  __meta__: { hash: 'f34232296986854a08bbe063a41e0f539511cfd2' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateScholarshipRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateScholarshipRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScholarshipRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarshipRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateScholarshipRequestMutation, UpdateScholarshipRequestMutationVariables>;
export const DeleteScholarshipRequestDocument = {
  __meta__: { hash: '670ca078871317f6bf764d36a19a1c34f29ec0cb' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteScholarshipRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteScholarshipRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteScholarshipRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteScholarshipRequestMutation, DeleteScholarshipRequestMutationVariables>;
export const MeDocument = {
  __meta__: { hash: 'ee45e2672aa6ba7deb754ef5575a4dffab740d04' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const CreateContactDocument = {
  __meta__: { hash: '1e8802f109525aa20d4f44b23d8e743525d386bd' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateContactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateContactMutation, CreateContactMutationVariables>;
export const DeleteContactMutationDocument = {
  __meta__: { hash: '57ae541a8951925a83cdbab5d1b4ebf1d4b3cd25' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContactMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteContactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContactMutationMutation, DeleteContactMutationMutationVariables>;
export const GetContactsDocument = {
  __meta__: { hash: 'fe6b025cd9e4c8396d966cf447ec8e2aeab0e86a' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactsQuery, GetContactsQueryVariables>;
export const GetContactAliasesDocument = {
  __meta__: { hash: '2d90ab6396715840f22387f2077423dfd2ff88bd' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactAliases' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aliasesIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactAliasesQuery, GetContactAliasesQueryVariables>;
export const GetContactDocument = {
  __meta__: { hash: '9ed8edc1e7a87ffc45617a102ca511594ff20893' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeceased' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumbers' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pictureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'birthPlace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'picture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationships' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'houses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'residentsIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactQuery, GetContactQueryVariables>;
export const ContactReferencesDocument = {
  __meta__: { hash: '0e90c2163746c6cb54da52daa36cf70b5dab62f2' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactReferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StudentReference' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RelationshipReference' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationshipTypeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LeadReference' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactReferencesQuery, ContactReferencesQueryVariables>;
export const UpdateContactDocument = {
  __meta__: { hash: '6841febe583db4e4b84fcb9d8248e8da03ae60ff' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateContactInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateContactMutation, UpdateContactMutationVariables>;
export const GetContestsDocument = {
  __meta__: { hash: '9160b9dc90ff3406aa0ee8f0688488904041e3ed' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContestSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContestFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCurrent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flowSchema' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flows' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContestsQuery, GetContestsQueryVariables>;
export const GetContestDocument = {
  __meta__: { hash: '5082f4ebbc1beff9aef00886b3935cd4c2950d46' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTestDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deadLineDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'previousContestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flowSchema' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCurrent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContestQuery, GetContestQueryVariables>;
export const CreateContestDocument = {
  __meta__: { hash: 'b18a5329a8ebf970d876aa30464d106e2256c225' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateContestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateContestMutation, CreateContestMutationVariables>;
export const UpdateContestDocument = {
  __meta__: { hash: '7156eacaa542c725671acbf2d0ab8b11ea5352fc' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateContestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateContestMutation, UpdateContestMutationVariables>;
export const DeleteContestDocument = {
  __meta__: { hash: '686fee755015b224056d1f3b6107d995d8b5aff9' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteContestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContestMutation, DeleteContestMutationVariables>;
export const CreateEligibilityTestDateDocument = {
  __meta__: { hash: '3c267f09ca30d5707dc432b8a528177362b4be2f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEligibilityTestDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEligibilityTestDateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEligibilityTestDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTestDate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEligibilityTestDateMutation, CreateEligibilityTestDateMutationVariables>;
export const DeleteEligibilityTestDateDocument = {
  __meta__: { hash: '9c7652603136329882d893b82a211f060bd29d44' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEligibilityTestDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteEligibilityTestDateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEligibilityTestDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEligibilityTestDateMutation, DeleteEligibilityTestDateMutationVariables>;
export const UpdateEligibilityTestDateDocument = {
  __meta__: { hash: '02a98baacf40b420043665baad83cca3e7e4ab29' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEligibilityTestDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEligibilityTestDateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEligibilityTestDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibilityTestDate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEligibilityTestDateMutation, UpdateEligibilityTestDateMutationVariables>;
export const GetDashboardDocument = {
  __meta__: { hash: 'f48388a54bc1893a66c06a008e72ab921239857e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DashboardFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'newAccountsCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newAccountsPreviousCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAccountsCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAccountsPreviousCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedAccountsCurrentTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedAccountsPreviousTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'closedAccountsCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closedAccountsPreviousCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allScholarshipsCurrentTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allScholarshipsPreviousTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inpsScholarshipsCurrentTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inpsScholarshipsPreviousTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interculturaScholarshipsCurrentTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interculturaScholarshipsPreviousTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnersScholarshipsCurrentTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partnersScholarshipsPreviousTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interculturaScholarshipsByCoverageCurrent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interculturaScholarshipsByCoveragePrevious' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationClosedProgramSankey' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'flowName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exitedCount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scholasticProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linguisticProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bothProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationsWithProgramsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assignedProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reserveProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pendingProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nhProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shProgramCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'departedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departedByDestinationCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newLeadCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newLeadPreviousCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'convertedLeadCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'convertedLeadCurrentRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'convertedLeadPreviousRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'convertedToStudentCurrentCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'convertedToStudentPreviousCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'convertedToRelationshipCurrentCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'convertedToRelationshipPreviousCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardQuery, GetDashboardQueryVariables>;
export const UploadDocumentDocument = {
  __meta__: { hash: '15fdbb6cbdac1daba14444589dbda1a5d5d4075d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const GetDocumentsDocument = {
  __meta__: { hash: 'ea31003d61fe39f2c10d462a9a9f30be4ab81776' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const GetDocumentDocument = {
  __meta__: { hash: '16599210cbcc942b7bc5c864e9e33f7bad1b3a03' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDocumentQuery, GetDocumentQueryVariables>;
export const DeleteDocumentDocument = {
  __meta__: { hash: '184b5bc06f5a9270881d39c285b6eb92323c09b6' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const CreateEmergencyContactDocument = {
  __meta__: { hash: '6ef9ff612899de06809e89b4fde0a07eb74a614b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEmergencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'relation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEmergencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contact' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fullName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fullName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'relation' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'relation' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'studentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'studentId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emergencyContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEmergencyContactMutation, CreateEmergencyContactMutationVariables>;
export const UpdateEmergencyContactDocument = {
  __meta__: { hash: '610b09b60d9af57fb69a2e85bce09d64cc6f840d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEmergencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'relation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmergencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contact' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contact' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fullName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fullName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'relation' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'relation' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emergencyContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmergencyContactMutation, UpdateEmergencyContactMutationVariables>;
export const DeleteEmergencyContactDocument = {
  __meta__: { hash: 'adad3a8b4ec82bf53f1d07e56b4eaf618b631ef8' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEmergencyContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEmergencyContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEmergencyContactMutation, DeleteEmergencyContactMutationVariables>;
export const FlowSchemasDocument = {
  __meta__: { hash: '1340ee6ee0dc3676c9691c1f544b79e2519f6f6d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'flowSchemas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'FlowSchemaSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flowSchemas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'states' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlowSchemasQuery, FlowSchemasQueryVariables>;
export const GetHousesDocument = {
  __meta__: { hash: 'e22f1195266b7a6af7391bc5c21185d46a2604a9' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHouses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseSortInput' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'houses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'residentsIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHousesQuery, GetHousesQueryVariables>;
export const UpdateHouseDocument = {
  __meta__: { hash: '32d9549c118e9c00f24d1b9a3acf3fe74f2157da' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateHouseInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'house' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateHouseMutation, UpdateHouseMutationVariables>;
export const CreateHouseDocument = {
  __meta__: { hash: 'b1bd21203fd56aa3b17a7ebcd69cb7c410d25c96' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateHouseInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'house' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHouseMutation, CreateHouseMutationVariables>;
export const DeleteHouseDocument = {
  __meta__: { hash: 'c630ab3fb5c87dbba4683bb67bb97af2cb32ed2a' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteHouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteHouseInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteHouseMutation, DeleteHouseMutationVariables>;
export const SetHouseResidentsDocument = {
  __meta__: { hash: 'a9c4ccc62ea7cc46633900f46a45d5596c8b0279' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setHouseResidents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetHouseResidentsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setHouseResidents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'house' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetHouseResidentsMutation, SetHouseResidentsMutationVariables>;
export const LabelsDocument = {
  __meta__: { hash: '951dd58c0bbb9284774828fa2c269e8a4ce95a4b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'labels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LabelSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LabelFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelsQuery, LabelsQueryVariables>;
export const CreateLabelDocument = {
  __meta__: { hash: '23dce428092fc1ada4e6093e09b7890a184897ed' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateLabelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLabelMutation, CreateLabelMutationVariables>;
export const UpdateLabelDocument = {
  __meta__: { hash: 'b2ff09a000f0d951f618084da4300681a3b563d7' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateLabelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const DeleteLabelDocument = {
  __meta__: { hash: '53393320676286f44c15d49f67d10684513549bb' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLabel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteLabelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLabelMutation, DeleteLabelMutationVariables>;
export const BulkSetLabelsDocument = {
  __meta__: { hash: 'b7cb58f174fc76b8920dc5bda65dec1454c8fd3f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkSetLabels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkSetLabelsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkSetLabels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemIds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ids' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkSetLabelsMutation, BulkSetLabelsMutationVariables>;
export const GetLeadTypesDocument = {
  __meta__: { hash: 'b8b7b4148cd6fbd4641015014ab758f14f5f22d5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLeadTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'LeadTypeSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLeadTypesQuery, GetLeadTypesQueryVariables>;
export const GetLeadsDocument = {
  __meta__: { hash: '014b32b1847682e11ea83bf158b2ce818e112863' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLeads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LeadSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LeadFilterInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLeadsQuery, GetLeadsQueryVariables>;
export const LeadDocument = {
  __meta__: { hash: 'bbbfadc38b9433a21f3dbf569de09d7f76c76496' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'lead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aliasesIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDeceased' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'middleName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pictureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthPlace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadQuery, LeadQueryVariables>;
export const CreateLeadDocument = {
  __meta__: { hash: '7a683b3523c1b96688b833f1e265891d7979ed73' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'typeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'typeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'typeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contactId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lead' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLeadMutation, CreateLeadMutationVariables>;
export const DeleteLeadDocument = {
  __meta__: { hash: '798038b132d10f3e9a8adc9fc38228b7cc8db176' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLeadMutation, DeleteLeadMutationVariables>;
export const UpdateLeadTypeDocument = {
  __meta__: { hash: '9996b42c398aa1a4e6f193e59b10e3eca2439561' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLeadType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateLeadTypeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLeadType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLeadTypeMutation, UpdateLeadTypeMutationVariables>;
export const LocalCentersDocument = {
  __meta__: { hash: '85715f91127ec189e184e86e21629205f0154384' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'localCenters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocalCenterSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocalCenterFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localCenters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'area' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalCentersQuery, LocalCentersQueryVariables>;
export const LocalCentersSimplifiedDocument = {
  __meta__: { hash: '9395b9e4010128b4044bc327226750f214939464' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'localCentersSimplified' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocalCenterSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocalCenterFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localCenters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalCentersSimplifiedQuery, LocalCentersSimplifiedQueryVariables>;
export const AreasDocument = {
  __meta__: { hash: 'ddf3df447398c46e2872908fdbfbe15d29289d0e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'areas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AreaFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AreasQuery, AreasQueryVariables>;
export const LocalCenterDocument = {
  __meta__: { hash: '4c78b1b0509bab3a5a962370c14e0f9e0c689818' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'localCenter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localCenter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalCenterQuery, LocalCenterQueryVariables>;
export const MessagesDocument = {
  __meta__: { hash: '69e6ca65ca9649aedda8f8823de0368337a1f156' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStarred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'messageDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesQuery, MessagesQueryVariables>;
export const CreateMessageDocument = {
  __meta__: { hash: '76f518e3a474ef591eb66217dd23601be1e22d53' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMessageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageMutation, CreateMessageMutationVariables>;
export const UpdateMessageDocument = {
  __meta__: { hash: '720d10d9f6ef6a2a7bebeba86ce1272a6d226ee6' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMessageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMessageMutation, UpdateMessageMutationVariables>;
export const GetParentsDocument = {
  __meta__: { hash: '349649a0b7bc21b884e1ab71805d8ef2edd61219' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetParents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationships' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relationshipType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'houses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'residentsIds' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetParentsQuery, GetParentsQueryVariables>;
export const GetStudentParentsDocument = {
  __meta__: { hash: 'da03e5de640ce0579a5fc3d8a14312fb8556da1e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStudentParents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'houses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'residentsIds' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationships' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relationshipType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudentParentsQuery, GetStudentParentsQueryVariables>;
export const UpdateParentContactDocument = {
  __meta__: { hash: '03379dd16c64fa87c67a612183dc3eb944f01e1d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParentContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateParentContactMutation, UpdateParentContactMutationVariables>;
export const DeleteContactDocument = {
  __meta__: { hash: 'ca24cb5594a2c768609db7a2239a48adaaeeec1e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContactMutation, DeleteContactMutationVariables>;
export const WorkflowInfosDocument = {
  __meta__: { hash: '739ab6b917eaeb8603a52ae2df49a7c43bcbd01d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'workflowInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkflowInfoSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkflowInfoFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workflowInfos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inputs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workflowId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workflowName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workflowDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'effects' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkflowInfosQuery, WorkflowInfosQueryVariables>;
export const TriggerWorkflowsDocument = {
  __meta__: { hash: '6cd5a7bd28b2bdfc4672092450634db515cf864a' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerWorkflows' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerWorkflowInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'string' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerWorkflowsMutation, TriggerWorkflowsMutationVariables>;
export const CreateRankingDocument = {
  __meta__: { hash: '1a9a132c785b379130a545c1bb9723920b673e18' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRanking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRankingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRanking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ranking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRankingMutation, CreateRankingMutationVariables>;
export const UpdateRankingDocument = {
  __meta__: { hash: '1c902fafc4e9c2d9d3b16e89c960fa569da7a8d3' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRanking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRankingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRanking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ranking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRankingMutation, UpdateRankingMutationVariables>;
export const CreateScoreDocument = {
  __meta__: { hash: '02c6873933b467dca74a78085ae0fa694594a3b3' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateScoreInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'score' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateScoreMutation, CreateScoreMutationVariables>;
export const UpdateScoreDocument = {
  __meta__: { hash: '77c3c1ec3be0ccfd145e3f1e93c2dab4b9ac94fb' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateScoreInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'score' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateScoreMutation, UpdateScoreMutationVariables>;
export const GetRelationTypeDocument = {
  __meta__: { hash: '08b72a112fc45b96e7ed424a121ebf7c75b05bf5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRelationType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'RelationshipTypeSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relationshipTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRelationTypeQuery, GetRelationTypeQueryVariables>;
export const ListRelationshipsDocument = {
  __meta__: { hash: 'ee61bc351158587d5fd6143e9295db5f8786b8a9' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListRelationships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'RelationshipSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RelationshipFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relationships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListRelationshipsQuery, ListRelationshipsQueryVariables>;
export const CreateRelationshipDocument = {
  __meta__: { hash: 'cf258d08c3788c6f5f3fef34ff1b156fc3ff20ec' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRelationship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRelationshipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRelationship' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationshipType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRelationshipMutation, CreateRelationshipMutationVariables>;
export const DeleteRelationshipDocument = {
  __meta__: { hash: '7b79d1ab41992697260a1005c7d88c1bf208577e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRelationship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteRelationshipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRelationship' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRelationshipMutation, DeleteRelationshipMutationVariables>;
export const CreateRequestDocument = {
  __meta__: { hash: '16a48f80d2c0215d99bc3179835d3fc458227418' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteRequestDocument = {
  __meta__: { hash: '14caf3e61871afdc1811b61400f67c04b781687f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const GetRequestsDocument = {
  __meta__: { hash: '43a2968fe43dcccb4e12a1a22ca692b539305a54' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRequestsQuery, GetRequestsQueryVariables>;
export const GetAnswersDocument = {
  __meta__: { hash: '1097128e9ec44cdf1346eec6fcf311b659f1fcde' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnswerSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnswerFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAnswersQuery, GetAnswersQueryVariables>;
export const GetRequestDocument = {
  __meta__: { hash: '7ce7e4016cab21db3a08e610dc353b8ae2822b41' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRequestQuery, GetRequestQueryVariables>;
export const UpdateRequestDocument = {
  __meta__: { hash: 'd3e893c74db5178684bdae222aeca36ccf3ebcee' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRequestMutation, UpdateRequestMutationVariables>;
export const ScholarshipProgramRequestsDocument = {
  __meta__: { hash: '63c287df532543e26f796a66a5b71a838b9268a5' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'scholarshipProgramRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScholarshipProgramRequestSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scholarshipProgramRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScholarshipProgramRequestsQuery, ScholarshipProgramRequestsQueryVariables>;
export const ScholarshipRequestsDocument = {
  __meta__: { hash: '1b152dbd87cc721e6ee7450f6b87735dac57c629' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'scholarshipRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScholarshipRequestSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scholarshipRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScholarshipRequestsQuery, ScholarshipRequestsQueryVariables>;
export const GetScholarshipsDocument = {
  __meta__: { hash: '3a98c68b10a2b4e571ab3f620ad34b7010ee967b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScholarships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScholarshipSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScholarshipFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scholarships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closesAtTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doesRequireIsee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doesRequireOAPayment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closesAtDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sponsor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetScholarshipsQuery, GetScholarshipsQueryVariables>;
export const UpdateScholarshipDocument = {
  __meta__: { hash: '08be6fdf201d1e38c6ccbcbe8816dd1c0e18793c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateScholarship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateScholarshipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScholarship' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scholarship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateScholarshipMutation, UpdateScholarshipMutationVariables>;
export const GetSponsorDocument = {
  __meta__: { hash: 'cdb1ae2a2bd41fa39a5b1fdd3466864e38c50388' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSponsorQuery, GetSponsorQueryVariables>;
export const ListSchoolsDocument = {
  __meta__: { hash: '8ab94bef85b36523ba882fe73626a8fb3a139ab0' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listSchools' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SchoolSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SchoolFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'schools' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListSchoolsQuery, ListSchoolsQueryVariables>;
export const GetSchoolDocument = {
  __meta__: { hash: 'cbeaabac50e1393f12381166b41da5b767bdb5f1' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSchool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSchoolQuery, GetSchoolQueryVariables>;
export const UpdateSchoolDocument = {
  __meta__: { hash: '791a90157033023ff0a57ec4aac700f9dd9b0b95' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSchool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSchoolInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSchool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const CreateSchoolDocument = {
  __meta__: { hash: '84e356acb28a66c1bc69961351e720f73b49b24c' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSchool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSchoolInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const SponsorsDocument = {
  __meta__: { hash: 'f29653cacac4a964907f5148eac06d23bb52bc0b' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sponsors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SponsorSortInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SponsorsQuery, SponsorsQueryVariables>;
export const GetStudentsDocument = {
  __meta__: { hash: '68fd493e076f1577034f112a05a6c6bbdee03e52' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'StudentSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'StudentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'students' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'application' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentFlowIndex' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentStateIndex' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flows' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'states' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'school' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalSchool' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'houses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relationships' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'relationshipType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localCenter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'area' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudentsQuery, GetStudentsQueryVariables>;
export const GetStudentDocument = {
  __meta__: { hash: '1c262e96fa8045c4fd4b611578284ed7f8a35854' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastAccessAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFamilyWillingToHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expectationsDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'growthDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'healthDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalityDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'religionDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'religionAttendanceFrequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willNotPartecipateToOrientationReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willPartecipateToOrientation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willNotPartecipateToNarritornoReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willPartecipateToNarritorno' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalSchool' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'grade' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'schoolReportId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'schoolId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'school' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReportSummaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'averageGrade' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'school' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'schoolId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expectationsDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'growthDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'healthDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalityDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willNotPartecipateToOrientationReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willPartecipateToOrientation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFamilyWillingToHost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentFlowIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'programTypes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentStateIndex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flows' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'states' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iseEs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'range' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxScholarshipAmount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'documents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scholarshipRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarship' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sponsor' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'programApplications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'program' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scholarshipProgramRequests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'coverage' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internationalApplication' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'anticipatedGraduationDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEatDairy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEatFish' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canEatPoultry' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cannotLiveWithAnimals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'indoor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'outdoor' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'languageProficiencies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'internationalApplicationId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'listening' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'reading' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'speaking' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'writing' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'yearsOfStudy' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryLanguage' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'dietaryPreference' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dietaryRestrictionsDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'doesSmoke' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'familyMemberHostDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'familyMemberParticipantDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequentAbsencesDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasDietaryRestrictions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberHosted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasFamilyMemberParticipated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasMedicalRequirements' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasMissedYearOrSemester' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasRelationLivingAbroad' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFrequentlyAbsent' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'medicalRequirementsDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'missedYearOrSemesterDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'previousAbroadExperienceDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'relationLivingAbroadDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'willAbstainFromSmoking' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'yearsOfPrimarySchool' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'yearsOfSecondarySchool' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'doesRequireReligionServicesAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'religionAttendanceFrequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'religionDescription' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fiscalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumbers' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'houses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'residentsIds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'pictureId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationships' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'objectContactId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectContactId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'relationshipType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthPlace' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localCenter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'area' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'administrativeArea' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emergencyContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudentQuery, GetStudentQueryVariables>;
export const CreateStudentDocument = {
  __meta__: { hash: '9d4d34e75ca298f41402a22a4cd0af4768641e65' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStudent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStudent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contactId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStudentMutation, CreateStudentMutationVariables>;
export const UpdateStudentDocument = {
  __meta__: { hash: 'ad8ff1b21e86b47e83c6ab93ab61e8112b3ed307' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStudent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateStudentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStudent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const DeleteStudentDocument = {
  __meta__: { hash: 'c6595aeffbda4372aa1b8ac3ab0895a9c1400241' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStudent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStudent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const CreateSchoolReportSummaryDocument = {
  __meta__: { hash: 'dc8f4a1bfd264a1d3cfb02e11d1a94703a235729' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSchoolReportSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSchoolReportSummaryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchoolReportSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReportSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSchoolReportSummaryMutation, CreateSchoolReportSummaryMutationVariables>;
export const DeleteSchoolReportSummaryDocument = {
  __meta__: { hash: 'df102c3c90012d1767e58059658c4899d2cf3ac9' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSchoolReportSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteSchoolReportSummaryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSchoolReportSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSchoolReportSummaryMutation, DeleteSchoolReportSummaryMutationVariables>;
export const UpdateSchoolReportSummaryDocument = {
  __meta__: { hash: 'b42c9f5230122cd6222f8d391bcc7526cbc20b24' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSchoolReportSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSchoolReportSummaryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSchoolReportSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReportSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSchoolReportSummaryMutation, UpdateSchoolReportSummaryMutationVariables>;
export const CreateSchoolReportDocument = {
  __meta__: { hash: 'c8a23685f3e00cd51c8a8b3d884ce1912b216ea7' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSchoolReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSchoolReportInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchoolReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSchoolReportMutation, CreateSchoolReportMutationVariables>;
export const DeleteSchoolReportDocument = {
  __meta__: { hash: 'f72627be67f44e5bcc12159632002697c9b04269' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSchoolReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteSchoolReportInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSchoolReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSchoolReportMutation, DeleteSchoolReportMutationVariables>;
export const UpdateSchoolReportDocument = {
  __meta__: { hash: 'fe6d35b90d6619a8d00c757b6be7251b9143f45d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSchoolReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSchoolReportInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSchoolReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSchoolReportMutation, UpdateSchoolReportMutationVariables>;
export const CreateSchoolReportItemDocument = {
  __meta__: { hash: '2edd84a39585d76ea10e72eac75eed77489d1190' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSchoolReportItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSchoolReportItemInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchoolReportItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReportItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSchoolReportItemMutation, CreateSchoolReportItemMutationVariables>;
export const DeleteSchoolReportItemDocument = {
  __meta__: { hash: '77e73dd4d8859da7e7b07af40062a5b474932bed' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSchoolReportItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteSchoolReportItemInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSchoolReportItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSchoolReportItemMutation, DeleteSchoolReportItemMutationVariables>;
export const UpdateSchoolReportItemDocument = {
  __meta__: { hash: 'c7b257d40bebbf8ffa04ad90c19aa85e72a3dd39' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSchoolReportItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSchoolReportItemInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSchoolReportItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolReportItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSchoolReportItemMutation, UpdateSchoolReportItemMutationVariables>;
export const GetTasksDocument = {
  __meta__: { hash: '83472033c8c25811cc6f804cd80c948136821eae' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TaskSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TaskFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deadline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'events' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTasksQuery, GetTasksQueryVariables>;
export const CreateTaskDocument = {
  __meta__: { hash: 'ef5598ecd46eb47cf4a600475926c81fde464a08' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTaskInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = {
  __meta__: { hash: '69610568d73c69b3c08222f9399bc6820d640dee' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTaskInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const DeleteTaskDocument = {
  __meta__: { hash: 'b355598078e97d9b078cde742d1482b9c982fc55' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const ThreadsDocument = {
  __meta__: { hash: 'f3116782b5bb9e0ae56af4f5d29acfdb99d7374e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'threads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadSortInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThreadFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isResolved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastMessageDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'originId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentLength' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'createdAt' },
                                  value: { kind: 'EnumValue', value: 'DESC' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'take' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadsQuery, ThreadsQueryVariables>;
export const ThreadDocument = {
  __meta__: { hash: '5e1f5005dababe8e4c060f657c509d013428562f' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'thread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isResolved' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContactId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThreadQuery, ThreadQueryVariables>;
export const CreateThreadDocument = {
  __meta__: { hash: '5bf6d879bdaef95b65d2df9af204fadd1463a02e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateThreadMutation, CreateThreadMutationVariables>;
export const UpdateThreadDocument = {
  __meta__: { hash: 'bbe66c0c255deeae0d6cb3ca5c1a9a07b82f436e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateThreadMutation, UpdateThreadMutationVariables>;
export const DeleteThreadDocument = {
  __meta__: { hash: '5577a011dc866d863c138bd5bcb1f5d155daf5b3' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteThreadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteThreadMutation, DeleteThreadMutationVariables>;
export const GetUserDocument = {
  __meta__: { hash: '20c365464c84204e9e5fc9fcc828536d41668fd1' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetUserExtendedDocument = {
  __meta__: { hash: '95b807e68571da114f0d9a944c2fcf6f8cb62c24' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserExtended' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'localCenterId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserExtendedQuery, GetUserExtendedQueryVariables>;
export const UserDocument = {
  __meta__: { hash: '72ba62017b822ec5e81bbc536aa293a717eafc54' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const CreateUserDocument = {
  __meta__: { hash: 'b9db842e6866ac97b8ff6f91229ae50570f8b10d' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = {
  __meta__: { hash: '5f83d1f9f45d866641abb2c1079cd6113df1e88e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const BulkSetAssigneesDocument = {
  __meta__: { hash: '08f625a63a510c512dbf7ac08e7f2f80747c6595' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkSetAssignees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkSetAssigneesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkSetAssignees' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemIds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ids' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkSetAssigneesMutation, BulkSetAssigneesMutationVariables>;
export const GetViewsDocument = {
  __meta__: { hash: '7f082b42734563d232487d69083485b80f2c09c0' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getViews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewSortInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'views' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnOrdersView' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnsView' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetViewsQuery, GetViewsQueryVariables>;
export const CreateViewDocument = {
  __meta__: { hash: '58428f08e0c9194384dc9a456c2cd11e1c212e14' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateViewInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'view' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateViewMutation, CreateViewMutationVariables>;
export const UpdateViewDocument = {
  __meta__: { hash: '6e424944c03074636e8fb4737a1dca4848282574' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateViewInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'view' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateViewMutation, UpdateViewMutationVariables>;
export const DeleteViewDocument = {
  __meta__: { hash: 'a54154c2e2c20acf3ac0252a11743a8c634aee77' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteViewInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteViewMutation, DeleteViewMutationVariables>;
